import { useState } from 'react';
import { Grid, CircularProgress, Typography, Paper, Divider, Chip, Stack, Alert, Box, Button, AlertTitle } from "@mui/material";
import Bet from './bet/bet';
import { deleteBet, clearIBError } from '../../actions/bets';
import { useSelector, useDispatch } from 'react-redux';
import { isUserAuthenticated, getUserFromProfile } from '../../utils/utils';
import { likeBet } from '../../actions/bets';
import AddBoxIcon from '@mui/icons-material/AddBox';

const Bets = ({ searchTerm, isSearching }) => {
    const dispatch = useDispatch();
    const [user] = useState(getUserFromProfile());
    const { bets, isLoading, isError, errorDetail, errorTitle } = useSelector((state) => state.bets);

    const handleDelete = (betID) => {
      dispatch(deleteBet(betID, user._id));
    }

    const handleLike = (betID, userID) => {
      dispatch(likeBet(betID, userID));
    }

    // BLOCK - Error Handling
    const handleErrorClose = () => {
      // dispatch and clear the error from the store
      dispatch(clearIBError());
    }
    const checkforErrors = () => {
        // put any error messages at the top of the screen
        if (isError) return ( 
            <Alert variant="filled" severity="error" sx={{ mt: 2, mb: 2 }} onClose={handleErrorClose}>
            <AlertTitle>{errorTitle}</AlertTitle>
            <Typography>{JSON.stringify(errorDetail).replaceAll('"','')}</Typography>           
            </Alert>
        );
    }   

    const authenticated = isUserAuthenticated();

    if (!bets?.length && !isLoading) return (
      <Box sx={{ pt: 3 }}>
        <Paper sx={{ p:2 }} elevation={1}>         
            <Typography>0 bets found...</Typography>
            <Stack spacing={2} alignItems='center' sx={{ mt: 2 }}>
              <Typography>why not create one?</Typography>
              <Button variant='contained' href="/create-bet" startIcon={<AddBoxIcon />}>Create Bet </Button>              
          </Stack>
        </Paper>
      </Box>
    );

    return (
      isLoading 
        ? (
          <Stack sx={{ mt: 3 }} spacing={2} direction="column" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        )
        : (
          <> 
          <Paper sx={{ m: 0, mb: 2,  p: 2, backgroundColor: 'transparent' }} elevation={0}>
            { (searchTerm.length > 0) && isSearching && (
                <Divider sx={{m: 2}}>
                  <Chip label={`${bets.length} result(s) for "${searchTerm}"`} variant="filled" color="primary" />
                </Divider>
              ) 
            }
          </Paper>
          { checkforErrors() }
          <Paper>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" justifyContent="center" sx={{ p: 1 }} spacing={2}>
              {bets.map(bet => (
                <Grid item xs={12} sm={8} md={5} lg={4} xl={4} key={bet._id}>
                  <Bet bet={bet} onDelete={handleDelete} onLike={handleLike} user={user} authenticated={authenticated} />
                </Grid>
              ))}
            </Grid>
          </Paper>
          </>
        )
    );
  };

export default Bets;