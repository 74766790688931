import { FETCH_ALL_USERS, FETCH_USER, ACTIVATE_USER, START_LOADING, END_LOADING, LOGOUT } from '../constants/actionTypes';

/*
    currentPage: "0"
    numberOfPages: "1"
    numberOfUsers: "7"
    users: [,…]
    users_per_page: "20"
*/

const INITIAL_STATE = {
  isLoading: true, 
  users: []
};

const usersReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    
    case LOGOUT:
      return { INITIAL_STATE };

    case START_LOADING:
      return { ...state, isLoading: true };
    
    case END_LOADING:
      return { ...state, isLoading: false};
    
    case FETCH_ALL_USERS:
      //console.log(`fetch all users ${JSON.stringify(action.results)}`);
      return {
        ...state,
        users: action.results.users,
        currentPage: action.results.currentPage,
        numberOfPages: action.results.numberOfPages,
        userCount: action.results.numberOfUsers
      };
    
    case FETCH_USER:
      return { ...state, user: action.results.user };
    
    case ACTIVATE_USER:
      return { ...state, user: action.results };
    
    default:
      return state;
      
  }

};

export default usersReducer;