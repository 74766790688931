import { FETCH_ACTIVITY_BY_USER, FETCH_ACTIVITY, START_LOADING_ACTIVITY, END_LOADING_ACTIVITY, LOGOUT } from '../constants/actionTypes';

const INITIAL_STATE = {
    isLoadingActivity: true, 
    activity: [],
    currentPage: 1,
    activityPages: 1,
    activitiesPerPage: 6
};

const activityReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    
    case LOGOUT:
      return { ...INITIAL_STATE };

    case START_LOADING_ACTIVITY:
      return { ...state, isLoadingActivity: true };
    
    case END_LOADING_ACTIVITY:
      return { ...state, isLoadingActivity: false};
    
    case FETCH_ACTIVITY_BY_USER:
      //console.log(`fetch all activity for user: ${JSON.stringify(action.results)}`);
      return {
          ...state,
          activity: action.results.activities,
          currentPage: Number(action.results.currentPage),
          activityPages: Number(action.results.numberOfPages),
          activityCount: Number(action.results.numberOfActivities),
          activitiesPerPage: Number(action.results.activitiesPerPage)
      };

    case FETCH_ACTIVITY: 
      //console.log(`fetch all activity: ${JSON.stringify(action.results)}`);     
      return {
        ...state,
        activity: action.results.activities,
        currentPage: Number(action.results.currentPage),
        activityPages: Number(action.results.numberOfPages),
        activityCount: Number(action.results.numberOfActivities),
        activitiesPerPage: Number(action.results.activitiesPerPage)
    };
    
    default:
      return state;
      
  }

};

export default activityReducer;