import { FETCH_WALLET_AMOUNT, START_LOADING_WALLET, END_LOADING_WALLET, TAKE_BET, CREATE_BET, VOTE_BET, FETCH_WALLET_HISTORY, 
  LEDGER_ADD_CREDITS, ARBITRATE_BET, DELETE_BET, LOGOUT } from '../constants/actionTypes';
import { getUserFromProfile } from '../utils/utils';

const INITIAL_STATE = {
    isLoadingWallet: true, 
    history: [],
    walletHistoryCount: 0, 
    walletHistoryPage: 1,
    walletHistoryPages: 1,
    walletHistoryItemsPerPage: 6,
    amount: 0.0
}

const walletReducer = (state = INITIAL_STATE, action) => { 

    const user = getUserFromProfile();

    //console.log(`wallet reducer amount:${state.amount}`);

    switch (action.type) {
            
      case LOGOUT:
        return { ...INITIAL_STATE };

      case START_LOADING_WALLET:
        //console.log(`start loading wallet`);
        return { ...state, isLoadingWallet: true };

      case END_LOADING_WALLET:
        //console.log(`end loading wallet`);
        return { ...state, isLoadingWallet: false};
      
      case FETCH_WALLET_HISTORY:
          //console.log(`fetch wallet reducer firing: ${JSON.stringify(action.results)}`);
          return { ...state, history: action.results.history };

      case FETCH_WALLET_AMOUNT:
        console.log(`fetch wallet reducer firing: ${JSON.stringify(action.results)}`);
        state.amount = parseFloat(action.results.amount)
        return { ...state, amount: state.amount };
      
      case LEDGER_ADD_CREDITS:
        //console.log(`ledger add credits reducer firing: ${JSON.stringify(action.results)}`);
        state.amount = state.amount + parseFloat(action.results.ledger.credit);
        //console.log(`wallet amount ${state.amount}`);
        return { ...state, amount: state.amount };

      case TAKE_BET:
        //console.log(`TAKE_BET Reducer: ${JSON.stringify(action.results)}`);
        state.amount = state.amount - parseFloat(action.results.wager)
        return { 
          ...state,
          amount: state.amount
        };

      case CREATE_BET:
        state.amount = state.amount - parseFloat(action.results.wagger)
        return {
          ...state,
          amount: state.amount
        }

      case DELETE_BET:
        state.amount = parseFloat(action.results.walletAmount);
        return {
          ...state,
          amount: state.amount
        }

      case VOTE_BET:
        //console.log(`vote bet reducer firing: ${JSON.stringify(action.results)}`);
        // check to see if this bet is now concluded
        if (action.results.bet.status === "concluded") {          
          // is this user the bet winner or loser of the bet?
          if (user._id === action.results.winID) {
            state.amount = state.amount + parseFloat(action.results.winAmount)
          } else if (user._id === action.results.loseID) {
            state.amount = state.amount - parseFloat(action.results.loseAmount)
          }  
        }
        return { 
          ...state, 
          amount: state.amount
        }

      case ARBITRATE_BET:
        //console.log(`arbitrate bet wallet reducer firing: ${JSON.stringify(action.results)}`);
        if (action.results.bet.status === "concluded") {        
          // is this user the bet winner or loser of the bet?
          if (user._id === action.results.winID) {
            console.log('winning');
            state.amount = state.amount + parseFloat(action.results.winAmount)
          } else if (user._id === action.results.loseID) {
            console.log('NOT winning');
            state.amount = state.amount - parseFloat(action.results.loseAmount)
          }
        }
        return { 
          ...state, 
          amount: state.amount
        }  

      default:
        return state;
    }
};

export default walletReducer;