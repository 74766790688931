import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Bets from '../bets/bets';
import { getOpenBets, getBetsBySearch } from '../../actions/bets';
import { Container, TextField, IconButton, Stack, Pagination, Paper, Alert, Button, Link, AlertTitle, Collapse, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { getVotingingBetsByUser } from '../../actions/bets';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import StorefrontIcon from '@mui/icons-material/Storefront';

const Home = () => {
    const [searchQ, setSearchQ] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [isSearching, setisSearching] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, numberOfPages } = useSelector((state) => state.bets);
    const { voting } = useSelector((state) => state.voting);
    const [user] = useState(JSON.parse(localStorage.getItem('profile'))?.user);
    const userId = user?._id;
    const [page, setPage] = useState(1);
    let dispatchedVotingRef = useRef(false);
    const [votingbetcount, setVotingBetCount] = useState([]);
    const [previousPage, setPreviousPage] = useState(0);
    const [sbetsOpen, setSBetsOpen] = useState(true);

    const handlePaginationChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {

        const dispatchedVoting = dispatchedVotingRef.current;

        // if we have a valid user dispatch or set the current # of bets waiting for a vote resolution
        if (userId && !dispatchedVoting) {
            //console.log(`fetching bets for user ${userId}`)
            dispatch(getVotingingBetsByUser(userId));
            dispatchedVotingRef.current = true;
        } else if (typeof voting != 'undefined' && voting.length > 0) {
            // parse out the voting bets that the user has already voted on
            let still_voting = voting.filter(
              function (a) {
                return ((a.ownerID === userId) && (!a.vote?.owner?.vote)) || ((a.takerID === userId) && (!a.vote?.taker?.vote));
              }
            );
            setVotingBetCount(still_voting.length);
        }

        // make pagination go
        if (page !== previousPage) {
            setPreviousPage(page);
            dispatch(getOpenBets(page));
        }

    }, [dispatch, voting, page, userId, previousPage]);
      
    const findBetsByTitle = () => {
        //console.log(`JS looking for bets with title containing - ${searchValue}`)
        setisSearching(true);
        setSearchQ(searchValue);
        navigate(`/bets/search?searchQuery=${searchValue}`, {replace: true});
        dispatch(getBetsBySearch(searchValue));
    };

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            findBetsByTitle();
        }
    };

    const resetSearch = () => {
        setSearchQ('');
        setSearchValue('');
        setisSearching(false);
        navigate('/');
        dispatch(getOpenBets());
    }

    return (
        <>
        
        <Paper variant='outlined' sx={{ p:2, borderRight: 0, borderTop: 0, borderLeft: 0, mb: 1 }}>
            <Stack direction='row' spacing={1}>
            <StorefrontIcon sx={{ color: 'dimgray' }} fontSize='large'  />
            <Typography variant='h6' color="dimgray" >bet marketplace</Typography>
            </Stack>
        </Paper>   

        <Container maxWidth='xl'>

            { /* --------- if the user have bets to vote on - send them a system alert ---------- */ }
            { votingbetcount > 0 && 
                
            <Collapse in={sbetsOpen}>
                <Alert severity="warning" variant='filled' sx={{ mt: 1 }} 
                    action={
                        <IconButton size="small" onClick={() => { setSBetsOpen(false) }}>
                            <CloseIcon sx={{  color: 'white' }} />
                        </IconButton>
                    }
                >
                    <AlertTitle>Settle Bets</AlertTitle>
                    <Stack direction="row" display='flex' justifyContent='space-between' alignItems='center' spacing={3} >
                        <div>{user.firstname}, exciting news! &nbsp;You have &nbsp;<strong>{ votingbetcount }</strong>&nbsp; incredibet(s) to settle.</div>
                        <Button href='/mybets' component={Link} to={"/mybets"} variant="contained" sx={{ backgroundColor: '#ffc766', color: 'black', '&:hover': { backgroundColor: '#fff', color: '#3c52b2' } }} >
                            settle bet(s)
                        </Button>
                    </Stack>
                </Alert>
            </Collapse>
                
            }

            <Stack direction="column" spacing={1} sx={{ mt:5 }} >
                
                <Stack direction="row" >
                    <TextField 
                        id="search" 
                        name="searchField" 
                        onKeyDown={handleKeyPress} 
                        placeholder="find awesome bets, be the house" 
                        label="search bets" 
                        variant="outlined" 
                        value={searchValue} 
                        onChange={(e) => setSearchValue(e.target.value)} 
                        fullWidth 
                        focused
                        InputProps={{
                            endAdornment: (
                            <IconButton onClick={() => resetSearch()}>
                                <ClearIcon />
                            </IconButton>
                            )
                        }}
                        sx={{ backgroundColor: 'white'}}
                    /> 
                    <IconButton aria-label="search" size="large" color="primary" onClick={findBetsByTitle}>
                        <SearchIcon fontSize="inherit" />
                    </IconButton>
                </Stack>            
                
                <Bets searchTerm={searchQ} isSearching={isSearching} />

                { (!isLoading && Number(numberOfPages) > 1)  &&
                <Stack spacing={2} alignContent="center" alignItems="center" sx={{ mt:2 }}>
                    <Paper sx={{ p:2, mb: 2 }}>
                        <Pagination 
                            count={Number(numberOfPages)} 
                            page={Number(page) || 1}
                            variant="outlined" 
                            shape="rounded"
                            color="primary" 
                            onChange={handlePaginationChange}
                        />
                    </Paper>
                </Stack>
                }
                
            </Stack>
        </Container>
        </>
      );
    };
    
export default Home;