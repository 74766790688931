// enumeration for type of activity types
export const GOOGLE_SIGNIN          = 0;
export const LOCAL_SIGNIN           = 1;
export const LIKE_BET               = 2;
export const OWNER_HOLD             = 3;
export const TAKER_HOLD             = 4;
export const BET_SETTLEMENT         = 5;
export const SERVICE_FEE            = 6;
export const WINNINGS               = 7;
export const LOSSES                 = 8;
export const CREATE_BET             = 9;
export const EXPEDITE_BET           = 10;
export const PURCHASE_INCREDITS     = 11;
export const GOOGLE_ACCOUNT_CREATED = 12;
export const INCREDITS_TRANSFER     = 13;
export const EXPIRED_BET            = 14
export const UNKNOWN                = 99;