import { useState, useRef, useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate} from 'react-router-dom';
import { 
    Stack, Typography, Box, Container, Card, CardMedia,
    Paper, Grid, Avatar, Button, Pagination,
    Alert, AlertTitle, Accordion, AccordionSummary, Badge, 
    AccordionDetails, CircularProgress, TableContainer, Table,
    TableHead, TableRow, TableCell, TableBody
} from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LedgerEntryRow from '../ledger/ledgerEntryRow';
import { INCREDIBETS_SYSTEM_USER_ID } from '../../config/config'
import { getLedgerEntries } from '../../actions/ledger';

const BusinessHome = props => {

    const navigate = useNavigate();
    const { isError, errorDetail, errorTitle } = useSelector(state => state.bets);
    const { cashEntries, cashPages, cashCount, isLoadingEntries, creditEntries, creditPages, creditCount } = useSelector((state) => state.ledger);

    const [ cashPage, setCashPage ] = useState(1);
    const [ previousCashPage, setPreviousCashPage ] = useState(1);

    const [ creditPage, setCreditPage ] = useState(1);
    const [ previousCreditPage, setPreviousCreditPage ] = useState(1);

    const id = INCREDIBETS_SYSTEM_USER_ID; 
    const dispatch = useDispatch();    
    let dispatchedRef = useRef(false);

    const handleCashPaginationChange = (event, value) => {
        setCashPage(value);
    };

    const handleCreditPaginationChange = (event, value) => {
        setCreditPage(value);
    };

    // called on page load and if any of the watched values change
    useEffect(() => {
        
        const dispatched = dispatchedRef.current;
        if (!dispatched) {        
            dispatch(getLedgerEntries(id, 'USD'));
            dispatch(getLedgerEntries(id, 'INCREDITS'));
            dispatchedRef.current = true;
        } 

        // make pagination go
        if (cashPage !== previousCashPage) {
            setPreviousCashPage(cashPage);
            dispatch(getLedgerEntries(id, 'USD', cashPage));
        }

        if (creditPage !== previousCreditPage) {
            setPreviousCreditPage(creditPage);
            dispatch(getLedgerEntries(id, 'INCREDITS', creditPage));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, cashPage, creditPage, id]);

    return (
        <>
        {isError && 
            <Alert variant="filled" severity="error" sx={{ m: 1 }}>
                <AlertTitle>{errorTitle}</AlertTitle>
                {JSON.stringify(errorDetail)}
            </Alert>
        }

        <Paper variant='outlined' sx={{ p:2, borderRight: 0, borderTop: 0, borderLeft: 0, mb: 1 }}>
            <Stack direction='row' spacing={1} alignItems='center'>
                <MonetizationOnIcon sx={{ color: 'dimgray' }} fontSize='large' />
                <Typography variant='h6' color="dimgray" >incrediBank Financial Admin</Typography>
                <Button variant="text" onClick={() => navigate(-1)}>&lt; Back</Button>
            </Stack>
       </Paper>  

        <Box sx={{ m: 5 }}>
            <Container>
                <Paper elevation={3}>
                    <Grid container component="main" >
                        <Grid item xs={12} sm={12} md={12} sx={{ m:2 }}>
                            <Stack direction="column" justifyContent="center" alignContent="center" alignItems="center" sx={{ mb: 2 }}>
                                <Avatar sx={{ bgcolor: 'primary.main' }}>
                                    <MonetizationOnIcon />
                                </Avatar>
                                <Typography component="h1" variant="h5"> incredibet financials </Typography>
                            </Stack>                                                                                                            
                        </Grid>
                    </Grid>
                </Paper>
                
                <Paper elevation={1} sx={{ mt: 2 }}>
                    <Stack padding={5} alignItems="center">
                    { /*   
                    <Card
                        variant="outlined"
                        sx={{
                            p: 1,
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                        }}
                        >
                        <CardMedia
                            component="img"
                            width="124"
                            height="124"
                            alt="finance"
                            src="https://source.unsplash.com/5fNmWej4tAA"
                            sx={{
                                borderRadius: 0.5,
                                width: 'clamp(124px, (304px - 100%) * 999 , 100%)',
                            }}
                        />
                        <Box sx={{ alignSelf: 'center', px: { xs: 0, sm: 2 } }}>
                            <Typography
                                variant="body1"
                                color="text.primary"
                                fontWeight={600}
                                sx={{
                                    textAlign: { xs: 'center', sm: 'start' },
                                    mt: { xs: 1.5, sm: 0 },
                                }}
                            >
                                incrediBank
                            </Typography>
                            <Typography
                                component="div"
                                variant="caption"
                                color="text.secondary"
                                fontWeight={500}
                                sx={{ textAlign: { xm: 'center', sm: 'start' } }}
                            >
                                YTD • TOTAL AMOUNT • TREND
                            </Typography>
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                    mt: 2,
                                    justifyContent: { xs: 'space-between', sm: 'flex-start' },
                                }}
                            >
                               $100 | $10,000 | <TrendingUpIcon />
                            </Stack>
                        </Box>
                    </Card>
                    */ }
                   
                        { /*----- TRANSACTION HISTORY ------*/}
                        <Accordion sx={{ width: '100%', mt: 4 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                id="money"
                            >
                                <Stack direction="row" spacing={1}>
                                <MonetizationOnIcon color="action" />
                                <Typography>cash transactions</Typography>
                                <Badge badgeContent={cashCount} color="primary" />
                                </Stack>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Box sx={{ width: '100%' }}>
                                    <Stack alignItems="center" direction="column" spacing={1}>
                                    { !isLoadingEntries 
                                        ? ( 
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead sx={{ backgroundColor: 'gray' }}>
                                                    <TableRow>
                                                        <TableCell align="left"><Typography color="white"><strong>Transaction</strong></Typography></TableCell>
                                                        <TableCell align="center"><Typography color="white"><strong>From</strong></Typography></TableCell>
                                                        <TableCell align="center"><Typography color="white"><strong>To</strong></Typography></TableCell>
                                                        <TableCell align="left"><Typography color="white"><strong>Amount</strong></Typography></TableCell>
                                                        <TableCell align="left"><Typography color="white"><strong>Date</strong></Typography></TableCell>   
                                                        <TableCell align="left"><Typography color="white"><strong>Details</strong></Typography></TableCell>                                
                                                    </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {cashEntries.map(entry => (<LedgerEntryRow entry={entry} user={id} key={entry._id} />))}
                                                    </TableBody>
                                                </Table>                        
                                                </TableContainer>                                                                                        
                                             )
                                        : <CircularProgress />
                                    }   
                                    </Stack>
                                </Box>
                                <Stack alignItems="center" spacing={1}>
                                { (!isLoadingEntries && (cashPages > 1))  &&
                                <Box sx={{ p: 1, width: '100%' }} display="flex" alignItems="center" justifyContent="center">
                                    <Pagination 
                                        count={cashPages} 
                                        page={cashPage || 1}
                                        variant="outlined" 
                                        shape="rounded"
                                        color="primary" 
                                        onChange={handleCashPaginationChange}
                                    />
                                </Box>
                                }
                                </Stack>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion sx={{ width: '100%', mt: 4 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                id="incredits"
                            >
                                <Stack direction="row" spacing={1}>
                                    <MonetizationOnIcon color="action" />
                                    <Typography>incredits transactions</Typography>
                                    <Badge badgeContent={creditCount} color="primary" />
                                </Stack>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Box sx={{ width: '100%' }}>
                                    <Stack alignItems="center" direction="column" spacing={1}>
                                    { !isLoadingEntries 
                                        ? ( 
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead sx={{ backgroundColor: 'gray' }}>
                                                    <TableRow>
                                                        <TableCell align="left"><Typography color="white"><strong>Transaction</strong></Typography></TableCell>
                                                        <TableCell align="center"><Typography color="white"><strong>From</strong></Typography></TableCell>
                                                        <TableCell align="center"><Typography color="white"><strong>To</strong></Typography></TableCell>
                                                        <TableCell align="left"><Typography color="white"><strong>Amount</strong></Typography></TableCell>
                                                        <TableCell align="left"><Typography color="white"><strong>Date</strong></Typography></TableCell>   
                                                        <TableCell align="left"><Typography color="white"><strong>Details</strong></Typography></TableCell>                               
                                                    </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {creditEntries.map(entry => (<LedgerEntryRow entry={entry} user={id} key={entry._id} />))}
                                                    </TableBody>
                                                </Table>                        
                                                </TableContainer>                                                                                        
                                             )
                                        : <CircularProgress />
                                    }   
                                    </Stack>
                                </Box>
                                <Stack alignItems="center" spacing={1}>
                                { (!isLoadingEntries && (creditPages > 1))  &&
                                <Box sx={{ p: 1, width: '100%' }} display="flex" alignItems="center" justifyContent="center">
                                    <Pagination 
                                        count={creditPages} 
                                        page={creditPage || 1}
                                        variant="outlined" 
                                        shape="rounded"
                                        color="primary" 
                                        onChange={handleCreditPaginationChange}
                                    />
                                </Box>
                                }
                                </Stack>
                            </AccordionDetails>
                        </Accordion>


                        <Button variant="outlined" sx={{ maxWidth: '100px', mt: 5 }} onClick={() => navigate(-1)}>Back</Button>
                    </Stack>
                </Paper>
            </Container>
        </Box>
        </>
    );
}

export default BusinessHome;