import { useEffect, useRef, useState } from "react";
import { getUser } from '../../actions/users';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Avatar, Accordion, AccordionSummary,
  Divider, Stack, Paper, Button, Box, AccordionDetails,
  Container, useMediaQuery, Badge, CircularProgress, IconButton,
  Pagination, Alert, AlertTitle, Dialog, DialogTitle, 
  DialogContent, DialogActions, FormControl, InputLabel,
  MenuItem, FormHelperText, Select, Table, TableBody, TableContainer,
  TableHead, TableRow, TableCell } from "@mui/material";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@emotion/react';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { getParticipatingBetsByUser, getWonBetsByUser, getLostBetsByUser } from '../../actions/bets';
import { getActivityByUser } from '../../actions/activity';
import { addDepositFromUser, transferCreditsToUser, getLedgerEntries } from '../../actions/ledger';
import WalletSummaryRow from '../wallet/wallet_summary_row';
import { WON, LOST, PARTICIPATING } from '../wallet/wallet_summary_types';
import ActivityItemSummary from '../activity/activity_item';
import LedgerEntryRow from '../ledger/ledgerEntryRow';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../images/bling.png';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { PAYPAL_CLIENT_ID } from '../../config/config'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { INCREDIBETS_SYSTEM_USER_ID } from '../../config/config';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

export default function UserDetails() {
  
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);
  const { id } = useParams();
  const navigate = useNavigate();
  let dispatchedRef = useRef(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { participating, isLoadingParticipating, participatingCount, participatingPages } = useSelector(state => state.participating);
  const { won, isLoadingWon, wonCount, wonPages } = useSelector(state => state.won);
  const { lost, isLoadingLost, lostCount, lostPages } = useSelector(state => state.lost);
  const { isError, errorTitle, errorDetail } = useSelector((state) => state.bets);
  const { activity, isLoadingActivity, activityCount, activityPages } = useSelector((state) => state.activity);
  const { isLoadingWallet, amount } = useSelector((state) => state.wallet);
  const { ledgerEntries, isLoadingEntries, ledgerPages, ledgerCount } = useSelector((state) => state.ledger);
  
  const [fundUserOpen, setFundUserOpen] = useState(false);
  const [addFundsDialogIsOpen, setAddFundsDialogIsOpen] = useState(false);
  
  // --- pagination ---

  const [ pPage, setPPage] = useState(1);
  const [previousPPage, setPreviousPPage] = useState(1);
  const [ wPage, setWPage] = useState(1);
  const [previousWPage, setPreviousWPage] = useState(1);
  const [ lPage, setLPage] = useState(1);
  const [previousLPage, setPreviousLPage] = useState(1);
  const [ aPage, setAPage] = useState(1);
  const [previousAPage, setPreviousAPage] = useState(1);
  const [ ePage, setEPage] = useState(1);
  const [previousEPage, setPreviousEPage] = useState(1);
  const [ cPage, setCPage] = useState(1);
  const [previousCPage, setPreviousCPage] = useState(1);

  const runningAmount = [];

  // --- paypal start ---
  
  // hooks
  var addFundsAmount = 10;
  const [ppSelectAmount, setPPSelectAmount] = useState(10);

  // creates a valid paypal order object
  const createOrder = (data, actions) => {
    return actions.order.create({
        purchase_units: [
            {
                description: "incredits",
                amount: {
                    currency_code: "USD",
                    value: addFundsAmount
                },
            },
        ],
        application_context : {
          shipping_preference: "NO_SHIPPING"
        }
    }).then((orderID) => {
        //console.log(`paypal order id = ${orderID}`);
        //TODO - save this information in the log
        return orderID;
    });
  };

  // check approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
        //const { payer } = details;
        //console.log(`details of payer = ${JSON.stringify(payer)}`);

        // add the funds to the system account and send the user some credits
        dispatch(addDepositFromUser(addFundsAmount, id));
        dispatch(transferCreditsToUser(addFundsAmount, INCREDIBETS_SYSTEM_USER_ID, id));

        setAddFundsDialogIsOpen(false);
    });
  };

  const onError = (data, actions) => {
    console.log("An error occured with the payment");
  };

  // --- paypal end ---

  // pagination handlers
  const handleParticipatingPaginationChange = (event, value) => {
    setPPage(value);
  };

  const handleWonPaginationChange = (event, value) => {
    setWPage(value);
  };

  const handleLostPaginationChange = (event, value) => {
    setLPage(value);
  };

  const handleActivityPaginationChange = (event, value) => {
    setAPage(value);
  };

  const handleLedgerPaginationChange = (event, value) => {
    setEPage(value);
  };

  const handleCashPaginationChange = (event, value) => {
    setCPage(value);
  };
  
  const handleFundUserClickOpen = () => {
    setFundUserOpen(true);
  }

  const handleFundUserClickClose = () => {
    setFundUserOpen(false);
  }

  const handleFundUserClick = () => {
    setFundUserOpen(false);    
  }

  const handleAddFundsClickOpen = () => {
    setAddFundsDialogIsOpen(true);
  }

  const handleAddFundsClickClose = () => {
    setAddFundsDialogIsOpen(false);
  }
  
  const handleAddFundsAmountChange = (event) => {
    addFundsAmount = event.target.value;
    setPPSelectAmount(addFundsAmount);
    //console.log(`funding level changes to ${addFundsAmount}`);
  };

  // TODO - the total commitment should be comming from Mongo
  const getTotalCommitement = () => {
    var commited_amount = 0.0;
    for (let bet in participating) {

        let value = participating[bet];            
        var amount = value.wallet.ownerStake;
        //console.log(`getTotalCommitment comparing ${JSON.stringify(value.taker)} with ${user._id}`);
        if (value.taker?.id === user._id) {
            //console.log(`taker stake included:${value.wallet.takerStake}`);
            amount = value.wallet.takerStake;
        }
        //console.log(`bet amount ${value.amount}`)
        commited_amount = commited_amount + parseFloat(amount);
    }
    return Number(commited_amount).toFixed(2);
  }

  const getRunningTotal = () => {
    var current_amount = amount;

    // TODO - running amoutn needs to come from Mongo - pagination breaks this way

    // last transaction needs to equal the current amount
    runningAmount.push(current_amount);

    ledgerEntries.forEach(entry => {

      // only track credits
      if (entry.units === "USD") return;
      
      // determine if this was a credit or debit for this user
      if (entry.debituser._id === user._id) {
        current_amount -= parseFloat(entry.debit);
      } else {
        current_amount += parseFloat(entry.credit);
      }

      runningAmount.push(current_amount);

    })

    //console.log(runningAmount);
    return ledgerEntries.map((entry, index) => (<LedgerEntryRow entry={entry} user={user} total={runningAmount[index]} key={entry._id} />))

  }

  // called on page load and if any of the watched values change
  useEffect(() => {
    
    const dispatched = dispatchedRef.current;
    if (!dispatched) {
      dispatch(getUser(id));
      dispatch(getParticipatingBetsByUser(id));
      dispatch(getWonBetsByUser(id));
      dispatch(getLostBetsByUser(id));
      dispatch(getActivityByUser(id));
      dispatch(getLedgerEntries(id, 'ALL'));
      dispatchedRef.current = true;
    } 

    // make pagination go
    if (pPage !== previousPPage) {
      setPreviousPPage(pPage);
      dispatch(getParticipatingBetsByUser(id, pPage));
    }

    if (wPage !== previousWPage) {
      setPreviousWPage(wPage);
      dispatch(getWonBetsByUser(id, wPage));
    }

    if (lPage !== previousLPage) {
      setPreviousLPage(lPage);
      dispatch(getLostBetsByUser(id, lPage));
    }

    if (aPage !== previousAPage) {
      setPreviousAPage(aPage);
      dispatch(getActivityByUser(id, aPage));
    }

    if (ePage !== previousEPage) {
      setPreviousEPage(ePage);
      dispatch(getLedgerEntries(id, 'INCREDITS', ePage));
    }

    if (cPage !== previousCPage) {
      setPreviousCPage(cPage);
      dispatch(getLedgerEntries(id, 'USD', cPage));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pPage, wPage, lPage, aPage, ePage, cPage, id]);

  // security/sanity check - no user, do not proceed 
  if (!user) return null;

  // if there is an error returned from the system
  if (isError) return ( 
    <Alert variant="filled" severity="error" sx={{ m: 1 }}>
      <AlertTitle>{errorTitle}</AlertTitle>
      <Typography>{JSON.stringify(errorDetail).replaceAll('"','')}</Typography>           
    </Alert>
  );

  return (
    <div>      
      {user ? (
      <>
        <Paper variant='outlined' sx={{ p:2, borderRight: 0, borderTop: 0, borderLeft: 0, mb: 1 }}>
         <Stack direction='row' spacing={1} alignItems='center'>
           <Avatar src={user.picture} alt={user.name}  />
           <Typography variant='h6' color="dimgray" >{user.name}'s incredibets profile</Typography>
           <Button variant="text" onClick={() => navigate(-1)}>&lt; Back</Button>
         </Stack>
       </Paper>      
        
        <Container maxWidth="xl">
          
          <Paper elevation={1} sx={{ mt: 5, pb: 5 }}>
                                         
            <Stack spacing={0} direction="column" sx={{ }} alignItems="center">                  
              
              <Avatar variant="rounded" src={user.picture} sx={{ width: 128, height: 128 }} />
              <Typography variant="h6">{user.name}</Typography>
              <Typography variant="caption">member since {new Date(user.createdAt).getFullYear()}</Typography>
                              
              <Stack spacing={2} direction="row" sx={{ mt: 2, mb: 2 }}>                     
                <Stack spacing={0} direction="column" justifyContent="space-evenly">                        
                  <Typography variant="caption" sx={{ fontWeight: 'light', fontStyle: 'italic' }}>{user.email}</Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Stack spacing={0} direction="column">
                  <Typography><strong>incredits</strong></Typography>                     
                  <Stack direction="row" spacing={0} justifyContent="flex-start" alignItems="center">
                    <Stack direction="row" spacing={0.5} justifyContent="flex-start" alignItems="center">                      
                      { isLoadingWallet 
                      ? <CircularProgress /> 
                      : <>
                          <Typography variant="caption">available:</Typography>
                          <CreditCardIcon fontSize="small" />
                          <Typography><strong>{parseFloat(amount).toFixed(2)}</strong></Typography>                        
                        </>
                      }                       
                    </Stack> 
                    <IconButton color="success" onClick={() => { handleAddFundsClickOpen() }} >
                      <AddCircleOutlineIcon fontSize="small" />
                    </IconButton>
                  </Stack>
                  <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>      
                    <Typography variant="caption">commited:</Typography>
                    <Typography><strong>${getTotalCommitement()}</strong></Typography>
                  </Stack>
                  <Divider orientation="horizontal" flexItem sx={{ mt: 1, mb: 1}} />
                  <Typography><strong>incredibets</strong></Typography>
                  <Typography variant="caption">                  
                    <strong>{participatingCount}</strong> active bets | win rate: <strong>{Number(wonCount/(wonCount+lostCount) * 100).toFixed(0)}%</strong>
                  </Typography>                 
                </Stack>
              </Stack>                    

              <Stack spacing={1} sx={{ m:1, width: '95%' }}>

              { /*----- INCREDIBETS LEDGER/WALLET ------ */ }
              <Accordion sx={{ width: '100%'}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id="wallet"
                >
                  <Stack direction="row" spacing={2}>
                    <AccountBalanceWalletIcon color="action" />
                    <Typography>incredits wallet</Typography>
                    <Badge badgeContent={ledgerCount} color="primary" sx={{ ml:2 }} />
                  </Stack>
                </AccordionSummary>

                <AccordionDetails>                                
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead sx={{ backgroundColor: 'gray' }}>
                        <TableRow>
                          <TableCell align="left"><Typography color="white"><strong>Transaction</strong></Typography></TableCell>
                          <TableCell align="left"><Typography color="white"><strong>From</strong></Typography></TableCell>
                          <TableCell align="left"><Typography color="white"><strong>To</strong></Typography></TableCell>
                          <TableCell align="left"><Typography color="white"><strong>Amount</strong></Typography></TableCell>                          
                          <TableCell align="left"><Typography color="white"><strong>Date</strong></Typography></TableCell>   
                          <TableCell align="center"><Typography color="white"><strong>Details</strong></Typography></TableCell>                                 
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      { !isLoadingEntries 
                      ? getRunningTotal() 
                      : <TableRow><TableCell colSpan={5}><Stack alignItems="center" sx={{ m:5 }}><CircularProgress /></Stack></TableCell></TableRow>
                      }                  
                      </TableBody>
                    </Table>                        
                  </TableContainer>                                                                                                         
                
                  <Stack alignItems="center" spacing={1} sx={{ mt:1 }}>
                  { (!isLoadingEntries && (ledgerPages > 1))  &&
                    <Box sx={{ p: 1,  width: '100%' }} display="flex" alignItems="center" justifyContent="center">
                      <Pagination 
                          count={ledgerPages} 
                          page={ePage || 1}
                          variant="outlined" 
                          shape="rounded"
                          color="primary" 
                          onChange={handleLedgerPaginationChange}
                      />
                    </Box>
                  }
                  </Stack>
                </AccordionDetails>
              </Accordion>                    
                
              { /*----- ACTIVE BETS ------*/}
              <Accordion sx={{ width: '100%'}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id="livebets"
                >
                  <Stack direction="row" spacing={2}>
                    <HourglassEmptyIcon color="action" />
                    <Typography>active bets</Typography>
                    <Badge badgeContent={participatingCount} color="primary" />
                  </Stack>
                </AccordionSummary>                

                <AccordionDetails>

                  <TableContainer component={Paper}>
                    <Table size={isSmallScreen ? 'small' : null} >
                      <TableHead sx={{ backgroundColor: 'gray' }}>
                          <TableRow>
                              <TableCell><Typography color="white" variant={isSmallScreen ? 'caption' : 'body1'}><strong>Bet</strong></Typography></TableCell>
                              <TableCell><Typography color="white" variant={isSmallScreen ? 'caption' : 'body1'}><strong>Date</strong></Typography></TableCell>
                              <TableCell align='center'><Typography color="white" variant={isSmallScreen ? 'caption' : 'body1'}><strong>Odds</strong></Typography></TableCell>
                              <TableCell align="right"><Typography color="white" variant={isSmallScreen ? 'caption' : 'body1'}><strong>Amount Committed</strong></Typography></TableCell>
                              <TableCell align="center"><Typography color="white"><strong>Details</strong></Typography> </TableCell>     
                          </TableRow>
                      </TableHead>
                      <TableBody>
                      { !isLoadingParticipating 
                      ? participating.map(bet => (<WalletSummaryRow bet={bet} key={bet._id} maxTitleLength={100} type={PARTICIPATING} isSmall={isSmallScreen} />))
                      : <TableRow><TableCell colSpan={4}><Stack alignItems="center" sx={{ m:5 }}><CircularProgress /></Stack></TableCell></TableRow>
                      }                                                 
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Stack alignItems="center" spacing={1}>
                  { (!isLoadingParticipating && (participatingPages > 1))  &&
                    <Box sx={{ p: 1, width: '100%' }} display="flex" alignItems="center" justifyContent="center">
                      <Pagination 
                          count={participatingPages} 
                          page={pPage || 1}
                          variant="outlined" 
                          shape="rounded"
                          color="primary" 
                          onChange={handleParticipatingPaginationChange}
                      />
                    </Box>
                  }
                  </Stack>
                </AccordionDetails>
              </Accordion>

              { /*----- WON BETS ------*/}
              <Accordion sx={{ width: '100%'}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id="wonbets"
                >
                  <Stack direction="row" spacing={1}>
                    <EmojiEventsIcon color="action" />
                    <Typography>won bets</Typography>
                    <Badge badgeContent={wonCount} color="primary" />
                  </Stack>
                </AccordionSummary>

                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table>
                        <TableHead sx={{ backgroundColor: 'gray' }}>
                            <TableRow>
                              <TableCell><Typography color="white"><strong>Bet</strong></Typography></TableCell>
                              <TableCell><Typography color="white"><strong>Date</strong></Typography></TableCell>
                              <TableCell align='center'><Typography color="white"><strong>Odds</strong></Typography></TableCell>
                              <TableCell align="right"><Typography color="white"><strong>Amount Won</strong></Typography></TableCell>
                              <TableCell align="center"><Typography color="white"><strong>Details</strong></Typography> </TableCell>     
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { !isLoadingWon 
                        ? won.map(bet => (<WalletSummaryRow bet={bet} key={bet._id} type={WON} />))
                        : <TableRow><TableCell colSpan={4}><Stack alignItems="center" sx={{ m:5 }}><CircularProgress /></Stack></TableCell></TableRow>
                        }      
                        </TableBody>                
                    </Table>
                  </TableContainer>
                </AccordionDetails>
                <Stack alignItems="center" spacing={1}>
                  { (!isLoadingWon && (wonPages > 1))  &&
                    <Box sx={{ p: 1, width: '100%' }} display="flex" alignItems="center" justifyContent="center">
                      <Pagination 
                        count={wonPages} 
                        page={wPage || 1}
                        variant="outlined" 
                        shape="rounded"
                        color="primary" 
                        onChange={handleWonPaginationChange}
                    />
                    </Box>
                  }
                </Stack>
              </Accordion>

              { /*----- LOST BETS ------*/}
              <Accordion sx={{ width: '100%'}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id="lostbets"
                >
                  <Stack direction="row" spacing={1}>
                    <ThumbDownOffAltIcon color="action" />
                    <Typography>lost bets</Typography>
                    <Badge badgeContent={lostCount} color="primary" />
                  </Stack>
                </AccordionSummary>

                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead sx={{ backgroundColor: 'gray' }}>
                        <TableRow>
                          <TableCell><Typography color="white"><strong>Bet</strong></Typography></TableCell>
                          <TableCell><Typography color="white"><strong>Date</strong></Typography></TableCell>
                          <TableCell align='center'><Typography color="white"><strong>Odds</strong></Typography></TableCell>
                          <TableCell align="right"><Typography color="white"><strong>Amount Lost</strong></Typography></TableCell>
                          <TableCell align="center"><Typography color="white"><strong>Details</strong></Typography> </TableCell>     
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        { !isLoadingLost 
                        ? lost.map(bet => (<WalletSummaryRow bet={bet} key={bet._id} maxTitleLength={100} type={LOST} />))
                        : <TableRow><TableCell colSpan={4}><Stack alignItems="center" sx={{ m:5 }}><CircularProgress /></Stack></TableCell></TableRow>
                        }                            
                      </TableBody>                    
                    </Table>
                  </TableContainer>
                  <Stack alignItems="center" spacing={1}>
                  { (!isLoadingLost && (lostPages > 1)) &&
                    <Box sx={{ p: 1, width: '100%' }} display="flex" alignItems="center" justifyContent="center">
                      <Pagination 
                        count={lostPages} 
                        page={lPage || 1}
                        variant="outlined" 
                        shape="rounded"
                        color="primary" 
                        onChange={handleLostPaginationChange}
                      />
                    </Box>
                  }
                  </Stack>
                </AccordionDetails>

              </Accordion>

              { /*----- ACTIVITY SUMMARY ------*/}
              <Accordion sx={{ width: '100%'}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id="summary"
                >
                  <Stack direction="row" spacing={1}>
                    <AssignmentTurnedInIcon color="action" />
                    <Typography>activity</Typography>
                    <Badge badgeContent={activityCount} color="primary" />
                  </Stack>
                </AccordionSummary>

                <AccordionDetails>
                  <TableContainer component={Paper}>
                      <Table>
                        <TableHead sx={{ backgroundColor: 'gray' }}>
                          <TableRow>
                            <TableCell><Typography color="white"><strong>Who</strong></Typography></TableCell>
                            <TableCell><Typography color="white"><strong>When</strong></Typography></TableCell>
                            <TableCell><Typography color="white"><strong>What</strong></Typography></TableCell>                                 
                          </TableRow>
                        </TableHead>
                        <TableBody>                        
                        { !isLoadingActivity 
                          ? activity.map(item => (<ActivityItemSummary activity={item} key={item._id} />)) 
                          : <TableRow><TableCell colSpan={3}><CircularProgress /></TableCell></TableRow>
                        }   
                        </TableBody>
                      </Table>
                    </TableContainer>
                  <Stack alignItems="center" spacing={1}>
                  { (!isLoadingActivity && (activityPages > 1))  &&
                    <Box sx={{ p: 1, width: '100%' }} display="flex" alignItems="center" justifyContent="center">
                      <Pagination 
                          count={activityPages} 
                          page={aPage || 1}
                          variant="outlined" 
                          shape="rounded"
                          color="primary" 
                          onChange={handleActivityPaginationChange}
                      />
                    </Box>
                  }
                  </Stack>
                </AccordionDetails>
              </Accordion>
            
              </Stack>

              <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 2 } }>
                { user.admin && 
                <Button variant="contained" startIcon={<AttachMoneyIcon />} sx={{ maxWidth: '150px', minWidth: '100px'}} onClick={() => { handleFundUserClickOpen() }} >Fund&nbsp;User</Button>
                }
                <Button variant="outlined" sx={{ maxWidth: '100px', minWidth: '100px'}} onClick={() => navigate(-1)}>Back</Button>
              </Stack>    
            </Stack>
     
          </Paper>

          { /* ------------- DIALOG: ADD FUNDS ------------- */ }
          <Dialog open={addFundsDialogIsOpen} onClose={handleAddFundsClickClose} maxWidth="sm" fullWidth={true}>
            <DialogTitle>incredibets: fund your account</DialogTitle>
            <Box position="absolute" top={0} right={0}>
                <IconButton onClick={handleAddFundsClickClose}>
                <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent dividers>
              { /*----- PAYPAL INTEGRATION ------*/ } 
              <Stack spacing={2} direction="column" alignItems="center">
                <Typography>add additional funds to your incredibet account.</Typography>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-simple-select-helper-label">Amount</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={ppSelectAmount}
                    label="Amount"
                    onChange={handleAddFundsAmountChange}
                  >                  
                <MenuItem value={10}>$10.00</MenuItem>
                <MenuItem value={50}>$50.00</MenuItem>
                <MenuItem value={100}>$100.00</MenuItem>
                <MenuItem value={250}>$250.00</MenuItem>
                <MenuItem value={500}>$500.00</MenuItem>
                <MenuItem value={1000}>$1000.00</MenuItem>
              </Select>
              <FormHelperText>Enter the amount to add</FormHelperText>
            </FormControl>
                <PayPalScriptProvider
                  options={{ "client-id": PAYPAL_CLIENT_ID}}
                >
                  <PayPalButtons
                    style={{ layout: 'vertical' }}
                    createOrder={createOrder}
                    onApprove={onApprove}
                    onError={onError}
                  />    
                </PayPalScriptProvider>                            
              </Stack>
            </DialogContent>              
          </Dialog>           

          { /* ------------- DIALOG: ADMIN > FUND USER ------------- */ }
          <Dialog open={fundUserOpen} onClose={handleFundUserClickClose} maxWidth="sm" fullWidth={true}>
            <DialogTitle>incrediADMIN: fund user account</DialogTitle>
            <Box position="absolute" top={0} right={0}>
                <IconButton onClick={handleFundUserClickClose}>
                <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent dividers>
              <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>                  
                
                <Stack spacing={2} direction="row" alignItems="center" width={300}>
                    <Avatar variant="rounded" src={user.picture} sx={{ width: 72, height: 72 }} />
                    <Stack spacing={0} direction="column" justifyContent="space-evenly">
                      <Typography variant="h6">{user.name}</Typography>
                      <Typography variant="caption" sx={{ fontWeight: 'light', fontStyle: 'italic' }}>{user.email}</Typography>
                      <Typography variant="caption">member since {new Date(user.createdAt).getFullYear()}</Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                    <Stack spacing={0} direction="column" justifyContent="space-evenly">
                      <Typography variant="caption">balance:</Typography>
                      <Typography><strong>${parseFloat(user.wallet.total).toFixed(2)}</strong></Typography>                                              
                    </Stack>
                </Stack> 


                <Stack spacing={2} direction="row" alignItems="center" width={300}>
                  <Avatar variant="rounded" src={logo} sx={{ width: 32, height: 32 }} />
                  <Stack direction="column" sx={{ mr:2 }} alignItems="center" spacing={-0.5}>
                    <Stack direction="row" spacing={1}>
                        <Typography variant='h5'>incredibets</Typography>
                        <Typography variant='h5' sx={{ color: 'red' }}><strong>&gt;</strong></Typography>
                    </Stack>            
                    <Typography variant="caption" sx={{ color: 'red' }}>be the house</Typography>                          
                  </Stack>
                  
                  <Stack spacing={0} direction="column" justifyContent="space-evenly">
                    <Typography  variant="caption">balance:</Typography>
                    <Typography><strong>${parseFloat(user.wallet.total).toFixed(2)}</strong></Typography>                                      
                  </Stack> 
                </Stack>

              </Stack>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={handleFundUserClickClose}>
                    Cancel
                </Button>
                <Button color="secondary" variant="contained" onClick={() => handleFundUserClick()}>
                    Confirm
                </Button>
            </DialogActions>
          </Dialog>
        </Container>        
        </>
      ) : (
        <div>
          <br />
          <p>No user/invalid user selected.</p>
        </div>
      )}
      
    </div>
  );
};