import * as actionType from '../constants/actionTypes';

const authReducer = (state = { authData: null }, action) => {
  
  switch (action.type) {

    case actionType.AUTH:
      //console.log(`AUTH Reducer: ${JSON.stringify(action.results)}`);
      localStorage.setItem('profile', JSON.stringify({ ...action.results }));
      return { ...state, authData: action.results, loading: false, errors: null };
  
    case actionType.LOGOUT:
      //localStorage.getItem('profile');
      console.log('logout reducer fired')
      localStorage.clear();
      sessionStorage.clear();
      return { ...state, authData: null, loading: false, errors: null };
    
    default:
      return state;
  }

};

export default authReducer;