import { Stack, Typography, Avatar, TableRow, TableCell, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { getIBDateAsString, getIBTimeAsString } from '../../utils/utils';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { INCREDIBETS_SYSTEM_USER_ID } from '../../config/config';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import InfoIcon from '@mui/icons-material/Info';

const LedgerEntryRow = ({ entry, user, total, myKey }) => {

    //console.log(`LedgerEntryRow ${JSON.stringify(entry)}`);
    //console.log(`LedgerEntryRow ${total}`);

    var amount = 0;
    var isCredit = false;
    var from = {};
    var to = {};
    var transaction = "";

    // determine if this was a credit or debit for this user
    if (entry.debituser._id === user._id) {
        amount = entry.debit;
        to = entry.credituser;
        from = entry.debituser;
        //console.log(`LedgerEntryRow: ${JSON.stringify(entry)}`)
    } else {
        amount = entry.credit;
        isCredit = true;
        from = entry.debituser;
        to = entry.credituser;
    }

    switch (entry.type) {

        case "TRANSFER" :
            transaction = "TRANSFER";
            break;

        case "DEPOSIT" :
            transaction = "PURCHASE";
            break;

        case "HOLD" :
            transaction = "RESERVE";
            break;

        case "RELEASE" :
            transaction = "RELEASE";
            break;

        case "REVENUE" :
            transaction = "SERVICE FEE";
            break;

        default:
            transaction = "UNKNOWN";
    }

    //console.log(`entry betID ${entry.betID}`);
    
    return (
        <TableRow>            
            <TableCell>
                <Stack direction="row" spacing={1} alignItems="center" >
                { isCredit 
                ? <MonetizationOnIcon sx={{ color: "green" }} />
                : <MonetizationOnIcon sx={{ color: "red"}} />        
                }   
                    <Typography>{transaction}</Typography>
                </Stack>
            </TableCell>
            <TableCell>
                <Stack direction="row" spacing={1} alignItems="center">
                    { from._id === INCREDIBETS_SYSTEM_USER_ID ? <Avatar src={from.picture} sx={{ bgcolor: 'red' }} alt={from.name}/> : <Avatar src={from.picture} alt={from.name}/> }                    
                    <Typography>{from.name}</Typography>
                </Stack>
            </TableCell>
            <TableCell>
                <Stack direction="row" spacing={1} alignItems="center">
                    { to._id === INCREDIBETS_SYSTEM_USER_ID ? <Avatar src={to.picture} sx={{ bgcolor: 'red' }} alt={to.name}/> : <Avatar src={to.picture} alt={to.name}/> }
                    <Typography>{to.name}</Typography>
                </Stack>
            </TableCell>                
            <TableCell>
                { entry.units === 'USD' && <Typography>{!isCredit && '-'}${parseFloat(amount).toFixed(2)} USD</Typography> }
                { entry.units === 'INCREDITS' && <Stack direction="row" spacing={1} alignItems='center' justifyContent='flex-start'><CreditCardIcon fontSize='small' /> <Typography>{!isCredit && '-'}{parseFloat(amount).toFixed(2)} incredits</Typography></Stack> }
            </TableCell>
            
            <TableCell>
                <Typography>{getIBDateAsString(entry.at)} {getIBTimeAsString(entry.at)} </Typography>
            </TableCell>     
            <TableCell align='center' key={"cell5_" + myKey}>
                { entry.betID 
                ? <IconButton component={Link} to={`/bet/${entry.betID}`}><InfoIcon /></IconButton>
                : <Typography>-</Typography>
                }    
            </TableCell>                                                           
        </TableRow>

    );
}

export default LedgerEntryRow;