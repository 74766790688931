import { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import { Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setBetArbitrationText } from '../../actions/bets';

const AutoSaveTextField = ({ initialValue = '', betid, userid, saveDelay = 1000 }) => {
    
    const dispatch = useDispatch();

    const [value, setValue] = useState(initialValue);
    const [saveStatus, setSaveStatus] = useState('not saved');
    const timerRef = useRef(null);
    const isInitialMount = useRef(true);

    useEffect(() => {

        // If this is the initial mount, skip the save operation
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }

        // Update the save status to 'saving' when the value changes
        setSaveStatus('saving...');

        // Clear the previous timer if the value changes
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    
        // Set a new timer to save the data after the specified delay
        timerRef.current = setTimeout(() => {
            saveData(value);
            setSaveStatus('saved');
        }, saveDelay);
    
        // Cleanup function to clear the timer if the component unmounts
        return () => {
            if (timerRef.current) {
            clearTimeout(timerRef.current);
            }
        };

    }, [value, saveDelay]);
  

    // save function to send the data back to the DB
    const saveData = (data) => {
        dispatch(setBetArbitrationText(betid, userid, data));
        //console.log('Data saved:', data);
    };

    const handleChange = (event) => {
      setValue(event.target.value);
    };
  
    return (
        <Stack spacing={1}>
        <TextField
            label="Your Pitch Text"
            variant="outlined"
            value={value}
            onChange={handleChange}
            fullWidth
        />
        <Typography variant="caption" color="textSecondary" align="right">
            {saveStatus}
        </Typography>
        </Stack>
    );
  };
  
  export default AutoSaveTextField;