import { 
  FETCH_ALL_BETS, CREATE_BET, UPDATE_BET, DELETE_BET, LIKE_BET, VOTE_BET, FETCH_BET_BY_SEARCH,
  FETCH_BET, FETCH_BET_BY_OWNER, TAKE_BET, START_LOADING, END_LOADING, FETCH_OPEN_BETS, EXPEDITE_BET,
  IB_ERROR, IB_CLEAR_ERROR, LOGOUT, SET_ARBITRATION_TEXT, ARBITRATE_BET
} from '../constants/actionTypes';
import { transferCreditsToUser } from '../actions/ledger';
import { INCREDIBETS_SYSTEM_USER_ID } from '../config/config';
import { getUserFromProfile } from '../utils/utils';

const INITIAL_STATE = {
  isLoading: true, 
  bets: [], 
  betCount: 0, 
  currentPage: 1,
  numberOfPages: 1,
  betsPerPage: 6,
  isError: false, 
  errorTitle: "",
  errorDetails: "", 
  isMakeitRain: false, 
  isBetLoss: false,
  isBetPending: false,
  winAmount: 0, 
  serviceFee: 0,
  winBet: {}
}

const betsReducer = (state = INITIAL_STATE, action) => {
  
  var user = getUserFromProfile();

  switch (action.type) {

    case LOGOUT: 
      return { ...INITIAL_STATE };

    case START_LOADING:
      return { 
        ...state, 
        isLoading: true };

    case END_LOADING:
      return { 
        ...state, 
        isLoading: false};

    case FETCH_ALL_BETS:
      //console.log(`FETCH_ALL_BETS Reducer: ${JSON.stringify(action.results)}`);
      return {
        ...state,
        isMakeitRain: false,
        winBet: {},
        bets: action.results.bets,
        betCount: Number(action.results.numberOfBets),
        currentPage: Number(action.results.currentPage),
        numberOfPages: Number(action.results.numberOfPages),
        betsPerPage: Number(action.results.bets_per_page)
      };

    case FETCH_OPEN_BETS:
      //console.log(`FETCH_OPEN_BETS Reducer: ${JSON.stringify(action.results)}`);
      return {
        ...state,
        isMakeitRain: false,
        winBet: {},
        bets: action.results.bets,
        betCount: Number(action.results.numberOfBets),
        currentPage: Number(action.results.currentPage),
        numberOfPages: Number(action.results.numberOfPages)
      };

    case FETCH_BET_BY_SEARCH:
      return {
        ...state,
        isMakeitRain: false,
        winBet: {},
        bets: action.results.bets,
        betCount: Number(action.results.numberOfBets),
        currentPage: Number(action.results.currentPage),
        numberOfPages: Number(action.results.numberOfPages)
      };

    case FETCH_BET_BY_OWNER:
      //console.log(`FETCH_BET_BY_OWNER Reducer: ${JSON.stringify(action.results)}`);
      return { 
        ...state, 
        isMakeitRain: false,
        winBet: {},
        bets: action.results.bets,
        betCount: Number(action.results.numberOfBets),
        currentPage: Number(action.results.currentPage),
        numberOfPages: Number(action.results.numberOfPages)
      };

    case FETCH_BET:
      //console.log(`FETCH_BET Reducer: ${JSON.stringify(action.results)}`);
      return { 
        ...state, 
        isMakeitRain: false,
        winBet: {},
        bet: action.results.bet
      };

    case TAKE_BET:
      //console.log(`TAKE_BET Reducer: ${JSON.stringify(action.results)}`);
      return { 
        ...state,
        isMakeitRain: false,
        winBet: {},
        bets: state.bets.map((bet) => (bet._id === action.results.bet._id ? action.results.bet : bet)),
        wallet: action.results.wallet 
      };

    case EXPEDITE_BET:
      //console.log(`EXPEDITE_BET Reducer: ${JSON.stringify(action.results)}`);
      return { 
        ...state,
        isMakeitRain: false,
        winBet: {},
        bets: state.bets.map((bet) => (bet._id === action.results.bet._id ? action.results.bet : bet))
      };

    case CREATE_BET:
      return { 
        ...state, 
        isMakeitRain: false,
        winBet: {},
        bets: [...state.bets, action.results.bet] ,
        betCount: state.betCount + 1
      };

    case VOTE_BET:
      console.log(`vote bet reducer firing: ${JSON.stringify(action.results)}`);
      // check to see if this bet is now concluded
      if (action.results.bet.status === "concluded") {
        //console.log('bet is conculded!');

        /*
          bet: bet,
          userID: userid,
          winID: winuserid,
          loseID: loseuserid,
          winAmount: total,
          loseAmount: loseamount,
          wallet: newwallet,
          serviceFee: fee
        */

        // update the ledger entries 
        transferCreditsToUser(action.results.winAmount, action.results.loseID, action.results.winID);
        transferCreditsToUser(action.results.serviceFee, action.results.winID, INCREDIBETS_SYSTEM_USER_ID);

        // is this user the bet winner or loser of the bet?
        if (user._id === action.results.winID) {                 
          state.isMakeitRain = true;
          state.isBetPending = false;
          state.isBetLoss = false;
        } else {          
          state.isMakeitRain = false;
          state.isBetPending = false;
          state.isBetLoss = true;
        }
        return { 
          ...state, 
          isMakeitRain: state.isMakeitRain,
          isBetLoss: state.isBetLoss,
          winAmount: action.results.winAmount,
          loseAmount: action.results.loseAmount,
          serviceFee: action.results.serviceFee,
          winBet: action.results.bet,
          bets: state.bets.map((bet) => (bet._id === action.results.bet._id ? action.results.bet : bet)) 
        }
      } else if (action.results.bet.status === "pending") {
        state.isBetPending = true;
        state.isMakeitRain = false;
        state.isBetLoss = false;

        return { 
          ...state, 
          isMakeitRain: state.isMakeitRain,
          isBetLoss: state.isBetLoss,
          winAmount: action.results.winAmount,
          loseAmount: action.results.loseAmount,
          serviceFee: action.results.serviceFee,
          winBet: action.results.bet,
          bets: state.bets.map((bet) => (bet._id === action.results.bet._id ? action.results.bet : bet)) 
        }

      }
      console.log('reducer - bet not concluded...')
      return { 
        ...state,
        bets: state.bets.map((bet) => (bet._id === action.results.bet._id ? action.results.bet : bet)) 
      };

      case ARBITRATE_BET:
        console.log(`arbitrate bet reducer firing: ${JSON.stringify(action.results)}`);
       
        // update the ledger entries 
        transferCreditsToUser(action.results.winAmount, action.results.loseID, action.results.winID);
        transferCreditsToUser(action.results.serviceFee, action.results.winID, INCREDIBETS_SYSTEM_USER_ID);        

        return { 
          ...state, 
          winAmount: action.results.winAmount,
          loseAmount: action.results.loseAmount,
          serviceFee: action.results.serviceFee,
          bets: state.bets.map((bet) => (bet._id === action.results.bet._id ? action.results.bet : bet)) 
        };

    case LIKE_BET:
      //console.log(`LIKE_BET Reducer: ${JSON.stringify(action.results)}`);
      return { 
        ...state, 
        isMakeitRain: false,
        winBet: {},
        bets: state.bets.map((bet) => (bet._id === action.results.bet._id ? action.results.bet : bet)) 
      };


    case SET_ARBITRATION_TEXT:
      //console.log(`SET_ARBITRATION_TEXT Reducer: ${JSON.stringify(action.results)}`);
      return { 
        ...state, 
        isMakeitRain: false,
        winBet: {},
        bet: action.results.bet
      };

    case UPDATE_BET:
      return { 
        ...state, 
        isMakeitRain: false,
        winBet: {},
        bets: state.bets.map((bet) => (bet._id === action.results._id ? action.results : bet)) 
      };

    case DELETE_BET:
      //console.log(`DELETE_BET Reducer: ${JSON.stringify(action.results)}`);
      //console.log(`DELETE BET Bets: ${JSON.stringify(state.bets)} `);
      return { 
        ...state, 
        isMakeitRain: false,
        winBet: {},
        bets: state.bets.filter((bet) => bet._id !== action.results.betID),
        betCount: state.betCount - 1,
        numberOfPages: (state.betCount - 1)/state.betsPerPage
      };

    case IB_ERROR:
      console.log(`IB ERROR - ${JSON.stringify(action)}`);
      return { 
        ...state,
        isError: true,
        errorTitle: action.error.title ?? 'Error',
        errorDetail: action.error.message
      }

    case IB_CLEAR_ERROR:
      //console.log(`IB CLEAR ERROR - ${JSON.stringify(action)}`);
      return { 
        ...state,
        isError: false,
        errorTitle: '',
        errorDetail: ''
      }

    default:
      return { ...state };

  }
};

export default betsReducer;