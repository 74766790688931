import React, { useState } from 'react';
import { 
    Card, CardActions, CardContent, Typography, IconButton, CardHeader, Avatar, CardActionArea, Grid, 
    Switch, Dialog, DialogTitle, Box, DialogContent, DialogActions, Button, Stack } from "@mui/material";
import { red } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { activateUser, setAdmin } from '../../../actions/users';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const User = ({ user, currentuser, authenticated }) => {

    // TODO - pass current user to the dispatch functions for logging

    const [deactivateopen, setDeactivateOpen] = useState(false);

    //console.log(JSON.stringify(user));

    const [activeUser, setActiveUser] = useState(user.active);
    const [adminUser, setAdminUser] = useState(user.admin);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleDeactivateClickOpen = () => {
        if (authenticated) {
            setDeactivateOpen(true);
        } else {
            navigate('/login');
        }
    }

    const handleDeactivateClickClose = () => {
        setDeactivateOpen(false);
    }

    const handleDeactivateClick = () => {
        setDeactivateOpen(false);
        dispatch(activateUser(user._id));
        setActiveUser(!activeUser);
    }

    const handleAdminClick = () => {
        if (authenticated) {
            dispatch(setAdmin(user._id));
            setAdminUser(!adminUser);
        } else {
            navigate('/login');
        }
    }

    return (
        <>
        <Card variant="outlined" sx={{ minHeight: 400, display: "flex", justifyContent: "space-between", flexDirection: "column" }}> 
            <CardActionArea href={`/user/${user._id}`} >
                <CardHeader   
                    avatar={
                        user ? (
                            <Avatar alt={user.name} src={user.picture} />
                        ) : (
                            <Avatar sx={{ bgcolor: red[500] }} aria-label="bet">
                            iB
                            </Avatar>
                        )
                    }  
                    title={user.name}
                    subheader={user.email}
                />
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={5}>
                            <Typography noWrap={true} sx={{ fontWeight: 600, fontSize: 14, textAlign: "right" }}>first name:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                        { activeUser ? (
                            <Typography variant="caption">{user.firstname}</Typography>
                        ) : (
                            <Typography variant="caption" color="text.disabled">{user.firstname}</Typography>
                        )} 
                        </Grid>
                        <Grid item xs={5}>
                            <Typography sx={{ fontWeight: 600, fontSize: 14, textAlign: "right" }}>last name:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                        { activeUser ? (
                            <Typography variant="caption">{user.lastname}</Typography>
                        ) : (
                            <Typography variant="caption" color="text.disabled">{user.lastname}</Typography>
                        )}
                        </Grid>
                        <Grid item xs={5}>
                            <Typography sx={{ fontWeight: 600, fontSize: 14, textAlign: "right" }}>wallet:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant="caption">${parseFloat(user.wallet.total).toFixed(2)}</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography sx={{ fontWeight: 600, fontSize: 14, textAlign: "right" }}>login:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            { user.google ? (
                                <Typography variant="caption">google</Typography>
                            ) : (
                                <Typography variant="caption">incredibets</Typography>
                            )}
                        </Grid>
                        <Grid item xs={5}>
                            <Typography sx={{ fontWeight: 600, fontSize: 14, textAlign: "right", mt:1 }}>admin:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            { activeUser ? (
                                <Switch checked={adminUser} onClick={() => handleAdminClick()} inputProps={{ 'aria-label': 'controlled' }} />
                            ) : (
                                <Switch checked={adminUser}  disabled  inputProps={{ 'aria-label': 'controlled' }} />
                            )}
                        </Grid>
                        <Grid item xs={5}>
                            <Typography sx={{ fontWeight: 600, fontSize: 14, textAlign: "right" }}>created:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant="caption">{user.createdAt}</Typography>
                        </Grid>
                        
                    </Grid>
                </CardContent>   
            </CardActionArea>
            <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton onClick={() => handleDeactivateClickOpen()} color="primary">
                { activeUser ? (
                    <PersonIcon />
                ) : (
                    <>
                    <Box sx={{ backgroundColor: '#e91e63', p: 1, mr: 3 }}>
                        <Stack alignItems="center" justifyContent="center">
                            <Typography sx={{ fontWeight: 600, fontSize: 14, textAlign: 'center' }} color="common.white" variant="caption">USER DISABLED</Typography>
                        </Stack>
                    </Box> 
                    <PersonOffIcon />
                    </>
                )}
                </IconButton>                     
            </CardActions>
        </Card>
        <Dialog open={deactivateopen} onClose={handleDeactivateClickClose} maxWidth="sm" fullWidth>
            { activeUser ? (
            <DialogTitle>Confirm User Deactivation</DialogTitle>               
            ) : (
            <DialogTitle>Confirm User Activation</DialogTitle> 
            )}
            
            <Box position="absolute" top={0} right={0}>
                <IconButton onClick={handleDeactivateClickClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent>
                <Stack spacing={1} alignItems="center">
                { activeUser ? (
                    <Typography>Are you sure you want to deactivate this user?</Typography>
                ) : (
                    <Typography>Are you sure you want to activate this user?</Typography>
                )}
                    <Typography sx={{ fontWeight: 'bold' }}>{user.name} ({user.email})</Typography>
                    <Avatar alt={user.name} src={user.picture} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={handleDeactivateClickClose}>
                    Cancel
                </Button>
                <Button color="secondary" variant="contained" onClick={() => handleDeactivateClick()}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    </>
    );
}
  
  export default User;