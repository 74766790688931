import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Toolbar } from '@mui/material';
import './App.css'
import 'font-awesome/css/font-awesome.min.css'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CreateBet from './components/bets/create_bet/create-bet'
import Login from './components/auth/login';
import NavBar from './components/navbar/navbar';
import Footer from "./components/footer/footer";
import Home from "./components/home/home";
import BetDetails from "./components/bets/betdetails";
import UserHome from "./components/users/usersadminhome";
import UserDetails from "./components/users/userdetails";
import MyBets from './components/bets/my-bets';
import BusinessHome from './components/business/businesshome';
import BetArbiration from './components/bets/betarbitration';
import { blue, green } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: green,
  },
  typography: {
    ibcaption: {
      fontWeight: 100,
      fontSize: 12,
      textAlign: "center",
    }
  }
});

export default function App() {
  return (
    <BrowserRouter>    
      <ThemeProvider theme={theme}>
        <NavBar />
        <Toolbar />      
          <Routes>
            <Route exact path="/" element={<Home />} /> 
            <Route exact path="/bets" element={<Home />} />
            <Route exact path="/bets/search" element={<Home />} />
            <Route exact path="/bet/:id" element={<BetDetails />} />
            <Route exact path="/login" element={<Login />} login={NavBar.login} />
            <Route exact path="/create-bet" element={<CreateBet />} user={NavBar.user} />   
            <Route exact path="/users" element={<UserHome />} />
            <Route exact path="/user/:id" element={<UserDetails />} />
            <Route exact path="/mybets" element={<MyBets />} />
            <Route exact path="/business" element={<BusinessHome />} />
            <Route exact path="/arbitrate" element={<BetArbiration />} />
          </Routes>      
        <Footer />  
      </ThemeProvider>
    </BrowserRouter>
  )
}