import { FETCH_LOST_BETS_BY_USER, START_LOADING_LOST, END_LOADING_LOST, DELETE_BET, LIKE_BET, LOGOUT } from '../constants/actionTypes';

const INITIAL_STATE = {
  isLoadingLost: true, 
  lost: [], 
  lostCount: 0,
  lostPage: 1,
  lostPages: 1,
  lostPerPage: 6 
}

const lostReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    
    case LOGOUT:
      return { ...INITIAL_STATE };

    case START_LOADING_LOST:
      return { ...state, isLoadingLost: true };
    
    case END_LOADING_LOST:
      return { ...state, isLoadingLost: false};
  
    case FETCH_LOST_BETS_BY_USER:
      return {
        ...state, 
        lost: action.results.bets,
        lostCount: Number(action.results.numberOfBets),
        lostPage: Number(action.results.currentPage),
        lostPages: Number(action.results.numberOfPages),
        lostPerPage: Number(action.results.bets_per_page)
      };
  
    case DELETE_BET:
      if (state.lost.some((bet) => bet._id === action.results.betID)) {
        //console.log('bet found in favorites array, removing it');
        return {
          ...state, 
          lost: state.lost.filter((bet) => bet._id !== action.results.betID),
          lostCount: state.lostCount - 1,
          lostPages: (state.lostCount - 1)/state.lostPerPage
        };
      } else {
        return { ...state };
      };
  
    case LIKE_BET:
      return { 
        ...state, 
        lost: state.lost.map((bet) => (bet._id === action.results.bet._id ? action.results.bet : bet)) 
      };
  
    default:
      return state;
  } 
};

export default lostReducer;