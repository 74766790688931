import { FETCH_ACTIVITY_BY_USER, FETCH_ACTIVITY, START_LOADING_ACTIVITY, END_LOADING_ACTIVITY, IB_ERROR } from '../constants/actionTypes';
import { getApp } from '../utils/utils';

//
// return all of the activity for a specific incredibets users
//
export const getActivityByUser = (userid, page = 1, activitiesPerPage = 10) => async (dispatch) => {
    
    try {
    
        dispatch({ type: START_LOADING_ACTIVITY });

        // we don't have an authenticated user yet
        if (!window.incredibets.realmUser) {
            // get an authenticated user oblect from realm-web
            //console.log('getActivityByUser null realmUser')
            window.incredibets.realmUser = await getApp();
        }

        let request = {
            userid: userid,
            page: page,
            activitiesPerPage: activitiesPerPage
        };
        //console.log(JSON.stringify(request));
        const results = JSON.parse(await window.incredibets.realmUser.functions.fetchActivity(JSON.stringify(request))).body;
        if (results.error !== undefined) {
            throw results;
        }
    
        dispatch({ type: FETCH_ACTIVITY_BY_USER, results });
        dispatch({ type: END_LOADING_ACTIVITY });

    } catch (error) {
        console.log(`getActivityByUser catch: ${JSON.stringify(error)}`);
        dispatch({ type: IB_ERROR, error });
        dispatch({ type: END_LOADING_ACTIVITY });
    }

};

//
// return all of the system activity
//
export const getActivity = (page = 1, activitiesPerPage = 10) => async (dispatch) => {
    
    try {
    
        dispatch({ type: START_LOADING_ACTIVITY });

        // we don't have an authenticated user yet
        if (!window.incredibets.realmUser) {
            // get an authenticated user oblect from realm-web
            //console.log('getActivityByUser null realmUser')
            window.incredibets.realmUser = await getApp();
        }

        let request = {            
            page: page,
            activitiesPerPage: activitiesPerPage
        };
        //console.log(JSON.stringify(request));
        const results = JSON.parse(await window.incredibets.realmUser.functions.fetchActivity(JSON.stringify(request))).body;
        if (results.error !== undefined) {
            throw results;
        }
    
        dispatch({ type: FETCH_ACTIVITY, results });
        dispatch({ type: END_LOADING_ACTIVITY });

    } catch (error) {
        console.log(`getActivityByUser catch: ${JSON.stringify(error)}`);
        dispatch({ type: IB_ERROR, error });
        dispatch({ type: END_LOADING_ACTIVITY });
    }

};
