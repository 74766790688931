import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { 
    CircularProgress, Stack, Typography, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody,
    Pagination, Box
} from '@mui/material';
import WalletSummaryRow from './wallet_summary_row';
import { getParticipatingBetsByUser } from '../../actions/bets';
import { PARTICIPATING } from "./wallet_summary_types";

const WalletSummaryTable = ({ user }) => {

    var commited_amount = 0.0;

    const dispatch = useDispatch();

    const { participating, isLoadingParticipating, participatingCount, participatingPages, participatingPage } = useSelector(state => state.participating);
    const [ pPage, setPPage] = useState(participatingPage);
    const [previousPPage, setPreviousPPage] = useState(1);

    // TODO - the total commitment should be comming from Mongo
    const getTotalCommitement = () => {
        //console.log(JSON.stringify(bets));
        for (let bet in participating) {

            let value = participating[bet];            
            var amount = value.wallet.ownerStake;
            //console.log(`getTotalCommitment comparing ${JSON.stringify(value.taker)} with ${user._id}`);
            if (value.taker?.id === user._id) {
                //console.log(`taker stake included:${value.wallet.takerStake}`);
                amount = value.wallet.takerStake;
            }
            //console.log(`bet amount ${value.amount}`)
            commited_amount = commited_amount + parseFloat(amount);
        }
        return Number(commited_amount).toFixed(2);
    }

    const handlePaginationChange = (event, value) => {
        setPPage(value);
    };

    // fetch more bets as required
    useEffect(() => {
    
        // make pagination go
        if (pPage !== previousPPage) {
          setPreviousPPage(pPage);
          dispatch(getParticipatingBetsByUser(user._id, pPage));
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, pPage, user]);
    
    return (
        <Stack direction="column" spacing={1}>
            { participatingCount === 0 
            ? <Typography variant="caption" color="white" sx={{pt:1}}>You are participating in 0 bets...</Typography>
            : <>
                <Typography variant="caption" color="white" sx={{pt:1}}>Your Commitments:</Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300, maxHeight: 440 }} size="small">
                        <TableHead sx={{ backgroundColor: 'gray' }}>
                            <TableRow>
                                <TableCell><Typography color="white" variant="caption"><strong>Bet</strong></Typography></TableCell>
                                <TableCell><Typography color="white" variant="caption"><strong>Date</strong></Typography></TableCell>
                                <TableCell align='center'><Typography color="white" variant="caption"><strong>Odds</strong></Typography></TableCell>
                                <TableCell align="right"><Typography color="white" variant="caption"><strong>Committed</strong></Typography></TableCell>
                                <TableCell align="center"><Typography color="white" variant="caption"><strong>Details</strong></Typography> </TableCell>                 
                            </TableRow>
                        </TableHead>
                      <TableBody>
                      { !isLoadingParticipating 
                      ? participating.map(bet => (<WalletSummaryRow user={user} bet={bet} key={bet._id} type={PARTICIPATING} isSmall={true} maxTitleLength={15} />))
                      : <TableRow><TableCell colSpan={5}><Stack alignItems="center" sx={{ m:5 }}><CircularProgress /></Stack></TableCell></TableRow>
                      }                  
                      </TableBody>
                    </Table>                        
                </TableContainer>                                                               
                
            <Stack alignItems="center" spacing={1}>
                { (!isLoadingParticipating && (participatingPages > 1))  &&
                <Box sx={{ backgroundColor : 'darkgrey', p: 1, width: '100%' }} display="flex" alignItems="center" justifyContent="center">
                    <Pagination 
                        count={participatingPages} 
                        page={pPage || 1}
                        variant="outlined" 
                        shape="rounded"
                        color="incredibet" 
                        onChange={handlePaginationChange}
                    />
                </Box>
                }
                { isLoadingParticipating 
                ? <CircularProgress /> 
                : <>
                    <Typography variant="caption" color="white" sx={{ pl: 1, width: '100%', textAlign: 'center' }}>you are currently participating in <strong>{participatingCount}</strong> bet(s).</Typography>
                    <Typography variant="caption" color="white" sx={{ pl: 1, pb:1, width: '100%', textAlign: 'center' }}>total committments: <strong>${getTotalCommitement()}</strong></Typography>
                  </>
                }
            </Stack>
        </>
        }
        </Stack>
    )
}

export default WalletSummaryTable;