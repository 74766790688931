import { format, parseISO } from 'date-fns';
import CryptoJS from 'crypto-js';
import * as Realm from "realm-web";

// connect to the realm framework and returns an authenticated session back
export async function getApp() {

    const appKey = process.env.REACT_APP_REALM_APP_ID;
    if (!appKey) {
        throw new Error('Realm app key not found');
    }
    const app = new Realm.App({ id: appKey });

    const apiKey = process.env.REACT_APP_API_KEY;
    if (!apiKey) {
        throw new Error('Realm Server API Key not found');
    }
    //console.log(`API KEY loaded - ${apiKey}`);

    const credentials = Realm.Credentials.apiKey(apiKey);
    //console.log(`Realm credentials returned ${JSON.stringify(credentials)}`);

    const session = await app.logIn(credentials);
    //console.log(`login successful, bearer token: ${JSON.stringify(session.accessToken)}`);
    
    return session;
};

export function signEndPointRequest(body) {
    
    const secret = process.env.REACT_APP_API_SECRET;
    if (!secret) {
        throw new Error('Realm Server API Secret not found');
    }
    //console.log(`API KEY loaded - ${secret}`);
    const payload = JSON.stringify(body);

    const sha256 = CryptoJS.HmacSHA256(payload, secret);
    //console.log(`hash generated ${sha256}`);

    return sha256;

    /*
    const hash = crypto.createHmac('sha265', secret)
        .update(payload)
        .digest('hex')
    return hash;
    */
};

export function truncate(input, length) {
    if (length < 0) { return input }
    if (input.length > length) {
       return input.substring(0, length-1) + (input.length>length?'...':''); 
    }
    return input;
};

export function isUserAuthenticated() {
    // reterive the profile object
    const profile = localStorage.getItem('profile');
    var authenticated = false;

    // if we have a profile object - do the user stuff
    if (profile) {
        // check to see if the user's login loken is still valid
        const token = JSON.parse(localStorage.getItem('profile')).token;
        if (token) {
            authenticated = true;
        }
    }
    return authenticated
};

export function getUserFromProfile() {
    // reterive the profile object
    const profile = localStorage.getItem('profile');
    var user;

    // if we have a profile object - do the user stuff
    if (profile) {
        // convert the user ID string back to a binary object
        user = JSON.parse(profile).user;
        //console.log(`user obj: ${JSON.stringify(user)}`);
        //let userOID = new require('mongodb').BSON.ObjectId(user._id);
        //user._id = userOID;
        return user;
    } 

    return null;
}

export function getIBTimeAsString(betdate, isShort = false) {
    
    if (!betdate) return;

    try { 
        const bdate = parseISO(betdate);
        if (isShort) return format(bdate, 'h:mma');
        else return format(bdate, 'hh:mm a')
    } 
    catch (e) { 
        console.log(`getIBTimeAsString error converting ${betdate}, ${e}`);
    }
}

export function getIBDateAsString(betdate, isShort = false) {
    
    if (!betdate) return;
    
    try { 
        const bdate = parseISO(betdate);
        if (isShort) return format(bdate, 'M/d/yy');
        else return format(bdate, 'MMMM dd, yyyy ')
    } 
    catch (e) { 
        console.log(`getIBDateAsString error converting ${betdate}, ${e}`);
    }
}

export function getIBVoteDateAsStirng(betdate) {

    if (!betdate) return;

    try { 
        let bdate = parseISO(betdate);
        bdate.setDate(bdate.getDate() + 2 * 7);
        return format(bdate, 'MMMM dd, yyyy ')
    } 
    catch (e) { 
        console.log(`getIBVoteDateAsStirng error converting ${betdate}, ${e}`);
    }
}

export function getIBDateAsMS(betdate) {

    if (!betdate) return;

    try { 
        let bdate = parseISO(betdate);
        return bdate.getTime();
    } catch (e) {
        console.log(`getIBDateAsMS error converting ${betdate}, ${e}`);
    }
}