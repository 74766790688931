import { FETCH_RESOLVING_BETS_BY_USER, START_LOADING_RESOLVING, END_LOADING_RESOLVING, DELETE_BET, LIKE_BET, FETCH_RESOLVING_BETS, LOGOUT } from '../constants/actionTypes';

const INITIAL_STATE = {
  isLoadingResolving: true, 
  resolving: [],
  resolvingCount: 0, 
  resolvingPage: 1,
  resolvingPages: 1,
  resolvingPerPage: 6
}

const votingReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case LOGOUT: 
      return { ...INITIAL_STATE };
    case START_LOADING_RESOLVING:
      return { ...state, isLoadingResolving: true };
    case END_LOADING_RESOLVING:
      return { ...state, isLoadingResolving: false};
    case FETCH_RESOLVING_BETS_BY_USER:
      //console.log(`resolving page:${action.results.currentPage}`);
      return {
        ...state, 
        resolving: action.results.bets,
        resolvingPage: Number(action.results.currentPage),
        resolvingPages: Number(action.results.numberOfPages),
        resolvingCount: Number(action.results.numberOfBets),
        resolvingPerPage: Number(action.results.bets_per_page)
      };
    case FETCH_RESOLVING_BETS:
      return {
        ...state, 
        resolving: action.results.bets,
        resolvingPage: Number(action.results.currentPage),
        resolvingPages: Number(action.results.numberOfPages),
        resolvingCount: Number(action.results.numberOfBets),
        resolvingPerPage: Number(action.results.bets_per_page)
      };
    case DELETE_BET:
      if (state.resolving.some((bet) => bet._id === action.results.betID)) {
        //console.log('bet found in voting array, removing it');
        return {
          ...state, 
          resolving: state.resolving.filter((bet) => bet._id !== action.results.betID),
          resolvingCount: state.resolvingCount - 1,
          resolvingPages: (state.betCount - 1)/state.betsPerPage
        };
      } else {
        return { ...state };
      };
     
    case LIKE_BET:
      return { 
        ...state, 
        resolving: state.resolving.map((bet) => (bet._id === action.results.bet._id ? action.results.bet : bet)) 
      };
    default:
      return state;
  }
};

export default votingReducer;