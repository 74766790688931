import { useState, useEffect } from "react";
import { getBet, clearIBError } from '../../actions/bets';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Stack, Paper, Button, Box, Container, Grid, useMediaQuery, useTheme, Alert, AlertTitle } from "@mui/material";
import Bet from './bet/bet';
import InfoIcon from '@mui/icons-material/Info';
import { deleteBet } from '../../actions/bets';
import { isUserAuthenticated } from '../../utils/utils';
import { likeBet } from '../../actions/bets';

const BetDetails = props => {
  const dispatch = useDispatch();
  const { bet, isError, errorDetail, errorTitle } = useSelector((state) => state.bets);
  const [user] = useState(JSON.parse(localStorage.getItem('profile'))?.user);
  const { id } = useParams();
  const navigate = useNavigate();

  const userId = user?._id;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const authenticated = isUserAuthenticated();

  // fetch the bet from the DB
  useEffect(() => {
    dispatch(getBet(id));
  }, [dispatch, id]);

  if (bet !== undefined) {
    var terms = bet.title?.split(/[ ,]+/);
    if (terms) {
      terms = terms.filter(function(item) {
        return item.length > 3;
      })
    } else terms = ["incredibets"];
  }

  const handleDelete = (betID) => {
    dispatch(deleteBet(betID, user._id));
  }

  const handleLike = (betID, userID) => {
    dispatch(likeBet(betID, userID));
  }

  // BLOCK - Error Handling
  const handleErrorClose = () => {
    // dispatch and clear the error from the store
    dispatch(clearIBError());
  }
  const checkforErrors = () => {
      // put any error messages at the top of the screen
      if (isError) return ( 
          <Alert variant="filled" severity="error" sx={{ mt: 2, mb: 2 }} onClose={handleErrorClose}>
          <AlertTitle>{errorTitle}</AlertTitle>
          <Typography>{JSON.stringify(errorDetail).replaceAll('"','')}</Typography>           
          </Alert>
      );
  }   

  return (
    <div>
      
      {bet ? (
        <>
        <Paper variant='outlined' sx={{ p:2, borderRight: 0, borderTop: 0, borderLeft: 0, mb: 1 }}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <InfoIcon sx={{ color: 'dimgray' }} fontSize='large'  />
            <Typography variant='h6' color="dimgray" >bet details</Typography>
            <Button variant="text" onClick={() => navigate(-1)}>&lt; Back</Button>
          </Stack>
        </Paper>   

        { checkforErrors() }

        <Box sx={{ mt: 2 }}>
          <Container>
            <Bet bet={bet} onDelete={handleDelete} onLike={handleLike} user={user} authenticated={authenticated} detailed={true} />
          </Container>
        </Box>
  
        </>
      ) : (
        <div>
          <Box sx={{ m: 5 }}>
           <Container>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography>Bet not found.</Typography> 
            </Paper>
          </Container>
          </Box>
        </div>
      )}
    </div>
  );
};

export default BetDetails;