import { FETCH_PARTICIPATING_BETS_BY_USER, TAKE_BET, START_LOADING_PART, END_LOADING_PART, DELETE_BET, LIKE_BET, LOGOUT } from '../constants/actionTypes';

const INITIAL_STATE = {
  isLoadingParticipating: true, 
  participating: [], 
  participatingCount: 0,
  participatingPage: 1,
  participatingPages: 1,
  participatingPerPage: 6
}

const participatingReducer = (state = INITIAL_STATE, action) => {
  
    switch (action.type) {

      case LOGOUT:
        return { ...INITIAL_STATE };

      case START_LOADING_PART:
        return { ...state, isLoadingParticipating: true };

      case END_LOADING_PART:
        return { ...state, isLoadingParticipating: false};

      case TAKE_BET:
          //console.log('TAKE BET from PARTICIPATING REDUCER!!!');
          return { 
            ...state,
            participating: [...state.participating, action.results.bet],
            participatingCount: state.participatingCount + 1
          };

      case FETCH_PARTICIPATING_BETS_BY_USER:
        //console.log(`REDUCING FETCH_PARTICIPATING_BETS_BY_USER: ${JSON.stringify(action)}`);
        return {
          ...state, 
          participating: action.results.bets,
          participatingCount: Number(action.results.numberOfBets),
          participatingPage: Number(action.results.currentPage),
          participatingPages: Number(action.results.numberOfPages),
          participatingPerPage: Number(action.results.bets_per_page)
        };

      case DELETE_BET:
        if (state.participating.some((bet) => bet._id === action.results.betID)) {
          //console.log('bet found in favorites array, removing it');
          return {
            ...state, 
            participating: state.participating.filter((bet) => bet._id !== action.results.betID),
            participatingCount: state.participatingCount - 1,
            participatingPages: (state.participatingCount - 1)/state.participatingPerPage
          };
        } else {
          return { ...state };
        };

      case LIKE_BET:
        return { 
          ...state, 
          participating: state.participating.map((bet) => (bet._id === action.results.bet._id ? action.results.bet : bet)) 
        };

      default:
        return state;
  }
};

export default participatingReducer;