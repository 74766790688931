import { useState, useEffect, useRef } from 'react';
import { Grid, CircularProgress, Typography, Paper, Divider, Stack, Box, Button, ButtonGroup, Badge,
  Dialog, DialogTitle, IconButton, DialogContent, DialogActions, Alert, Pagination, AlertTitle, Avatar } from "@mui/material";
import Bet from './bet/bet';
import { deleteBet, getFavoriteBetsByUser, getVotingingBetsByUser,
  getWonBetsByUser, getLostBetsByUser, getOpenBetsByUser, getTakenBetsByUser, 
  getBetsByUser, getResolvingBetsByUser, clearIBError } from '../../actions/bets';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HistoryIcon from '@mui/icons-material/History';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { isUserAuthenticated, getUserFromProfile } from '../../utils/utils';
import styled from 'styled-components';
import { useInterval } from 'react-use';
import CloseIcon from '@mui/icons-material/Close';
import { likeBet } from '../../actions/bets';
import { useSearchParams } from 'react-router-dom';
import * as BetStatus from '../../constants/betStatus';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import GavelIcon from '@mui/icons-material/Gavel';
import { useNavigate} from 'react-router-dom';
import AddBoxIcon from '@mui/icons-material/AddBox';
import * as actionType from '../../constants/actionTypes';

function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function MoneyRain() {
  const [billsToRender, setBillsToRender] = useState([{ key: 0, speed: 0, emoji: '💵', offset: 0 }]);
  const { height, width } = useWindowDimensions();

  useInterval(() => {
    if (billsToRender.length > 50) {
      billsToRender.shift();
    }
    const offset = Math.floor(Math.random() * width);
    const key = offset + Math.floor(Math.random() * 1000000);
    const emoji = '💵';
    const speed = Math.floor(Math.random() * 5) + 5;

    billsToRender.push({ offset, speed, key, emoji });
    setBillsToRender([...billsToRender]);
  }, 200);

  return (
    <SuperContainer>
    { billsToRender.map(( { key, emoji, offset, speed }) => {
      return (
        <RainContainer key={key} offset={offset} speed={speed} screenheight={height+20}>{emoji}</RainContainer>
      );
    })}
    </SuperContainer>
  )
}

const MyBets = () => {
    const dispatch = useDispatch();
    let dispatchedRef = useRef(false);

    const [user] = useState(getUserFromProfile());
    
    const { bets, numberOfPages, isLoading, isMakeitRain, isBetLoss, isBetPending, serviceFee, winAmount, 
      winBet, isError, errorTitle, errorDetail, betCount, loseAmount } = useSelector(state => state.bets);
    const { open, isLoadingOpen, openPages, openCount } = useSelector(state => state.open);
    const { favorites, isLoadingFavs, favPages, favCount } = useSelector(state => state.favorites);
    const { voting, isLoadingVoting, votingPages, votingCount } = useSelector(state => state.voting);
    const { won, isLoadingWon, wonPages, wonCount } = useSelector(state => state.won);
    const { lost, isLoadingLost, lostPages, lostCount } = useSelector(state => state.lost);
    const { taken, isLoadingTaken, takenPages, takenCount } = useSelector(state => state.taken);
    const { resolving, isLoadingResolving, resolvingPages, resolvingCount } = useSelector(state => state.resolving);
    
    const [ currentbets, setCurrentBets ] = useState([]);
    const authenticated = isUserAuthenticated();
    const [ waitingforvotebets, setWaitingForVoteBets ] = useState([]);
    const [ mir, setMIR ] = useState(isMakeitRain);
    const [ userWonBet, setUserWonBet ] = useState(false);
    const [ betLoss, setBetLoss ] = useState(isBetLoss);
    const [ userLostBet, setUserLostBet ] = useState(false);
    const [ betPending, setBetPending ] = useState(isBetPending);
    const [ userPendingBet, setUserPendingBet ] = useState(false);

    const userId = user?._id;

    // pagination suppport/state 
    const [hPage, setHPage] = useState(1);
    const [previousHPage, setPreviousHPage] = useState(1);
    const [wPage, setWPage] = useState(1);
    const [previousWPage, setPreviousWPage] = useState(1);
    const [tPage, setTPage] = useState(1);
    const [previousTPage, setPreviousTPage] = useState(1);
    const [oPage, setOPage] = useState(1);
    const [previousOPage, setPreviousOPage] = useState(1);
    const [lPage, setLPage] = useState(1);
    const [previousLPage, setPreviousLPage] = useState(1);
    const [fPage, setFPage] = useState(1);
    const [previousFPage, setPreviousFPage] = useState(1);
    const [vPage, setVPage] = useState(1);
    const [previousVPage, setPreviousVPage] = useState(1);
    const [rPage, setRPage] = useState(1);
    const [previousRPage, setPreviousRPage] = useState(1);

    const [previousABIndex, setPreviousABIndex] = useState(BetStatus.PENDING_BETS);
    const [ABindex, setABindex] = useState(BetStatus.PENDING_BETS);
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const handleWonBetClose = () => {
      setUserWonBet(false);
      setMIR(false);
    }

    const handleLostBetClose = () => {
      setUserLostBet(false);
      setBetLoss(false);
    }

    const handlePendingBetClose = () => {
      setUserPendingBet(false);
      setBetPending(false);
    }

    const handleWonPaginationChange = (event, value) => {     
      setWPage(value);      
    };

    const handleLostPaginationChange = (event, value) => {
      setLPage(value);
    };

    const handleTakenPaginationChange = (event, value) => {
      setTPage(value);
    };

    const handleHistoryPaginationChange = (event, value) => {
      setHPage(value);
    };

    const handleOpenPaginationChange = (event, value) => {
      setOPage(value);
    };

    const handleFavPaginationChange = (event, value) => {
      setFPage(value);
    };

    const handleVotingPaginationChange = (event, value) => {
      setVPage(value);
    };

    const handleResolvingPaginationChange = (event, value) => {
      setRPage(value);
    };

    const isUserBet = () => {
      return (userId === winBet?.owner?.id);
    }

    const usersVote = () => {
      if (isUserBet()) {
        if (winBet?.owner?.vote === winBet?.owner?.id) return winBet?.owner;
        return winBet?.taker;
      }
  
      if (winBet.taker?.vote === userId) return winBet.taker;
      return winBet.owner;
    }
  
    // how did the other person vote on this bet
    const othersVote = () => {
      if (isUserBet()) {
        if (winBet?.taker?.vote === winBet?.taker?.id) return winBet?.taker;
        return winBet.owner;
      }
  
      if (winBet?.owner?.vote === userId) return winBet?.owner;
      return winBet?.taker;
    }

    useEffect(() => {
      
      // track if we're already dispatched so we don't excessivly reterive data
      const dispatched = dispatchedRef.current;

      // is it time to MAKE IT RAIN?
      if (mir) {
        setUserWonBet(true);
      } else if (betLoss) {
        setUserLostBet(true);
      } else if (betPending) {
        setUserPendingBet(true);
      }

      // if we don't have any bets yet - then go get some
      if (!dispatched) {

        // get your bets, get your bets!
        dispatch(getFavoriteBetsByUser(userId));
        dispatch(getVotingingBetsByUser(userId));
        dispatch(getWonBetsByUser(userId));
        dispatch(getLostBetsByUser(userId));
        dispatch(getOpenBetsByUser(userId));
        dispatch(getTakenBetsByUser(userId));
        dispatch(getBetsByUser(userId));
        dispatch(getResolvingBetsByUser(userId));

        // handle setting of the active tab
        var tab_override = getOverrideTab();
        setPreviousABIndex(-1);
        setABindex(tab_override);

        const data = window.localStorage.getItem('INCREDIBETS_AB_INDEX');
        if ( data !== null ) {
          setABindex(JSON.parse(data));
          window.localStorage.removeItem('INCREDIBETS_AB_INDEX');
        }

        //console.log(`override tab detected current tab is ${tab_override}, previous tab is ${ptab_override}`);

        dispatchedRef.current = true;
      } else {

        // the bets the user has to vote on, their own or taken
        if (!isLoadingVoting) {
          
          if (typeof voting != 'undefined' && voting.length > 0) {
            // parse out the voting bets that the user has already voted on
            let still_voting = voting.filter(
              function (a) {
                return ((a.ownerID === userId) && (!a.vote?.owner?.vote)) || ((a.takerID === userId) && (!a.vote?.taker?.vote));
              }
            );
            setWaitingForVoteBets(still_voting);
          }
        }
      }

      // page changes
      if (fPage !== previousFPage) {
        setPreviousFPage(fPage);
        dispatch(getFavoriteBetsByUser(userId, fPage));
      }

      if (hPage !== previousHPage) {
        setPreviousHPage(hPage);
        dispatch(getBetsByUser(userId, hPage));
      }

      if (wPage !== previousWPage) {
        setPreviousWPage(wPage);
        dispatch(getWonBetsByUser(userId, wPage));
      }

      if (lPage !== previousLPage) {
        setPreviousLPage(lPage);
        dispatch(getLostBetsByUser(userId, lPage));
      }

      if (tPage !== previousTPage) {
        setPreviousTPage(tPage);
        dispatch(getTakenBetsByUser(userId, tPage));
      }

      if (oPage !== previousOPage) {
        setPreviousOPage(oPage);
        dispatch(getOpenBetsByUser(userId, oPage));
      }

      if (vPage !== previousVPage) {
        setPreviousVPage(vPage);
        dispatch(getVotingingBetsByUser(userId, vPage));
      }

      if (rPage !== previousRPage) {
        //console.log(`resolving page:${rPage} and previous resolving page:${previousRPage}`)
        setPreviousRPage(rPage);
        dispatch(getResolvingBetsByUser(userId, rPage));
      }
        
      // set the active set of bets based on the tab position
      setActiveTab(ABindex);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, userId, wPage, hPage, tPage, lPage, oPage, fPage, vPage, rPage, resolving, taken, won, lost, bets, voting, favorites, taken, ABindex]);

    // check the search params and return the tab index passed (or return the default)
    function getOverrideTab() {
      var tab_override = BetStatus.PENDING_BETS;

      if (searchParams.get('tab')) {
        //console.log(searchParams.get('tab'));
        switch (searchParams.get('tab')) {
          case 'win' : 
            tab_override = BetStatus.WON_BETS; 
            break;
          
          case 'lose': 
            tab_override = BetStatus.LOST_BETS; 
            break;
          
          case 'pending': 
            tab_override = BetStatus.PENDING_BETS; 
            break;

          case 'resolving':
            tab_override = BetStatus.RESOLVING_BETS;
            break;

          default : 
            tab_override = BetStatus.PENDING_BETS;
        }
      }
      //console.log(`tab override ${tab_override}`);
      return tab_override;
    }

    // determine the correct collection of bets to display in the UI
    const setActiveTab = (activeTab) => {
               
      //console.log(`setting current tab to ${activeTab}, previous tab ${previousABIndex}`);

      switch(activeTab) {
        case BetStatus.PENDING_BETS:
          setCurrentBets(voting);
          break;

        case BetStatus.TAKEN_BETS:
          setCurrentBets(taken);
          break;
      
        case BetStatus.ALL_BETS:
          setCurrentBets(bets);
          break;
    
        case BetStatus.WON_BETS:
          setCurrentBets(won);
          break;
    
        case BetStatus.LOST_BETS:
          setCurrentBets(lost);
          break;

        case BetStatus.LIVE_BETS:
          setCurrentBets(open);
          break;
    
        case BetStatus.FAV_BETS:
          setCurrentBets(favorites);
          break;
        
        case BetStatus.RESOLVING_BETS:
          setCurrentBets(resolving);
          break;

        default:
          setCurrentBets(voting);
      }

        setPreviousABIndex(activeTab);      
    }

    const handleDelete = (betID) => {
      dispatch(deleteBet(betID, userId));
    }

    const handleLike = (betID, userID) => {
      dispatch(likeBet(betID, userID));
      //console.log(`favorites: ${JSON.stringify(favorites)}`);
    }

    const handleVotingBets = () => {
      setABindex(BetStatus.PENDING_BETS);
      window.localStorage.setItem('INCREDIBETS_AB_INDEX', JSON.stringify(BetStatus.PENDING_BETS)); 
    }

    const handleLiveBets = () => {
      setABindex(BetStatus.TAKEN_BETS);
      window.localStorage.setItem('INCREDIBETS_AB_INDEX', JSON.stringify(BetStatus.TAKEN_BETS)); 
    }

    const handleBetHistory = () => {
      //console.log(`all bets selected ${JSON.stringify(bets)}`);
      setABindex(BetStatus.ALL_BETS);
      window.localStorage.setItem('INCREDIBETS_AB_INDEX', JSON.stringify(BetStatus.ALL_BETS)); 
    }

    const handleBetWon = () => {      
      setABindex(BetStatus.WON_BETS);
      window.localStorage.setItem('INCREDIBETS_AB_INDEX', JSON.stringify(BetStatus.WON_BETS));      
    }

    const handleBetLost = () => {
      setABindex(BetStatus.LOST_BETS);
      window.localStorage.setItem('INCREDIBETS_AB_INDEX', JSON.stringify(BetStatus.LOST_BETS));
    }

    const handleOpenBets = () => {
      setABindex(BetStatus.LIVE_BETS);
      window.localStorage.setItem('INCREDIBETS_AB_INDEX', JSON.stringify(BetStatus.LIVE_BETS)); 
    }

    const handleFavorites = () => {
      setABindex(BetStatus.FAV_BETS);
      window.localStorage.setItem('INCREDIBETS_AB_INDEX', JSON.stringify(BetStatus.FAV_BETS)); 
    }

    const handleResolvingBets = () => {
      setABindex(BetStatus.RESOLVING_BETS);
      window.localStorage.setItem('INCREDIBETS_AB_INDEX', JSON.stringify(BetStatus.RESOLVING_BETS));
    }

    // BLOCK - Error Handling
    const handleErrorClose = () => {
      // dispatch and clear the error from the store
      dispatch(clearIBError());

    }
    const checkforErrors = () => {
      // put any error messages at the top of the screen
      if (isError) return ( 
        <Alert variant="filled" severity="error" sx={{ mt: 2, mb: 2 }} onClose={handleErrorClose}>
          <AlertTitle>{errorTitle}</AlertTitle>
          <Typography>{JSON.stringify(errorDetail).replaceAll('"','')}</Typography>           
        </Alert>
      );
    }   

    // oh dear - nothing to see here friends
    if (!bets?.length && !open?.length && !favorites?.length && !taken?.length && !voting?.length && !won?.length && !lost?.length && !resolving?.length
      && !isLoading && !isLoadingFavs && !isLoadingTaken && !isLoadingLost && !isLoadingOpen && !isLoadingVoting && !isLoadingWon && !isLoadingResolving) return (
      <>
      <Paper variant='outlined' sx={{ p:2, borderRight: 0, borderTop: 0, borderLeft: 0, mb: 1 }}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <MonetizationOnIcon sx={{ color: 'dimgray' }} fontSize='large'  />
          <Typography variant='h6' color="dimgray" >my bets</Typography>
          <Button variant="text" onClick={() => navigate(-1)}>&lt; Back</Button>
        </Stack>
      </Paper>   
      <Paper sx={{ m: 2,  p: 2}} elevation={2}>
        <Stack sx={{ mt: 3 }} spacing={2} direction="column" justifyContent="center">
          <Typography variant="h6">you currently have 0 bets, why not create one?</Typography>
          <Button variant='contained' href="/create-bet" startIcon={<AddBoxIcon />}>Create Bet </Button>    
        </Stack>
      </Paper>
      </>
    );

    if (isLoading || isLoadingFavs || isLoadingTaken || isLoadingLost || isLoadingOpen || isLoadingVoting || isLoadingWon || isLoadingResolving) return (
      <Box sx={{ width: '100%', backgroundColor: 'transparent', m:5 }}>        
        <Stack alignItems="center" sx={{ m:5 }}>
          <CircularProgress />
        </Stack>
      </Box>
    );

    if (!authenticated) {
      dispatch({ type: actionType.LOGOUT });
      navigate('/login', {replace: true});
    }

    return (
    
      <>
      { mir && 
      <MoneyRain /> 
      }

      <Paper variant='outlined' sx={{ p:2, borderRight: 0, borderTop: 0, borderLeft: 0, mb: 1 }}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <MonetizationOnIcon sx={{ color: 'dimgray' }} fontSize='large'  />
          <Typography variant='h6' color="dimgray" >my bets</Typography>
          <Button variant="text" onClick={() => navigate(-1)}>&lt; Back</Button>
        </Stack>
      </Paper>      

      { checkforErrors() }

      <Grid container sx={{ backgroundColor:"whitesmoke" }} spacing={1} >

        <Grid item xs={5} sm={4} md={2} lg={2} xl={2}>
          
          { /* ----------- LEFT HAND SIDE MENU ----------- */ }
          <Paper sx={{ width: '100%' }} elevation={0}>
            
            <Box sx={{ backgroundColor: 'white', height: '30px', width: '100%'}} display="flex" alignItems="center" justifyContent="center" >
              <Typography variant='caption'><strong>ACTIVE&nbsp;BETS</strong></Typography>
            </Box>
            
            <ButtonGroup
              orientation="vertical"
              aria-label="vertical outlined button group"
              sx={{ backgroundColor: 'White' }}
              fullWidth={true}
              variant="text"
            >

              <Button key="open" variant={ABindex === BetStatus.LIVE_BETS ? "contained" : "text"} startIcon={<HourglassEmptyIcon />} fullWidth={true}
                sx={{ justifyContent: "flex-start", whiteSpace: "nowrap"}} onClick={handleOpenBets}>
                  {openCount} {BetStatus.LIVE_BETS_LABEL} 
              </Button>

              <Button key="live" variant={ABindex === BetStatus.TAKEN_BETS ? "contained" : "text"} startIcon={<AvTimerIcon />} fullWidth={true}
                sx={{justifyContent: "flex-start", whiteSpace: "nowrap"}} onClick={handleLiveBets}>
                  {takenCount} {BetStatus.TAKEN_BETS_LABEL}
              </Button>

              <Badge badgeContent={waitingforvotebets.length} color="success">
                <Button key="voting" variant={ABindex === BetStatus.PENDING_BETS ? "contained" : "text"} startIcon={<ThumbsUpDownIcon />} fullWidth={true}
                  sx={{justifyContent: "flex-start", whiteSpace: "nowrap"}}  onClick={handleVotingBets} >
                    {votingCount} {BetStatus.PENDING_BETS_LABEL}
                </Button>
              </Badge>              

              <Button key="resolving" variant={ABindex === BetStatus.RESOLVING_BETS ? "contained" : "text"} startIcon={<GavelIcon />} fullWidth={true}
                sx={{justifyContent: "flex-start", whiteSpace: "nowrap"}} onClick={handleResolvingBets}>
                  {resolvingCount} {BetStatus.RESOLVING_BETS_LABEL}
              </Button>

            </ButtonGroup>

            <Box sx={{ backgroundColor: 'white', height: '30px', mt: 2, width: '100%'}} display="flex" alignItems="center" justifyContent="center">
              <Typography variant='caption'><strong>BET HISTORY</strong></Typography>
            </Box>
            <ButtonGroup
              orientation="vertical"
              aria-label="vertical outlined button group"
              sx={{ backgroundColor: 'White' }}
              fullWidth={true}
              variant="text"
            >
              <Button key="won" variant={ABindex === BetStatus.WON_BETS ? "contained" : "text"} startIcon={<EmojiEventsIcon />} fullWidth={true}
                sx={{justifyContent: "flex-start"}} onClick={handleBetWon}>
                  {wonCount} Won
              </Button>
              <Button key="lost" variant={ABindex === BetStatus.LOST_BETS ? "contained" : "text"} startIcon={<ThumbDownOffAltIcon />} fullWidth={true}
                sx={{justifyContent: "flex-start"}} onClick={handleBetLost}>
                  {lostCount} Lost
              </Button>             
              <Button key="favorites" variant={ABindex === BetStatus.FAV_BETS ? "contained" : "text"} startIcon={<FavoriteIcon />} fullWidth={true}
                sx={{justifyContent: "flex-start"}} onClick={handleFavorites}>
                  {favCount} Favorites
              </Button>
              <Button key="history" variant={ABindex === BetStatus.ALL_BETS ? "contained" : "text"} startIcon={<HistoryIcon />} fullWidth={true}
                sx={{justifyContent: "flex-start"}} onClick={handleBetHistory}>
                  {betCount} All
              </Button>
            </ButtonGroup>
          </Paper>
        </Grid>

        { /* ----------- MAIN BET UI CONTAINER ----------- */ }        
        <Grid item container xs={7} sm={8} md={10} lg={10} xl={10} spacing={1}>                     
            
              { /* ----------- BET HEADER BAR ----------- */}

              <Grid item xs={12}>
                { ABindex === BetStatus.PENDING_BETS &&  
                <Stack direction="row" sx={{ backgroundColor: "DarkSlateGrey", p:1 }} spacing={1}>
                  <ThumbsUpDownIcon sx={{ color: 'white'}} />
                  <Typography sx={{ color: 'white', textTransform: "uppercase"}}>{votingCount} {BetStatus.PENDING_BETS_LABEL} bets:</Typography>
                  <Typography sx={{ color: 'white' }}>bets currently waiting for votes</Typography>
                </Stack>
                }     
                { ABindex === BetStatus.TAKEN_BETS &&  
                <Stack direction="row" sx={{ backgroundColor: "#B6CDCD", p:1 }} spacing={1}>
                  <AvTimerIcon />
                  <Typography sx={{ textTransform: "uppercase"}}>{takenCount} {BetStatus.TAKEN_BETS_LABEL} bets:</Typography>
                  <Typography>bets you've taken that are waiting on the end date of the bet to settle </Typography>
                </Stack>
                }     
                { ABindex === BetStatus.ALL_BETS &&  
                <Stack direction="row" sx={{ backgroundColor: "DodgerBlue", p:1 }} spacing={1}>
                  <HistoryIcon sx={{ color: 'white'}} />
                  <Typography sx={{ ml:1, color: 'white' }}>{betCount} HISTORY: your betting history...</Typography>
                </Stack>
                } 
                { ABindex === BetStatus.FAV_BETS &&  
                <Stack direction="row" sx={{ backgroundColor: "DodgerBlue", p:1 }} spacing={1}>
                  <FavoriteIcon sx={{ color: 'white'}} />
                  <Typography sx={{ ml:1, color: 'white' }}>{favCount} FAVORITES: bets that you are watching</Typography>
                </Stack>
                } 
                { ABindex === BetStatus.LIVE_BETS &&  
                <Stack direction="row" sx={{ backgroundColor: "DodgerBlue", p:1 }} spacing={1}>
                  <HourglassEmptyIcon sx={{ color: 'white'}} />
                  <Typography sx={{ ml:1, color: 'white' }}>{openCount} {BetStatus.LIVE_BETS_LABEL} Bets: bets that are still waiting for takers</Typography>
                </Stack>
                } 
                { ABindex === BetStatus.WON_BETS &&  
                <Stack direction="row" sx={{ backgroundColor: "DarkSlateGrey", p:1 }} spacing={1}>
                  <EmojiEventsIcon sx={{ color: 'white'}} />
                  <Typography sx={{ ml:1, color: 'white' }}>{wonCount} BETS WON: bets that I have won</Typography>
                </Stack>
                } 
                { ABindex === BetStatus.LOST_BETS &&  
                <Stack direction="row" sx={{ backgroundColor: "DarkSlateGrey", p:1 }} spacing={1}>
                  <ThumbDownOffAltIcon sx={{ color: 'white'}} />
                  <Typography sx={{ ml:1, color: 'white' }}>{lostCount} BETS LOST: bets that I have lost</Typography>
                </Stack>
                } 
                { ABindex === BetStatus.RESOLVING_BETS &&  
                <Stack direction="row" sx={{ backgroundColor: "Red", p:1 }} spacing={1}>
                  <GavelIcon sx={{ color: 'white'}} />
                  <Typography sx={{ color: 'white', textTransform: "uppercase"}}>{resolvingCount} bets in {BetStatus.RESOLVING_BETS_LABEL}: </Typography>
                  <Typography sx={{ color: 'white' }}>bets that are curently under review</Typography>
                </Stack>
                } 
              </Grid>

              { /* ----------- BETS ----------- */}

              { /* list out all of the bets for the given tab */ }
              { currentbets.length > 0 
              ? (
                currentbets.map(bet => (
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={bet?._id} >
                  <Bet bet={bet} onDelete={handleDelete} onLike={handleLike} user={user} authenticated={authenticated} />
                </Grid>
                ))
              )
              :
                (
                <Grid item sx={{ minHeight: '50px', mt: 2, ml:4 }} >
                  <Typography sx={{ height: '250px' }}>0 bets...</Typography>  
                </Grid>
                )
              }
            
              { /* ---------- BET VIEW FOOTER ---------- */ }
              <Grid item xs={12} >

                { ABindex === BetStatus.PENDING_BETS &&  
                <>
                  <Stack alignItems="center" spacing={1}>
                  { (!isLoadingVoting && (votingPages > 1))  &&
                    <Box sx={{ p: 1, width: '100%' }} display="flex" alignItems="center" justifyContent="center">
                      <Pagination 
                        count={votingPages} 
                        page={vPage || 1}
                        variant="outlined" 
                        shape="rounded"
                        color="primary" 
                        onChange={handleVotingPaginationChange}
                    />
                    </Box>
                  }
                  </Stack>
                  <Box sx={{ backgroundColor: "DarkSlateGrey", p:1, minHeight: '20px' }} />
                </>
                }     
                { ABindex === BetStatus.TAKEN_BETS &&  
                <>
                  <Stack alignItems="center" spacing={1}>
                  { (!isLoadingTaken && (takenPages > 1))  &&
                    <Box sx={{ p: 1, width: '100%' }} display="flex" alignItems="center" justifyContent="center">
                      <Pagination 
                        count={takenPages} 
                        page={tPage || 1}
                        variant="outlined" 
                        shape="rounded"
                        color="primary" 
                        onChange={handleTakenPaginationChange}
                    />
                    </Box>
                  }
                  </Stack>
                  <Box sx={{ backgroundColor: "#B6CDCD", p:1, minHeight: '20px' }} />
                </>
                }     
                { ABindex === BetStatus.ALL_BETS &&  
                <>
                  <Stack alignItems="center" spacing={1}>
                  { (!isLoading && (numberOfPages > 1))  &&
                    <Box sx={{ p: 1, width: '100%' }} display="flex" alignItems="center" justifyContent="center">
                      <Pagination 
                        count={numberOfPages} 
                        page={hPage || 1}
                        variant="outlined" 
                        shape="rounded"
                        color="primary" 
                        onChange={handleHistoryPaginationChange}
                    />
                    </Box>
                  }
                  </Stack>
                  <Box sx={{ backgroundColor: "DodgerBlue", p:1, minHeight: '20px' }} />
                </>
                } 
                { ABindex === BetStatus.FAV_BETS &&  
                <>             
                <Stack alignItems="center" spacing={1}>
                  { (!isLoadingFavs && (favPages > 1))  &&
                    <Box sx={{ p: 1, width: '100%' }} display="flex" alignItems="center" justifyContent="center">
                      <Pagination 
                        count={favPages} 
                        page={fPage || 1}
                        variant="outlined" 
                        shape="rounded"
                        color="primary" 
                        onChange={handleFavPaginationChange}
                    />
                    </Box>
                  }
                  </Stack>
                  <Box sx={{ backgroundColor: "DodgerBlue", p:1, minHeight: '20px' }} />
                </>
                } 
                { ABindex === BetStatus.LIVE_BETS &&         
                <>             
                  <Stack alignItems="center" spacing={1}>
                    { (!isLoadingOpen && (openPages > 1))  &&
                      <Box sx={{ p: 1, width: '100%' }} display="flex" alignItems="center" justifyContent="center">
                        <Pagination 
                          count={openPages} 
                          page={oPage || 1}
                          variant="outlined" 
                          shape="rounded"
                          color="primary" 
                          onChange={handleOpenPaginationChange}
                      />
                      </Box>
                    }
                    </Stack>
                    <Box sx={{ backgroundColor: "DodgerBlue", p:1, minHeight: '20px' }} />
                  </>
                } 
                { ABindex === BetStatus.WON_BETS &&  
                <>
                  <Stack alignItems="center" spacing={1}>
                  { (!isLoadingWon && (wonPages > 1))  &&
                    <Box sx={{ p: 1, width: '100%' }} display="flex" alignItems="center" justifyContent="center">
                      <Pagination 
                        count={wonPages} 
                        page={wPage || 1}
                        variant="outlined" 
                        shape="rounded"
                        color="primary" 
                        onChange={handleWonPaginationChange}
                    />
                    </Box>
                  }
                  </Stack>
                  <Box sx={{ backgroundColor: "DarkSlateGrey", p:1, minHeight: '20px' }} />
                </>
                } 
                { ABindex === BetStatus.RESOLVING_BETS &&  
                <>
                  <Stack alignItems="center" spacing={1}>
                  { (!isLoadingResolving && (resolvingPages > 1))  &&
                    <Box sx={{ p: 1, width: '100%' }} display="flex" alignItems="center" justifyContent="center">
                      <Pagination 
                        count={resolvingPages} 
                        page={rPage || 1}
                        variant="outlined" 
                        shape="rounded"
                        color="primary" 
                        onChange={handleResolvingPaginationChange}
                    />
                    </Box>
                  }
                  </Stack>
                  <Box sx={{ backgroundColor: "Red", p:1, minHeight: '20px' }} />
                </>
                } 
                { ABindex === BetStatus.LOST_BETS &&  
                <>
                  <Stack alignItems="center" spacing={1}>
                  { (!isLoadingLost && (lostPages > 1))  &&
                    <Box sx={{ p: 1, width: '100%' }} display="flex" alignItems="center" justifyContent="center">
                      <Pagination 
                        count={lostPages} 
                        page={lPage || 1}
                        variant="outlined" 
                        shape="rounded"
                        color="primary" 
                        onChange={handleLostPaginationChange}
                    />
                    </Box>
                  }
                  </Stack>
                  <Box sx={{ backgroundColor: "DarkSlateGrey", p:1, minHeight: '20px' }} />
                </>
                } 
              </Grid>
            </Grid>
        </Grid>
      

      {/* MAKE IT RAIN - WE HVE A WINNER!! */}
      <Dialog open={userWonBet} onClose={handleWonBetClose} maxWidth="sm" fullWidth={true}>
        <DialogTitle>incredibets: WINNER!</DialogTitle>
        <Box position="absolute" top={0} right={0}>
            <IconButton onClick={handleWonBetClose}>
            <CloseIcon />
            </IconButton>
        </Box>
        <DialogContent dividers>
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Typography variant="h6">Congratulations {user.firstname}!</Typography>
            <Typography>You won {winBet.title}.</Typography>
            <Typography variant='h6'>Your winnings total ${winAmount}</Typography>
            <Typography variant='caption' sx={{ mt: 2 }}>(less ${serviceFee} in service fees for incredibets)</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
            <Button color="secondary" variant="contained" onClick={handleWonBetClose}>
                Sweet!
            </Button>
        </DialogActions>
      </Dialog>

        {/* BETTER LUCK NEXT TIME - USER LOST THIS BET :/ */}
        <Dialog open={userLostBet} onClose={handleLostBetClose} maxWidth="sm" fullWidth={true}>
        <DialogTitle>incredibets: Oh Snap!</DialogTitle>
        <Box position="absolute" top={0} right={0}>
            <IconButton onClick={handleLostBetClose}>
            <CloseIcon />
            </IconButton>
        </Box>
        <DialogContent dividers>
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Typography variant="h6">Better betting next time {user.firstname}</Typography>
            <Typography>You lost the bet: {winBet.title}.</Typography>
            <Typography variant='h6'>You lost ${loseAmount}</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
            <Button color="secondary" variant="contained" onClick={handleLostBetClose}>
                Keep on keeping on
            </Button>
        </DialogActions>
      </Dialog>

      {/* OH OH - DISAGREEMENT ON BET OUTCOME! :/ */}
      <Dialog open={userPendingBet} onClose={handlePendingBetClose} maxWidth="sm" fullWidth={true}>
        <DialogTitle>incredibets: Oh Snap!</DialogTitle>
        <Box position="absolute" top={0} right={0}>
            <IconButton onClick={handlePendingBetClose}>
            <CloseIcon />
            </IconButton>
        </Box>
        <DialogContent dividers>
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Typography variant="h6">{user.firstname}, the bet <strong>"{winBet.title}"</strong> is unresolved. </Typography>
            <Typography>What does this mean? Bets are unresolved when the bet participants do not agree on the outcome of the bet:</Typography>
            <Grid container direction="row" spacing={1} justifyContent="center" alignItems="center">
                      <Grid item xs={6}>                            
                        <Stack spacing={1} direction="column" sx={{ mr: 2}} alignItems="flex-end" >
                          <Typography sx={{ fontWeight: 600, textAlign: "center" }}>Your Vote</Typography>
                          <Divider  sx={{borderBottomWidth: 2, width: '100%'}}/>
                          <Stack spacing={1} direction="row" justifyContent="center" alignItems="center" sx={{ mt:2 }}>                                
                            <Avatar alt={usersVote()?.name} src={usersVote()?.picture} referrerPolicy="no-referrer" />
                            <Stack direction="column">
                              <Typography>{usersVote()?.name}</Typography>
                              <Typography variant="caption" sx={{ fontWeight: 100 }} noWrap={true}>member since {new Date(usersVote()?.createdAt).getFullYear()}</Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                      <Stack spacing={1} direction="column" sx={{ ml: 2}} alignItems="flex-start" >
                        <Typography sx={{ fontWeight: 600, textAlign: "center" }}>Their Vote</Typography>
                        <Divider  sx={{borderBottomWidth: 2, width: '100%'}}/>
                        <Stack spacing={1} direction="row" justifyContent="center" alignItems="center" sx={{ mt:2 }}>                                
                        <Avatar alt={othersVote()?.name} src={othersVote()?.picture} referrerPolicy="no-referrer" />
                        <Stack direction="column">
                          <Typography>{othersVote()?.name}</Typography>
                          <Typography variant="caption" sx={{ fontWeight: 100 }} noWrap={true}>member since {new Date(othersVote()?.createdAt).getFullYear()}</Typography>
                        </Stack>
                          </Stack>
                        </Stack>                                                                                
                      </Grid>                         
                    </Grid>
            <Typography variant='h6'>This bet will automatically <strong>bust</strong> unless appealed.</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
            <Button color="error" variant="contained" onClick={handlePendingBetClose}>
                Appeal
            </Button>
            <Button variant="outlined" onClick={handlePendingBetClose}>
                Close
            </Button>
        </DialogActions>
      </Dialog>

      </>
    );
  };

export default MyBets;

const SuperContainer = styled.div`
  display: block;
  align-items: center;
  width: 100%;
  overflow: auto;
`; 

const RainContainer = styled.div`
  @keyframes falldown {
    0% { margin-top: 0; }
    100% { margin-top: ${props => props.screenheight + 100}px; }
  }

  left: ${props => props.offset}px;
  position: absolute;
  top: 10px;

  font-size: 58px;
  animation-name: falldown;
  animation-duration: ${props => props.speed}s;
`;