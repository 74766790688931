import { FETCH_ALL_USERS, FETCH_USER, START_LOADING, END_LOADING, ACTIVATE_USER, MAKE_USER_ADMIN, IB_ERROR } from '../constants/actionTypes';
import { getApp } from '../utils/utils';

//
// return all of the incredibets users
//
export const getUsers = (page = 1, usersPerPage = 20) => async (dispatch) => {
    
    try {
    
        dispatch({ type: START_LOADING });

        // we don't have an authenticated user yet
        if (!window.incredibets.realmUser) {
            // get an authenticated user oblect from realm-web
            //console.log('getUsers null realmUser')
            window.incredibets.realmUser = await getApp();
        }

        let request = {
            page: page,
            usersPerPage: usersPerPage
        };

        const results = JSON.parse(await window.incredibets.realmUser.functions.fetchUsers(JSON.stringify(request))).body;
        if (results.error !== undefined) {
            throw results;
        }
        
        //const { data } = await api.fetchUsers();
        //console.log(`ACTION: users fetched: ${JSON.stringify(data)}`);
        dispatch({ type: FETCH_ALL_USERS, results});
        dispatch({ type: END_LOADING });

    } catch (error) {
        console.log(`getUsers catch: ${JSON.stringify(error)}`);
        dispatch({ type: IB_ERROR, error });
        dispatch({ type: END_LOADING });
    }

}

//
// return a specific incredibets user
//
export const getUser = (id) => async (dispatch) => {
    
    try {

        dispatch({ type: START_LOADING });

        // we don't have an authenticated user yet
        if (!window.incredibets.realmUser) {
            // get an authenticated user oblect from realm-web
           //console.log('getUser null realmUser')
            window.incredibets.realmUser = await getApp();
        }

        let request = {
            id: id
        }
        
        const results = JSON.parse(await window.incredibets.realmUser.functions.fetchUsers(JSON.stringify(request))).body;
        if (results.error !== undefined) {
            throw results;
        }

        //const { data } = await api.fetchUser(id);
        //console.log(`user obtained: ${JSON.stringify(data.result)}`)
        dispatch({ type: FETCH_USER, results });
        dispatch({ type: END_LOADING });

    } catch (error) {
        console.log(`getUser catch: ${JSON.stringify(error)}`);
        dispatch({ type: IB_ERROR, error });
        dispatch({ type: END_LOADING });
    }

}

//
// set a user to either activated or disactivated state
//
export const activateUser = (userid) => async (dispatch) => {
    
    try {
    
        // we don't have an authenticated user yet
        if (!window.incredibets.realmUser) {
            // get an authenticated user oblect from realm-web
            //console.log('activateUser null realmUser')
            window.incredibets.realmUser = await getApp();
        }
    
        let request = {
            userid: userid
        }

        const results = JSON.parse(await window.incredibets.realmUser.functions.toggleUserActive(JSON.stringify(request))).body;
        if (results.error !== undefined) {
            throw results;
        }

        //console.log(`activation request for user ${userid}`);
        //const { data } = await api.activateUser(userid);
        dispatch({ type: ACTIVATE_USER, results });
        //console.log(`user activated: ${JSON.stringify(data.result)}`)

    } catch (error) {
        console.log(`activateUser catch: ${JSON.stringify(error)}`);
        dispatch({ type: IB_ERROR, error });
        dispatch({ type: END_LOADING });
    }

};

//
// set the given user's admin status
//
export const setAdmin = (userid) => async (dispatch) => {
    
    try {

        // we don't have an authenticated user yet
        if (!window.incredibets.realmUser) {
            // get an authenticated user oblect from realm-web
            //console.log('setAdmin null realmUser')
            window.incredibets.realmUser = await getApp();
        }

        let request = {
            userid: userid
        }

        const results = JSON.parse(await window.incredibets.realmUser.functions.toggleUserAdmin(JSON.stringify(request))).body;
        if (results.error !== undefined) {
            throw results;
        }

        // flip the admin status of a user
        //const { data } = await api.setUserAdmin(userid);
        dispatch({ type: MAKE_USER_ADMIN, results });
        
    } catch (error) {
        console.log(`setAdmin catch: ${JSON.stringify(error)}`);
        dispatch({ type: IB_ERROR, error });
        dispatch({ type: END_LOADING });
    }

};
