import { Typography, Paper, Stack, Grid, Box, Alert, AlertTitle, CircularProgress, Button, Pagination } from "@mui/material";
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import GavelIcon from '@mui/icons-material/Gavel';
import Bet from './bet/bet';
import { useSelector } from 'react-redux';
import { isUserAuthenticated } from '../../utils/utils';
import { getAllResolvingBets, clearIBError } from '../../actions/bets';
import { useNavigate} from 'react-router-dom';

const BetArbiration = props => {
    const dispatch = useDispatch();
    let dispatchedRef = useRef(false);

    const { resolving, resolvingPages ,isLoadingResolving } = useSelector(state => state.resolving);
    const { isError, errorTitle, errorDetail } = useSelector(state => state.bets)

    const [user] = useState(JSON.parse(localStorage.getItem('profile'))?.user);
    const authenticated = isUserAuthenticated();

    const [page, setPage] = useState(1);
    const [previousPage, setPreviousPage] = useState(1);

    const navigate = useNavigate();

    const handlePaginationChange = (event, value) => {
        setPage(value);
    }

    // BLOCK - Error Handling
    const handleErrorClose = () => {
        // dispatch and clear the error from the store
        dispatch(clearIBError());
    }
    const checkforErrors = () => {
        // put any error messages at the top of the screen
        if (isError) return ( 
            <Alert variant="filled" severity="error" sx={{ mt: 2, mb: 2 }} onClose={handleErrorClose}>
            <AlertTitle>{errorTitle}</AlertTitle>
            <Typography>{JSON.stringify(errorDetail).replaceAll('"','')}</Typography>           
            </Alert>
        );
    }   

    useEffect(() => {
      
        // track if we're already dispatched so we don't excessivly reterive data
        const dispatched = dispatchedRef.current;
  
        // if we don't have any bets yet - then go get some
        if (!dispatched) {  
          // get your bets, get your bets!
          dispatch(getAllResolvingBets(page));            
          dispatchedRef.current = true;
        } 
  
        if (page !== previousPage) {
          setPreviousPage(page);
          dispatch(getAllResolvingBets(page));
        }
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [dispatch, resolving, page]
    );    

    if (isLoadingResolving) return (
        <Box sx={{ width: '100%', backgroundColor: 'transparent', m:5 }}>          
          <Stack alignItems="center" sx={{ m:5 }}>
            <CircularProgress />
          </Stack>
        </Box>
    );

    return(

        <>

        <Paper variant='outlined' sx={{ p:2, borderRight: 0, borderTop: 0, borderLeft: 0, mb: 1 }}>
            <Stack direction='row' spacing={1} alignItems='center'>
                <GavelIcon sx={{ color: 'dimgray' }} fontSize='large'  />
                <Typography variant='h6' color="dimgray" >bet arbitration (admin)</Typography>
                <Button variant="text" onClick={() => navigate(-1)}>&lt; Back</Button>
            </Stack>
        </Paper>    

        { checkforErrors() }

        { /* ----------- BETS ----------- */}
        <Grid container spacing={1}>  
            { /* list out all of the bets for the given tab */ }
            { resolving.length > 0 
            ? (
                <>
                { resolving.map(bet => (
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={bet?._id} >
                    <Bet bet={bet} user={user} authenticated={authenticated} />
                </Grid>
                )) }
                <Grid item xs={12}>
                    <Stack alignItems="center" spacing={1}>
                        { (!isLoadingResolving && (resolvingPages > 1))  &&
                        <Box sx={{ p: 1, width: '100%' }} display="flex" alignItems="center" justifyContent="center">
                            <Pagination 
                            count={resolvingPages} 
                            page={page || 1}
                            variant="outlined" 
                            shape="rounded"
                            color="primary" 
                            onChange={handlePaginationChange}
                        />
                        </Box>
                        }
                    </Stack>
                </Grid>
                </>
            )
            :
            (
            <Grid item sx={{ minHeight: '50px', mt: 2, ml:4 }} >
                <Typography>0 bets...</Typography>  
            </Grid>
            )
            }
        </Grid>

        </>
    );

};

export default BetArbiration;