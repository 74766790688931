import { combineReducers } from 'redux';

import auth from './auth';
import bets from './bets';
import wallet from './wallet';
import users from './users';
import favorites from './favorites';
import participating from './participating';
import voting from './voting';
import won from './won';
import lost from './lost';
import open from './open';
import activity from './activity';
import taken from './taken';
import resolving from './resolving';
import ledger from './ledger';

export const reducers = combineReducers({ auth, bets, wallet, users, favorites, participating, voting, won, lost, open, activity, taken, resolving, ledger });