import { FETCH_TAKEN_BETS_BY_USER, TAKE_BET, START_LOADING_TAKEN, END_LOADING_TAKEN, DELETE_BET, LIKE_BET, LOGOUT } from '../constants/actionTypes';

const INITIAL_STATE = {
  isLoadingTaken: true, 
  taken: [], 
  takenCount: 0,
  takenPage: 1,
  takenPages: 1,
  takenPerPage: 6
}

const takenReducer = (state = INITIAL_STATE, action) => {
  
    switch (action.type) {

      case LOGOUT:
        return { ...INITIAL_STATE };

      case START_LOADING_TAKEN:
        return { ...state, isLoadingTaken: true };

      case END_LOADING_TAKEN:
        return { ...state, isLoadingTaken: false};

      case TAKE_BET:
        //console.log('TAKE BET from PARTICIPATING REDUCER!!!');
        return { 
          ...state,
          taken: [...state.taken, action.results.bet],
          takenCount: state.takenCount + 1
        };

      case FETCH_TAKEN_BETS_BY_USER:
        //console.log(`REDUCING FETCH_PARTICIPATING_BETS_BY_USER: ${JSON.stringify(action)}`);
        return {
          ...state, 
          taken: action.results.bets,
          takenCount: Number(action.results.numberOfBets),
          takenPage: Number(action.results.currentPage),
          takenPages: Number(action.results.numberOfPages),
          takenPerPage: Number(action.results.bets_per_page)
        };

      case DELETE_BET:
        if (state.taken.some((bet) => bet._id === action.results.betID)) {
          //console.log('bet found in favorites array, removing it');
          return {
            ...state, 
            taken: state.taken.filter((bet) => bet._id !== action.results.betID),
            takenCount: state.takenCount - 1,
            takenPages: (state.takenCount - 1)/state.takenPerPage
          };
        } else {
          return { ...state };
        };

      case LIKE_BET:
        return { 
          ...state, 
          taken: state.taken.map((bet) => (bet._id === action.results.bet._id ? action.results.bet : bet)) 
        };

      default:
        return state;
  }
};

export default takenReducer;