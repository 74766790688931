import { FETCH_WON_BETS_BY_USER, START_LOADING_WON, END_LOADING_WON, DELETE_BET, LIKE_BET, LOGOUT } from '../constants/actionTypes';

const INITIAL_STATE = {
  isLoadingWon: true, 
  won: [], 
  wonCount: 0,
  wonPage: 1,
  wonPages: 1,
  wonPerPage: 6
}

const wonReducer = (state = INITIAL_STATE, action) => {

    // supported action types here
    switch (action.type) {

      case LOGOUT: 
        return { ...INITIAL_STATE };

      case START_LOADING_WON:
        return { ...state, isLoadingWon: true };

      case END_LOADING_WON:
        return { ...state, isLoadingWon: false};

      case FETCH_WON_BETS_BY_USER:
        //console.log(`FETCH_WON_BETS_BY_USER ${JSON.stringify(action.results)}`);
        return {
          ...state, 
          won: action.results.bets,
          wonCount: Number(action.results.numberOfBets),
          wonPage: Number(action.results.currentPage),
          wonPages: Number(action.results.numberOfPages),
          wonPerPage: Number(action.results.bets_per_page)
        };

      case DELETE_BET:
        if (state.won.some((bet) => bet._id === action.results.betID)) {
          //console.log('bet found in voting array, removing it');
          return {
            ...state, 
            won: state.voting.filter((bet) => bet._id !== action.results.betID),
            wonCount: state.wonCount - 1,
            wonPages: (state.wonCount - 1)/state.wonPerPage
          };
        } else {
          return { ...state };
        };

      case LIKE_BET:
        return { 
          ...state, 
          won: state.won.map((bet) => (bet._id === action.results.bet._id ? action.results.bet : bet)) 
        };

      default:
        return state;
    }

};

export default wonReducer;