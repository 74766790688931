
import { Avatar } from "@mui/material";
import { red } from '@mui/material/colors';

const IBAvatar = ( { picture, name } ) => {

    //console.log(`avatar time! profile_pic=${picture} name=${name} `);
    if (typeof picture === 'string') {
        return <Avatar referrerPolicy="no-referrer" alt={name} src={picture} sx={{ bgcolor: red[500]}}/>
    } else {
        return <Avatar referrerPolicy="no-referrer" alt={name} sx={{ bgcolor: red[500]}}/>
    }
};

export default IBAvatar;