import { FETCH_VOTING_BETS_BY_USER, START_LOADING_VOTE, END_LOADING_VOTE, DELETE_BET, LIKE_BET, LOGOUT } from '../constants/actionTypes';

const INITIAL_STATE = {
  isLoadingVoting: true, 
  voting: [],
  votingCount: 0, 
  votingPage: 1,
  votingPages: 1,
  votingPerPage: 6
}

const votingReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case LOGOUT:
      return { ...INITIAL_STATE };
    case START_LOADING_VOTE:
      return { ...state, isLoadingVoting: true };
    case END_LOADING_VOTE:
      return { ...state, isLoadingVoting: false};
    case FETCH_VOTING_BETS_BY_USER:
      return {
        ...state, 
        voting: action.results.bets,
        votingPage: Number(action.results.currentPage),
        votingPages: Number(action.results.numberOfPages),
        votingCount: Number(action.results.numberOfBets),
        betsPerPage: Number(action.results.bets_per_page)
      };
    case DELETE_BET:
      if (state.voting.some((bet) => bet._id === action.results.betID)) {
        //console.log('bet found in voting array, removing it');
        return {
          ...state, 
          voting: state.voting.filter((bet) => bet._id !== action.results.betID),
          votingCount: state.voteCount - 1,
          votingPages: (state.betCount - 1)/state.betsPerPage
        };
      } else {
        return { ...state };
      };
     
    case LIKE_BET:
      return { 
        ...state, 
        voting: state.voting.map((bet) => (bet._id === action.results.bet._id ? action.results.bet : bet)) 
      };
    default:
      return state;
  }
};

export default votingReducer;