import { useState }  from 'react';
import { useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { 
    Stack, Typography, Box, TextField, Container, Stepper, Step, 
    StepLabel, Paper, Grid, Avatar, Button, LinearProgress, Checkbox, 
    FormControlLabel, Dialog, DialogActions, DialogTitle, DialogContent, IconButton,
    useMediaQuery, useTheme, Tooltip, Divider, Alert
} from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { createbet } from '../../../actions/bets';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { isUserAuthenticated, getUserFromProfile } from '../../../utils/utils';
import * as actionType from '../../../constants/actionTypes';

function CreateBet() {

    const [formStep, setFormStep] = useState(0);
    const [open, setOpen] = useState(false);
    const { isError, errorDetail } = useSelector(state => state.bets);
    const [user] = useState(getUserFromProfile());
    const { watch, register, control, handleSubmit, getValues, formState: { errors, isValid } } = useForm({mode: 'all'});
    const steps = ["ready", "set", "bet!"];
    const totalSteps = steps.length;
    const isLastStep = formStep === totalSteps - 1;
    const progress = Math.min(((formStep+1)/totalSteps)*100, 100);
    const watchBetAmount = watch("bet_amount", "10");
    const watchBetReceiving = watch("odds_receiving", "1");
    const watchBetStaked = watch("odds_staked", "1");
    const isNotDefaultLiveDateTime = watch("isNotDefaultLiveDateTime", false);
    const today = new Date();
    const nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate()+7);
    const bet = getValues();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const completeFormStep = () => {
        setFormStep(cur => cur + 1)
    }

    const reverseFormStep = () => {
        setFormStep(cur => cur - 1)
    }

    const handleClickOpen = () => {
        setOpen(true);
    }
  
    const handleClickClose = () => {
        setOpen(false);
    }

    const handleCreateClick = () => {
        setOpen(false);
        dispatch(createbet(bet, navigate));
    }

    const submitForm = (values) => {
        //console.log(values)
        dispatch(createbet(values, navigate));
    };

    const getTime = (betdate) => {
        try { 
            return format(betdate, 'hh:mm')
        } 
        catch (e) {  }
    }

    const getDate = (betdate) => {
        try { 
            return format(betdate, 'MMMM dd, yyyy ')
        } 
        catch (e) {  }
    }

    if (!isUserAuthenticated()) {
        dispatch({ type: actionType.LOGOUT });
        navigate('/login', {replace: true});
    }

    return (
        
        <>
        <Paper variant='outlined' sx={{ p:2, borderRight: 0, borderTop: 0, borderLeft: 0, mb: 1 }}>
            <Stack direction='row' spacing={1} alignItems='center'>
                <AddBoxIcon sx={{ color: 'dimgray' }} fontSize='large'  />
                <Typography variant='h6' color="dimgray" >create a new bet</Typography>
                <Button variant="text" onClick={() => navigate(-1)}>&lt; Back</Button>
            </Stack>
        </Paper>   

        <Box sx={{ m: 5 }}>
            <Container>

            {isError && 
                <Alert variant="filled" severity="error" sx={{ m: 1 }}>
                    There was an error creating this bet: {errorDetail}.
                </Alert>
            }

                <Stepper activeStep={formStep} sx={{ m: 5}}>
                    {steps.map(currentStep => {
                        const label = currentStep;
                        return <Step key={label} >
                            <StepLabel>{label}</StepLabel>
                            </Step>
                    })}
                </Stepper>

                <Paper elevation={3}>
                    <Grid container component="main" >
                        <Grid item xs={12} sm={12} md={12} sx={{ m:2 }}>

                            <Stack direction="column" justifyContent="center" alignContent="center" alignItems="center" sx={{ mb: 2 }}>
                                <Avatar sx={{ bgcolor: 'primary.main' }}>
                                    <MonetizationOnIcon />
                                </Avatar>
                                <Typography component="h1" variant="h5"> new incredibet </Typography>
                            </Stack>

                            <form onSubmit={handleSubmit(submitForm)}>

                            {formStep === 0 && (
                            <section>
                                <Stack spacing={3} sx={{ m:2 }}>
                                    <TextField 
                                        id="title" 
                                        name="title" 
                                        label="my bet's title" 
                                        placeholder="an awesome, descrititive title for my bet" 
                                        fullWidth 
                                        InputLabelProps={{ shrink: true }} {...register('title', { required: "a title for your bet is required..." })} 
                                        error={Boolean(errors.title)}
                                        helperText={errors.title?.message}
                                    />
                                    <Tooltip title="Here is where you outline the terms of your bet.  Remember to be specific.  A vague bet could lead to you or the bet taker requesting an appeal." arrow placement="bottom">
                                        <TextField 
                                            id="description" 
                                            name="description" 
                                            label="my bet's description" 
                                            placeholder="all the rocking details of my bet" 
                                            multiline 
                                            rows={4} 
                                            fullWidth 
                                            InputLabelProps={{ shrink: true }} {...register('description', { required: "a description of your bet is required..." })}
                                            error={Boolean(errors.description)}
                                            helperText={errors.description?.message}
                                        />
                                    </Tooltip>
                                    <input type="hidden" id="owner_id" name="owner_id" value={user?._id} {...register('owner_id')} />
                                </Stack>
                            </section>
                            )}

                            {formStep === 1 && (
                            <section>
                                <Stack spacing={2} sx={{ m:2, mt: 4 }} alignItems="center">
                                    <Tooltip title="This is the maximum amount you are committing to this bet." arrow placement="top">
                                        <TextField 
                                            type="number" 
                                            id="bet_amount" 
                                            name="bet_amount" 
                                            label="i'm willing to risk" 
                                            InputProps={{startAdornment: '$'}} 
                                            fullWidth InputLabelProps={{ shrink: true }} 
                                            {...register('bet_amount', { required: "an amount for your bet is required..." })}
                                            error={Boolean(errors.bet_amount)}
                                            helperText={errors.bet_amount?.message}
                                            defaultValue="10"
                                            sx={{ maxWidth: 150 }}
                                        />
                                    </Tooltip>
                                    <Stack direction="row" spacing={0} justifyContent="center" alignContent="center" alignItems="center" textAlign="center" >
                                        <Typography variant="body1"> my bet pays </Typography>
                                        <Tooltip title="The ratio between the amount your bet will pay (first box) per the amount the bet taker will stake (second box)." arrow placement="top">
                                            <TextField 
                                                id="odds_receiving" 
                                                name="odds_receiving" 
                                                type="number" 
                                                label="" 
                                                sx={{ m:1, width: '10ch' }} 
                                                inputProps={{ min:0,  style: { textAlign: 'right' }}} 
                                                {...register('odds_receiving', { required: true })} 
                                                error={Boolean(errors.odds_receiving)}
                                                defaultValue="1"
                                            />
                                        </Tooltip>
                                        <Typography variant="body1">to</Typography>
                                        <Tooltip title="The ratio between the amount your bet will pay (first box) per the amount the bet taker will stake (second box)." arrow placement="top">
                                            <TextField 
                                                id="odds_staked" 
                                                name="odds_staked" 
                                                label="" 
                                                type="number" 
                                                sx={{ m:1, width: '10ch' }} 
                                                {...register('odds_staked', { required: true })}
                                                error={Boolean(errors.odds_staked)}
                                                defaultValue="1"
                                            />
                                        </Tooltip>
                                        <Typography variant="body1"> odds </Typography>
                                    </Stack>
                                    <Box component="span" borderRadius="5px" sx={{ p: 1, backgroundColor: 'success.main', border: '2px dashed grey' }} >
                                        <Stack spacing={1} alignItems="center">
                                            <Typography sx={{ color: 'common.white'}}>Win Amount: $
                                                <span>
                                                    { isNaN(parseFloat(watchBetAmount)) ? '10.00'
                                                        : parseFloat(parseFloat(watchBetAmount) * parseInt(watchBetStaked) / parseInt(watchBetReceiving)).toFixed(2)
                                                    }
                                                </span>
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </section> 
                            )}
                            
                            {formStep === 2 && (
                                <section>
                                    <Stack spacing={2} sx={{ m:2 }} alignItems="center" >
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={0} alignItems="center" >
                                            { !isNotDefaultLiveDateTime 
                                                ? ( <Typography>Send this bet to the incredibets marketplace ASAP!</Typography> )
                                                : ( <Typography>This bet goes live at a specific date and time. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </Typography> )
                                            }
                                                <FormControlLabel 
                                                    control={<Checkbox name="isNotDefaultLiveDateTime" id="isNotDefaultLiveDateTime" />} 
                                                    label="select a custom bet launch date/time" 
                                                    {...register('isNotDefaultLiveDateTime')}
                                                />
                                                <input type="hidden" id="default_betlivedate" name="default_betlivedate" value={today} {...register('default_betlivedate')} />
                                            </Stack>
                                            { isNotDefaultLiveDateTime && 
                                                <>
                                                <Stack direction="row" spacing={1}>
                                                    <Controller 
                                                        name="bet_livedate"
                                                        control={control}
                                                        defaultValue={today}
                                                        render={({ field : {onChange , value } }) => (
                                                            <DatePicker
                                                            onChange={onChange}
                                                            value={value}
                                                            label="my bet goes live on"
                                                            inputFormat="MM/dd/yyyy"
                                                            defaultValue={today}
                                                            minDate={today}
                                                            disablePast
                                                            name="livedate"
                                                            renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        )}
                                                    />
                                                    <Controller
                                                        name="bet_livetime"
                                                        control={control}
                                                        defaultValue={today}
                                                        render={({ field : {onChange , value } }) => (
                                                            <TimePicker
                                                                value={value}
                                                                onChange={onChange}
                                                                label="at"
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                                </>
                                            }
                                            
                                            <Stack direction="row" spacing={1} >
                                                <Controller 
                                                    name="bet_enddate"
                                                    control={control}
                                                    defaultValue={nextWeek}
                                                    render={({ field : {onChange , value } }) => (
                                                        <DatePicker
                                                        onChange={onChange}
                                                        value={value}
                                                        label="my bet ends on"
                                                        inputFormat="MM/dd/yyyy"
                                                        defaultValue={nextWeek}
                                                        minDate={today}
                                                        disablePast
                                                        name="enddate"
                                                        renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    )}
                                                />
                                                <Controller
                                                    name="bet_endtime"
                                                    control={control}
                                                    defaultValue={today}
                                                    render={({ field : {onChange , value } }) => (
                                                        <TimePicker
                                                            value={value}
                                                            onChange={onChange}
                                                            label="at"
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </Stack>
                                </section> 
                            )}
                            
                            <Grid
                                container
                                sx={{ p:2 }}
                                spacing={1}
                                justifyContent="space-between"
                                direction="row"
                                alignItems="center"
                            >
                     
                            {formStep > 0 ? (
                                <Grid item xs sx={{ float: "right" }}>
                                    <Button 
                                        type="button"
                                        variant="contained"
                                        startIcon={<NavigateBeforeIcon />}
                                        onClick={reverseFormStep}
                                    >
                                        { !isSmallScreen && 'Back' }
                                    </Button>
                                </Grid>
                            ) : (
                                <Grid item xs sx={{ float: "right" }}>
                                    <Button 
                                        type="button"
                                        variant="contained"
                                        startIcon={<NavigateBeforeIcon />}
                                        disabled
                                    >
                                        { !isSmallScreen && 'Back' }
                                    </Button>
                                </Grid>
                            )}

                                <Grid item xs={4} sm={6} md={7} xl={8} >
                                    <LinearProgress variant="determinate" value={progress} />
                                </Grid>

                                <Grid item xs sx={{ marginLeft: 'auto' }}>
                                    {!isLastStep &&
                                    <Button 
                                        onClick={completeFormStep}
                                        disabled={!isValid}
                                        type="button" 
                                        variant="contained" 
                                        endIcon={<NavigateNextIcon />} 
                                        color={'primary'} 
                                        sx={{ float: "right" }}
                                    >
                                         { !isSmallScreen && 'next' }
                                    </Button>
                                    }
                                    {isLastStep && 
                                    <>
                                    <Button 
                                        type="button" 
                                        onClick={() => handleClickOpen()}
                                        variant="contained" 
                                        disabled={!isValid}
                                        endIcon={<AddIcon />} 
                                        color={'primary'} 
                                        sx={{ float: "right" }}
                                    >
                                         { !isSmallScreen && 'create' }
                                    </Button>
                                    </>
                                    }
                                </Grid>

                                
                            </Grid>
                            </form>
                            {/*
                            <pre>
                                {JSON.stringify(watch(), null, 2)}
                            </pre>
                            */}
                        </Grid>
                    </Grid>
                </Paper>
                <Stack padding={5} alignItems="center">
                    <Button variant="outlined" sx={{ maxWidth: '100px'}} onClick={() => navigate(-1)}>Cancel</Button>
                </Stack>
                <Dialog open={open} onClose={handleClickClose} maxWidth="lg" fullWidth>
                    <DialogTitle align="center">Confirm IncrediBet Creation #itsalive #bethehouse</DialogTitle>
                    <Box position="absolute" top={0} right={0}>
                        <IconButton onClick={handleClickClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <DialogContent>
                        <Paper elevation={4}>
                            <Grid container spacing={1} sx={{ p: 1}}>
                                <Grid item xs={4}>
                                    <Typography align="right" sx={{ fontWeight: 600 }}>Title:</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography>{bet.title}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography align="right" sx={{ fontWeight: 600 }}>Description:</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography>{bet.description}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={5} direction="column" alignItems="center" justifyContent="center">
                                        <Stack direction="row" spacing={2}>
                                            <Stack direction="column" alignItems="center">
                                                <Typography variant="body" sx={{ fontWeight: 500 }} noWrap={true}>Odds Given</Typography>
                                                <Divider orientation="horizontal" flexItem />
                                                <Typography variant="body">{bet.odds_receiving}:{bet.odds_staked}</Typography>
                                                <Typography>&nbsp;</Typography>
                                                <Typography variant="body" sx={{ fontWeight: 500 }} noWrap={true}>To Win</Typography>
                                                <Divider orientation="horizontal" flexItem />
                                                <Typography variant="body">${parseFloat(bet.bet_amount*bet.odds_staked/bet.odds_receiving).toFixed(2)}</Typography>
                                            </Stack>
                                            <Typography />
                                            <Stack direction="column" alignItems="center">
                                                <Typography variant="body" sx={{ fontWeight: 500 }} noWrap={true}>Max Risk</Typography>
                                                <Divider orientation="horizontal" flexItem />
                                                <Typography variant="body">${parseFloat(bet.bet_amount).toFixed(2)}</Typography>
                                                <Typography>&nbsp;</Typography>
                                                <Typography variant="body" sx={{ fontWeight: 500 }} noWrap={true}>Payout</Typography>
                                                <Divider orientation="horizontal" flexItem />
                                                <Typography variant="body">${parseFloat(parseFloat(bet.bet_amount) + (bet.odds_staked / bet.odds_receiving * bet.bet_amount)).toFixed(2)}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography align="right" sx={{ fontWeight: 600 }}>Bet Live:</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    { isNotDefaultLiveDateTime
                                        ? <Typography>{`${getTime(bet.bet_livetime)} on ${getDate(bet.bet_livedate)}`}</Typography>
                                        : <Typography>right away</Typography>
                                    }
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography align="right" sx={{ fontWeight: 600 }}>Bet Settles On:</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography>{`${getTime(bet.bet_endtime)} on ${getDate(bet.bet_enddate)}`}</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </DialogContent>
                    <DialogContent>
                        <Typography align="right">Are you ready to create this bet?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" variant="contained" onClick={handleClickClose}>
                            Cancel
                        </Button>
                        <Button color="secondary" variant="contained" onClick={() => handleCreateClick()}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Box>
        </>
    );
  }
  
  export default CreateBet;