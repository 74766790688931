import { 
    Avatar, Typography, TableCell, TableRow, Stack
} from '@mui/material';
import { getIBDateAsString, getIBTimeAsString } from '../../utils/utils';
import * as activity_types from './activity_types';
import CreditCardIcon from '@mui/icons-material/CreditCard';

const ActivityItemSummary = ({ activity, MyKey, maxTitleLength = 40 }) => {

    var user_name = "";
    var vote = "";
    var bet_title = "";
    var picture = "";
    var liked = false;
    var amount = "0";
    var fee = "0";
    var winnings = "0";
    var loses = "0";
    var incredits = "0"
    var type = 0;

    // map activity type from the activity type label
    switch (activity.type) {
        case 'google_login'             : type = activity_types.GOOGLE_SIGNIN; break;
        case 'google_accountCreated'    : type = activity_types.GOOGLE_ACCOUNT_CREATED; break;
        case 'user_signin'              : type = activity_types.LOCAL_SIGNIN; break;
        case 'like_bet'                 : type = activity_types.LIKE_BET; break;
        case 'owner_bet_hold'           : type = activity_types.OWNER_HOLD; break;
        case 'taker_bet_hold'           : type = activity_types.TAKER_HOLD; break;
        case 'bet_settlement'           : type = activity_types.BET_SETTLEMENT; break;
        case 'incredibet_service_fee'   : type = activity_types.SERVICE_FEE; break;
        case 'bet_winnings'             : type = activity_types.WINNINGS; break;
        case 'bet_loses'                : type = activity_types.LOSSES; break;
        case 'create_bet'               : type = activity_types.CREATE_BET; break;
        case 'expedite_bet'             : type = activity_types.EXPEDITE_BET; break;
        case 'incredits_purchase'       : type = activity_types.PURCHASE_INCREDITS; break;
        case 'incredits_transfer'       : type = activity_types.INCREDITS_TRANSFER; break;
        case 'bet_expired'              : type = activity_types.EXPIRED_BET; break;
        default                         : type = activity_types.UNKNOWN;
    }

    // this activity relates to a user
    if (activity.user) { 
        user_name = activity.user[0].name;
        picture = activity.user[0].picture;
    };
    // this activity relates to a bet
    if (activity.bet) { 
        bet_title = activity.bet[0].title;
    };

    if (activity.ledger) {
        /*
            {"_id":"6452fe5175ea63302d7a0cea","debit":250,"credit":250,"debitUserID":"6420b9edc2daa6f4cde9927e","creditUserID":"61c202a12c981d126b25cd12","type":"TRANSFER","units":"INCREDITS","at":"2023-05-04T00:37:37.513Z"}
        */
        incredits = parseFloat(activity.ledger[0].debit).toFixed(2).toString();
    }

    if (type === activity_types.LIKE_BET) {
        if (activity.index === -1) { liked = true }
    }
    if (type === activity_types.OWNER_HOLD || activity_types.TAKER_HOLD) {
        amount = activity.amount;
    }
    if (type === activity_types.BET_SETTLEMENT) {
         
        // is this the owner of the bet?
         if (activity.bet[0].ownerID.toString() === activity.user[0]._id.toString()) {
            // did the owner vote for them themself?
            if (activity.bet[0].vote?.owner?.vote === activity.bet[0].ownerID) { 
                vote = activity.bet[0].owner.name;
            } else {
                vote = activity.bet[0].taker.name;
            }
        } else {
            // this is the taker fo the bet, did they vote for themselves?
            if (activity.bet[0].vote?.taker?.vote === activity.user[0]._id.toString()) { 
                vote = activity.bet[0].taker.name;
            } else {
                vote = activity.bet[0].owner.name;
            }
        }
    }
    if (type === activity_types.SERVICE_FEE) {
        fee = activity.fee;
    }
    if (type === activity_types.WINNINGS) {
        winnings = activity.winAmount;
    }
    if (type === activity_types.LOSSES) {
        loses = activity.loseAmount;
    }

    const getTableRowDetails = () => {
    
        // each activity row has the format of who, when and what
        switch (type) {

            case activity_types.GOOGLE_SIGNIN: 
                return (<Typography>Google Signin</Typography>);
            case activity_types.LOCAL_SIGNIN:
                return (<Typography>incredibets Signin</Typography>);
            case activity_types.CREATE_BET:
                return (<Typography>created bet: <em>{bet_title}</em></Typography>);
            case activity_types.LIKE_BET:
                return (<Typography>{liked ? 'liked' : 'unLiked'} bet: <em>{bet_title}</em></Typography>);
            case activity_types.OWNER_HOLD:
                return (<Typography><CreditCardIcon /><em>{amount}</em> reserved on bet: <em>{bet_title}</em> </Typography>);
            case activity_types.TAKER_HOLD:
                return (<Typography><em>${amount}</em> reserved on bet: <em>{bet_title}</em> </Typography>);  
            case activity_types.BET_SETTLEMENT:
                return (<Typography>votes for <em>{vote}</em> to win bet: <em>{bet_title}</em> </Typography>);  
            case activity_types.SERVICE_FEE:
                return (<Typography>pays service fee of <em>${fee}</em> on bet: <em>{bet_title}</em> </Typography>);  
            case activity_types.WINNINGS:
                return (<Typography>wins <em>${winnings}</em> on bet: <em>{bet_title}</em> </Typography>);
            case activity_types.LOSSES:
                return (<Typography>loses <em>${loses}</em> on bet: <em>{bet_title}</em> </Typography>);
            case activity_types.EXPEDITE_BET:
                return (<Typography>expedited bet: <em>{bet_title}</em> </Typography>);
            case activity_types.PURCHASE_INCREDITS:
                return (<Typography>purchased <em>${incredits}</em> incredits </Typography>);
            case activity_types.GOOGLE_ACCOUNT_CREATED:
                return (<Typography>user account created for <em>{activity.user[0].email}</em></Typography>);
            case activity_types.INCREDITS_TRANSFER:
                return (
                    <Stack direction="row" spacing={1} alignItems='center' justifyContent='flex-start'>
                        <CreditCardIcon fontSize='small' />
                        <Typography><em>{incredits}</em> incredits transfered</Typography>
                    </Stack>
                );
            case activity_types.EXPIRED_BET:
                return (<Typography><strong>bet expired:</strong> <em>{bet_title}</em> </Typography>);
            default:
                return `unmapped activity: ${activity.type}`;
        }

    }

    return (      
        <TableRow>  
            <TableCell>
                <Stack direction="row" spacing={1} alignItems='center' justifyContent='flex-start'>
                    <Avatar src={picture} alt={user_name}/>                    
                    <Typography>{user_name}</Typography>
                </Stack>
            </TableCell>
            <TableCell>
                <Typography>{getIBTimeAsString(activity.at)} on {getIBDateAsString(activity.at)}</Typography>
            </TableCell>
            <TableCell>{ getTableRowDetails() }</TableCell>
        </TableRow>       
    )
}

export default ActivityItemSummary;