import { FETCH_LEDGER_ENTRIES_BY_USER, START_LOADING_LEDGER_ENTRIES, END_LOADING_LEDGER_ENTRIES, LEDGER_ADD_CREDITS, LEDGER_DEPOSIT_FUNDS, LEDGER_HOLD_CREDITS, LOGOUT } from '../constants/actionTypes';

const INITIAL_STATE = {
    isLoadingEntries: true, 
    ledgerEntries: [],
    creditEntries: [],
    cashEntries: [],
    ledgerPage: 1,
    creditPage: 1,
    cashPage: 1,
    ledgerPages: 0,
    creditPages: 0,
    cashPages: 0,
    entriesPerPage: 10,
    ledgerCount: 0,
    creditCount: 0,
    cashCount: 0,
}

const ledgerReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    
    case LOGOUT:
      return { ...INITIAL_STATE };

    case LEDGER_DEPOSIT_FUNDS:
      //console.log(`current entries ${JSON.stringify(state.cashEntries[0])}`)
      //console.log(`ledger cash transaction ${JSON.stringify(action.results.ledger)}`);
      return { 
        ...state, 
        cashEntries: [action.results.ledger, ...state.cashEntries],
        cashCount: state.cashCount + 1,
        ledgerEntries: [action.results.ledger, ...state.ledgerEntries],
        ledgerCount: state.ledgerCount +1
      };

    case  LEDGER_HOLD_CREDITS:
      //console.log(`ledger credit transaction ${JSON.stringify(action.results.ledger)}`);
      return { 
        ...state, 
        creditEntries: [action.results.ledger, ...state.creditEntries],
        creditCount: state.creditCount + 1,
        ledgerEntries: [action.results.ledger, ...state.ledgerEntries],
        ledgerCount: state.ledgerCount +1
      };

    case LEDGER_ADD_CREDITS:
      //console.log(`ledger credit transaction ${JSON.stringify(action.results.ledger)}`);
      return { 
        ...state, 
        creditEntries: [action.results.ledger, ...state.creditEntries],
        creditCount: state.creditCount + 1,
        ledgerEntries: [action.results.ledger, ...state.ledgerEntries],
        ledgerCount: state.ledgerCount +1
      };

    case START_LOADING_LEDGER_ENTRIES:
      return { ...state, isLoadingEntries: true };
    
    case END_LOADING_LEDGER_ENTRIES:
      return { ...state, isLoadingEntries: false};
    
    case FETCH_LEDGER_ENTRIES_BY_USER:
      //console.log(`fetch entries for a given user ${JSON.stringify(action.results)}`);

      if (String(action.results.units) === "USD") {
        //console.log(`fetch CASH entries for a given user`);        
        return {
          ...state,
          cashEntries: action.results.entries,
          cashPage: Number(action.results.currentPage),
          cashPages: Number(action.results.numberOfPages),
          cashCount: Number(action.results.numberOfEntries)
        };
      } else if (String(action.results.units) === "INCREDITS") {
        //console.log(action.results.numberOfEntries);
        return {
          ...state,
          creditEntries: action.results.entries,
          creditPage: Number(action.results.currentPage),
          creditPages: Number(action.results.numberOfPages),
          creditCount: Number(action.results.numberOfEntries)
        };
      } else {
        return {
          ...state,
          ledgerEntries: action.results.entries,
          ledgerPage: Number(action.results.currentPage),
          ledgerPages: Number(action.results.numberOfPages),
          ledgerCount: Number(action.results.numberOfEntries)
        }
      }
  
    default:
      return { ...state };
      
  }

};

export default ledgerReducer;