import { useState } from 'react';
import { Grid, CircularProgress, Typography, Paper, Stack, Alert, AlertTitle } from "@mui/material";
import User from './user/user';
import { useSelector } from 'react-redux';
import { isUserAuthenticated, getUserFromProfile} from '../../utils/utils';

const Users = () => {
  
    const [currentuser] = useState(getUserFromProfile());
    const { users, isLoading } = useSelector((state) => state.users);
    const { isError, errorTitle, errorDetail } = useSelector((state) => state.bets);
    const authenticated = isUserAuthenticated();

    // if we are not loading and we don't have any users...
    if (!users?.length && !isLoading) return (
      <Paper sx={{ m: 0, mb: 2,  p: 2}} elevation={0}>
        <Typography variant="h6">no users found...</Typography>
      </Paper>
    );

    // if there is an error returned from the system
    if (isError) return ( 
      <Alert variant="filled" severity="error" sx={{ m: 1 }}>
        <AlertTitle>{errorTitle}</AlertTitle>
        <Typography>{JSON.stringify(errorDetail).replaceAll('"','')}</Typography>           
      </Alert>
    );

    // if we are waiting for the system to return the list of users
    if (isLoading) return  (
      <Stack sx={{ mt: 3 }} spacing={2} direction="column" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );

    // return the collection of users
    return (            
      <Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-start" justifyContent="center" sx={{ p: 1 }}>
        {users.map((user) => (
          <Grid item xs={12} sm={6} md={5} lg={4} xl={3} key={user._id}>
            <User user={user} currentuser={currentuser} authenticated={authenticated} />
          </Grid>
        ))}
      </Grid>      
    );

  };

export default Users;