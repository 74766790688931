import { FETCH_OPEN_BETS_BY_USER, START_LOADING_OPEN, END_LOADING_OPEN, DELETE_BET, LIKE_BET, LOGOUT } from '../constants/actionTypes';

const INITIAL_STATE = {
  isLoadingOpen: true, 
  open: [],
  openCount: 0, 
  openPage: 1,
  openPages: 1,
  openPerPage: 6 
}

const openReducer = (state = INITIAL_STATE, action) => {
    
  switch (action.type) {
    
    case LOGOUT:
      return { ...INITIAL_STATE };

    case START_LOADING_OPEN:
      return { ...state, isLoadingOpen: true };
    
    case END_LOADING_OPEN:
      return { ...state, isLoadingOpen: false};
    
    case FETCH_OPEN_BETS_BY_USER:
      return {
        ...state, 
        open: action.results.bets,
        openCount: Number(action.results.numberOfBets),
        openPage: Number(action.results.currentPage),
        openPages: Number(action.results.numberOfPages),
        openPerPage: Number(action.results.bets_per_page)
      };

    case DELETE_BET:
      if (state.open.some((bet) => bet._id === action.results.betID)) {
        //console.log('bet found in favorites array, removing it');
        return {
          ...state, 
          open: state.open.filter((bet) => bet._id !== action.results.betID),
          openCount: state.openCount - 1,
          openPages: (state.betCount - 1)/state.betsPerPage
        };
      } else {
        return { ...state };
      };

    case LIKE_BET:
      return { 
        ...state, 
        open: state.open.map((bet) => (bet._id === action.results.bet._id ? action.results.bet : bet)) 
      };

    default:
      return state;
  }
};

export default openReducer;