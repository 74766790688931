import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { BsFillPersonFill } from 'react-icons/bs';
import PaidIcon from '@mui/icons-material/Paid';
import { 
    Button, Typography, AppBar, Toolbar, IconButton, 
    Stack, Divider, Box, Menu, MenuItem, ListItemIcon, useMediaQuery,
    ListItemText, Tooltip, Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';
import logo from '../../images/bling.png';
import * as actionType from '../../constants/actionTypes';
import AddBoxIcon from '@mui/icons-material/AddBox';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Logout from '@mui/icons-material/Logout';
import { useTheme } from '@emotion/react';
import MenuIcon from '@mui/icons-material/Menu';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BadgeIcon from '@mui/icons-material/Badge';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { getParticipatingBetsByUser } from '../../actions/bets';
import WalletSummaryTable from '../wallet/wallet_summary_table';
import IBAvatar from '../users/avatar';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { getWalletAmount } from '../../actions/wallet';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GavelIcon from '@mui/icons-material/Gavel';
import { getUserFromProfile, isUserAuthenticated } from '../../utils/utils';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const NavBar = () => {

    const [user, setUser] = useState();
    const { isLoadingWallet, amount } = useSelector((state) => state.wallet);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    let dispatchedRef = useRef(false);
    const { participating, isLoadingParticipating, participatingCount } = useSelector(state => state.participating);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [numberHolder, setnumberHolder] = useState(1);

    function generateRandomNumber() {
        var randomNumber = Math.floor(Math.random() * 100) + 1;
        setnumberHolder(randomNumber);
    }

    const logout = () => {
        handleClose();
        dispatch({ type: actionType.LOGOUT });
        navigate('/login', {replace: true});
        setUser(null);
      };

    
    useEffect(() => {
        
        const _logout = () => {
            console.log('token expired...');
            dispatch({ type: actionType.LOGOUT });
            navigate('/login', {replace: true});
            setUser(null);
          };

        if (!isUserAuthenticated) { _logout() };

        // we don't have a user yet
        if (!user) {
            // force refereh the user obj to get new wallet amounts
            const profile_user = getUserFromProfile();
            if (profile_user) {
                //console.log(`Navbar found a user ${profile_user.firstname}`);
                if (isUserAuthenticated) {
                    //console.log(`Navbar validated user: ${JSON.stringify(profile_user.wallet.total)}`);                    
                    setUser(profile_user); 
                    dispatch(getWalletAmount(profile_user._id));
                } else _logout();
            }
        }
        
        const dispatched = dispatchedRef.current;
        if (!dispatched && user) {
            //console.log('DISPATCHING');
            dispatchedRef.current = true;
            
            dispatch(getParticipatingBetsByUser(user._id));
            
            //console.log(`requesting wallet amount for user ${user.firstname}`)
            dispatch(getWalletAmount(user._id));
            
            // get a random number for the header tag line rotation
            generateRandomNumber();
        } 

      }, [location, dispatch, navigate, user] );

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [anchorActionMenu, setAnchorActionMenu] = React.useState(null);
    const openActionMenu = Boolean(anchorActionMenu);
    const handleActionMenuClick = (event) => {
        setAnchorActionMenu(event.currentTarget);
    };
    const handleActionMenuClose = () => {
        setAnchorActionMenu(null);
    };

    return (
        <header>
            <AppBar>
                <Toolbar>

                    <IconButton 
                        component={Link} to="/"
                        edge="start"
                        position="sticky"
                    >
                        <img src={logo} width="25" height="25" className={theme.logo} alt="logo" />
                    </IconButton>

                    { !isSmallScreen ? (
                        <Button 
                            variant="text" 
                            component={Link} to="/"
                            color="inherit"
                            sx={{ textTransform: "lowercase" }}
                        >
                            <Stack direction="column" sx={{ mr:2 }} alignItems="center" spacing={-0.5}>
                                <Stack direction="row" spacing={1}>
                                    <Typography variant='h5'>incredibets</Typography>
                                    <Typography variant='h5' sx={{ color: 'red' }}>&gt;</Typography>
                                </Stack>
                                {numberHolder % 2 === 0 ?
                                    <Typography variant="caption" sx={{ color: 'red' }}>be the house</Typography> :
                                    <Typography variant="caption" sx={{ color: 'red' }}>it's your house</Typography>
                                }
                            </Stack>
                        </Button>
                    ) : (
                        <Stack direction="row" spacing={0.5}>
                            <Typography variant="caption" sx={{ color: "white" }} >incredibets</Typography>
                            <Typography variant='caption' sx={{ color: 'red' }}>&gt;</Typography>
                        </Stack>
                    )}
                    
                    { isUserAuthenticated() ? (
                        <>
                            { isSmallScreen ? (
                                <>
                                <IconButton
                                    onClick={handleActionMenuClick}
                                    aria-controls={openActionMenu ? 'action-menu' : undefined }
                                    aria-haspopup="true"
                                    aria-expanded={openActionMenu ? 'true' : undefined}
                                    sx={{ color: "white" }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Box sx={{ flexGrow: 1 }} />
                                </>
                            ) : (
                                <>
                                <Button 
                                    color="inherit" 
                                    component={Link} to="/" 
                                    sx={{ textTransform: "lowercase" }}
                                    startIcon={<StorefrontIcon />}
                                >Marketplace
                                </Button>
                                <Button 
                                    color="inherit" 
                                    component={Link} to="/create-bet" 
                                    sx={{ textTransform: "lowercase" }}
                                    startIcon={<AddBoxIcon />}
                                >New bet
                                </Button>

                                <Button 
                                    color="inherit" 
                                    component={Link} to="/mybets" 
                                    sx={{ textTransform: "lowercase"}}
                                    startIcon={<MonetizationOnIcon />}
                                >My bets
                                </Button>

                                { user?.admin && (
                                    <Button 
                                    color="inherit" 
                                    component={Link} to="/arbitrate" 
                                    sx={{ textTransform: "lowercase"}}
                                    startIcon={<GavelIcon />}
                                    >Arbitration
                                    </Button>
                                    )
                                }

                                <Box sx={{ flexGrow: 1 }} />
                                </>

                                

                            )}
                            
                            { isSmallScreen ? (
                                <>
                                <Box component="span" borderRadius="5px" minWidth={80} sx={{ p: .5, backgroundColor: 'success.main', border: '2px white' }} >
                                <Stack spacing={1} alignItems="center" direction="row">
                                    <AccountBalanceWalletIcon />
                                    {isLoadingWallet ? "$..." :
                                    <Typography sx={{ color: 'common.white'}} variant="caption">${parseFloat(amount).toFixed(2)}</Typography>}               
                                </Stack>
                                </Box>
                                </>
                            ) : (
                                <Stack direction="row" sx={{ mr:2 }} spacing={1} alignItems="center">
                                    <Typography variant="caption">{user?.firstname}'s Wallet:</Typography>
                                    <Box component="span" borderRadius="5px" minWidth={80} sx={{ p: .5, backgroundColor: 'success.main', border: '2px white' }} >
                                        <CustomWidthTooltip
                                            arrow
                                            title={<WalletSummaryTable user={user} bets={participating} count={participatingCount} isLoading={isLoadingParticipating} />}
                                        >
                                            <Stack spacing={1} alignItems="center" direction="row">
                                                <AccountBalanceWalletIcon />
                                                {isLoadingWallet ? "$..." :
                                                <Typography sx={{ color: 'common.white'}}>${parseFloat(amount).toFixed(2)}</Typography>}             
                                            </Stack>
                                        </CustomWidthTooltip>
                                    </Box>
                                </Stack>
                            )}

                            <IconButton
                                onClick={handleClick}
                                size="small"
                                aria-controls={open ? 'account-menu' : undefined }
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <IBAvatar picture={user?.picture} name={user?.name} />
                            </IconButton>
                        </>
                    ) : (
                        <>
                            <Typography sx={{ flex:1 }}></Typography>
                            <Button 
                                color="inherit" 
                                startIcon={<BsFillPersonFill />} 
                                component={Link} to="/login"
                                edge="end"
                            >Login</Button>
                        </>
                    )}

                </Toolbar>
                
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                            },
                            '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                            },
                            minWidth: 160,
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem component={Link} to={`/user/${user?._id}`}>
                        <IBAvatar picture={user?.picture} name={user?.name} />
                        {user?.firstname}'s Profile
                    </MenuItem>
                    
                    { /* ------- START ADMIN MENU -------*/ }
                    { user?.admin && (
                    [                   
                        <Accordion sx={{ width: '100%' }} key="menu.admin">
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} key="menu.admin.summary" sx={{ backgroundColor: "lightgray"}}>
                                <Stack direction="row" spacing={1} key="menu.admin.header">
                                    <AdminPanelSettingsIcon color="action" key="menu.admin.icon" />
                                    <Typography key="menu.admin.text">Admin Only</Typography>                            
                                </Stack>
                            </AccordionSummary>

                            <AccordionDetails sx={{ backgroundColor: "lightgray"}} key="menu.admin.details">
                                <MenuItem key="menu.admin.useradmin" component={Link} to="/users">
                                    <ListItemIcon key="menu.admin.useradmin.icon">
                                        <BadgeIcon fontSize="small" key="menu.admin.useradmin.icon.badge" />
                                    </ListItemIcon>
                                    <Typography key="menu.admin.useradmin.text">User (Admin)</Typography>
                                </MenuItem>
                                <MenuItem key="menu.admin.finanical" component={Link} to="/business">
                                    <ListItemIcon key="menu.admin.finanical.icon">
                                        <PaidIcon fontSize="small" key="menu.admin.useradmin.icon.paid" />
                                    </ListItemIcon>
                                    <Typography key="menu.admin.finanical.text">Financial (Admin)</Typography>
                                </MenuItem>
                            </AccordionDetails>
                        </Accordion>
                    ]
                    )}
                    { /* ------- END ADMIN MENU -------*/ }

                    <Divider />
                
                    <MenuItem onClick={logout}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>
                <Menu
                    anchorEl={anchorActionMenu}
                    id="action-menu"
                    open={openActionMenu}
                    onClose={handleActionMenuClose}
                    onClick={handleActionMenuClose}
                >
                    <MenuItem component={Link} to="/" sx={{ width: 150 }} >
                        <StorefrontIcon key="sficon">
                            <AddBoxIcon fontSize="small" />
                        </StorefrontIcon>
                        <ListItemText sx={{ ml: 1 }}>marketplace</ListItemText>
                    </MenuItem>
                    <MenuItem component={Link} to="/create-bet" >
                        <ListItemIcon key="abicon">
                            <AddBoxIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>new bet</ListItemText>
                    </MenuItem>
                    <MenuItem component={Link} to="/mybets">
                        <ListItemIcon key="micon">
                            <MonetizationOnIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>my bets</ListItemText>
                    </MenuItem>
                    { user?.admin && (
                        <MenuItem component={Link} to="/arbitrate">
                             <ListItemIcon key="gicon">
                                <GavelIcon fontSize='small' />
                            </ListItemIcon>
                             <ListItemText>arbitration</ListItemText>
                        </MenuItem>                    
                        )
                    }
                </Menu>
                    
            </AppBar>
        </header>
    )
}

export default NavBar