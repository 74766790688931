import { useState } from 'react';
import { useDispatch } from 'react-redux';
import logo from '../../images/logo2.png';
import background from '../../images/background.png';
import { Avatar, Button, Paper, Grid, Typography, Checkbox, FormControlLabel, Divider, Box, Link, TextField, CssBaseline, Container, Chip, Stack, InputAdornment, IconButton, OutlinedInput, FormControl, InputLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { signin, signup, googleProfile } from '../../actions/auth';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { GoogleOAuthProvider } from '@moeindana/google-oauth';
import { GoogleLogin } from '@moeindana/google-oauth';

const initialFormState = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '' };

const Login = props => {

  const [formData, setFormData] = useState(initialFormState);
  const [isSignup, setIsSignup] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const switchMode = () => {
    setFormData(initialFormState);
    setIsSignup((prevIsSignup) => !prevIsSignup);
    setShowPassword(false);
  };

  const handleSubmit = event => {
    event.preventDefault();
    //console.log('handle submit');
    if (isSignup) {
      dispatch(signup(formData, navigate));
    } else {
      dispatch(signin(formData, navigate));
    }
  };

  const googleSuccess = async (response) => {
    //console.log(`google success ${JSON.stringify(response)}`);
    //console.log(`google success ${JSON.stringify(response.email)}`);
    try {
      dispatch(googleProfile(response, navigate));
    } catch (error) {
      console.log(`fetching user from mongo: ${error}`);
    }
  };

  const googleError = (response) => {
    alert(`Google Sign In was unsuccessful. \n\nERROR: ${response.error} \nDETAILS: ${response.details} \n\nPlease try again later`);
    console.log(`google error: ${response.details}`);
  }
  
  const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <GoogleOAuthProvider clientId="286987918344-oug7i6328o6ogk2ie3dpkksiq3sgihb6.apps.googleusercontent.com">
      <Box sx={{ mt: 5 }}>
        <Container component="main">
          <CssBaseline />
          <Paper elevation={3}>

          <Grid container component="main">
          {/* image side of the grid */}
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: `url(${background})`,
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <img src={logo} className="logo" alt="incredibets company banner" />
          </Grid>

          {/* login side of the grid */}    
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >           
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>

            <Typography component="h1" variant="h5">
              { isSignup ? 'sign up for incredibets' : 'sign in to incredibets' }
            </Typography>

            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
                { isSignup && (
                <>
                  <Stack direction="row" spacing={1}>
                    <TextField fullWidth name="firstName" label="First Name" onChange={handleChange} autoFocus />
                    <TextField fullWidth name="lastName" label="Last Name" onChange={handleChange} />
                  </Stack>
                </>
                )}

                <Stack direction="column" spacing={1} sx={{ mt: 1 }}>

                  <TextField fullWidth label="Email Address" name="email" autoComplete="email" autoFocus onChange={handleChange} key="Confirmation Code"/>
                  
                  <FormControl fullWidth sx={{ mt: 1 }} variant="outlined">
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput 
                      id="password" 
                      name="password" 
                      label="Password" 
                      autoComplete="current-password" 
                      onChange={handleChange} 
                      type={showPassword ? "text" : "password"} 
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      } />
                  </FormControl>
                  
                    { isSignup && ( 
                      <FormControl fullWidth sx={{ mt: 2 }} variant="outlined">
                        <InputLabel htmlFor="rpassword">Repeat Password</InputLabel>
                        <OutlinedInput 
                          id="rpassword"
                          fullWidth 
                          name="confirmPassword" 
                          label="Repeat Password" 
                          onChange={handleChange} 
                          type={showPassword ? "text" : "password"} 
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleShowPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                    </FormControl>
                    )}
                                  
                  <FormControlLabel control={<Checkbox />} label="remember me" />
                        
                </Stack>

                <Button type="submit" fullWidth variant="contained" color="primary">
                { isSignup ? 'Sign Up' : 'Sign In' }
                </Button>
                      
                <Divider sx={{m: 2, mt:3}}>
                  <Chip label="or" variant="outlined" />
                </Divider>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >           
                  <GoogleLogin
                    onSuccess={googleSuccess}
                    onError={googleError}
                  />
                </Box>

                <Grid container sx={{ mt:4 }} spacing={2} >
                  <Grid item xs>
                    <Link>
                      forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link onClick={switchMode}>
                      { isSignup ? 'Already have an account? Sign in' : "don't have an account? sign up!" }
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          </Grid>
          </Paper>
        </Container>
      </Box>
    </GoogleOAuthProvider>
  );
};

export default Login;