import { 
    IconButton,
    TableCell, TableRow, Typography
} from '@mui/material';
import { truncate, getIBDateAsString, getIBTimeAsString } from '../../utils/utils';
import { WON, LOST, PARTICIPATING } from './wallet_summary_types';
import { Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';

const WalletSummaryRow = ({ user, bet, myKey, type, isSmall, maxTitleLength = 40 }) => {

    /*----- not all bets are the owner's bets so make sure to show where they have taken other bets ------*/
    const betCommitment = () => {
        var amount = bet.wallet?.ownerStake;     
        if (bet.taker?.id === user?._id) {
            //console.log(`bet commitment on ${bet.title} taker:${bet.taker?.id} current user:${user?._id}`);   
            amount = bet.wallet?.takerStake;
        }     
        return amount;
    }

    const betLost = () => {
        var amount = '0.00';

        if (bet.winner?.id === bet.owner?.id) { amount = bet.wallet?.ownerStake}
        else amount = bet.wallet?.takerStake;

        return amount;
    }

    const betDate = () => {
        switch (type) {
            case PARTICIPATING:
                return (getIBDateAsString(bet.livedate, isSmall) + ' ' + getIBTimeAsString(bet.livedate, isSmall));
            default:
                return (getIBDateAsString(bet.enddate, isSmall) + ' ' + getIBTimeAsString(bet.enddate, isSmall));
        }
        
    }

    const betAmount = () => {
        let commitment = betCommitment();
        let total = parseFloat(parseFloat(bet.wallet?.ownerStake) + parseFloat(bet.wallet?.takerStake)).toFixed(2);
        let lost = betLost();

        switch(type) {
            case PARTICIPATING:
                return commitment;
            case WON:
                return total;
            case LOST:
                return lost;
            default:
                return '0.00';
        }
    }

    return (
        <TableRow key={"row_" + myKey} >
            <TableCell key={"cell1_" + myKey}>
                <Typography variant={isSmall ? 'caption' : 'body1'} >{truncate(bet.title, maxTitleLength)}</Typography>
            </TableCell>
            <TableCell key={"cell2_" + myKey}>
                <Typography variant={isSmall ? 'caption' : 'body1'}>{betDate()}</Typography>
            </TableCell>
            <TableCell key={"cell3_" + myKey} align='center'>
                <Typography variant={isSmall ? 'caption' : 'body1'}>{bet.odds_receiving + ":" + bet.odds_staked}</Typography>
            </TableCell>
            <TableCell align="right" key={"cell4_" + myKey}  >
                <Typography variant={isSmall ? 'caption' : 'body1'}>${betAmount()}</Typography>               
            </TableCell>
            <TableCell align='center' key={"cell5_" + myKey}><IconButton component={Link} to={`/bet/${bet._id}`}><InfoIcon /></IconButton></TableCell>
        </TableRow>
    )
}

export default WalletSummaryRow;