import * as actions from '../constants/actionTypes';
import { getApp } from '../utils/utils';

//
// create a new bet
//
export const createbet = (bet, router) => async (dispatch) => {
    
    //console.log(`ACTION: createBet ${JSON.stringify(bet)}`);

    function combineDateWithTime(d, t)
    {
        const combined = new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            t.getHours(),
            t.getMinutes(),
            t.getSeconds(),
            t.getMilliseconds()
            );
        //console.log(combined);
        return combined
    }
    
    try {

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('createbet authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        };

        // check to see if a custom live date was provided
        if (bet.isNotDefaultLiveDateTime) {
            // fix up the form data before ssending the bet in
            bet.bet_livedate = combineDateWithTime(bet.bet_livedate, bet.bet_livetime).toISOString();
        } else {
            bet.bet_livedate = combineDateWithTime(new Date(bet.default_betlivedate), new Date(bet.default_betlivedate)).toISOString();
        }
        bet.bet_enddate = combineDateWithTime(bet.bet_enddate, bet.bet_endtime).toISOString();

        //console.log(`createbet action for owner ${bet.owner_id}`);
        
        let request = {
            title : bet.title, 
            description: bet.description, 
            bet_amount : bet.bet_amount, 
            odds_receiving: bet.odds_receiving, 
            odds_staked: bet.odds_staked, 
            bet_livedate: bet.bet_livedate, 
            bet_enddate: bet.bet_enddate, 
            owner_id: bet.owner_id
        }
        const results  = JSON.parse(await window.incredibets.realmUser.functions.createBet(JSON.stringify(request))).body;
        //console.log(`results: ${JSON.stringify(results)}`);
        
        if (results.error !== undefined) {
            throw results;
        }
        dispatch({ type: actions.CREATE_BET, results });
        router('/', { replace: true });

    } catch (error) {
        console.log(`actions.bets.createbet: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error });
    }
};

//
// throw this vote into your favorite's list
// 
export const likeBet = (betid, userid) => async (dispatch) => {

    try {

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('likeBet authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        }

        let request = {
            betid: betid,
            userid: userid
        }
        console.log(`likeBet action ${JSON.stringify(request)}`);
        const results = JSON.parse(await window.incredibets.realmUser.functions.likeBet(JSON.stringify(request))).body;
        if (results.error !== undefined) {
            throw results;
        }
        
        dispatch({ type: actions.LIKE_BET, results });

    } catch (error) {
        console.log(`actions.bets.likeBet: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
    }

};


//
// throw this vote into your favorite's list
// 
export const setBetArbitrationText = (betid, userid, arbitrationtext) => async (dispatch) => {

    try {

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('likeBet authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        }

        let request = {
            betid: betid,
            userid: userid,
            text: arbitrationtext
        }
        //console.log(`setBetArbitrationText action ${JSON.stringify(request)}`);
        const results = JSON.parse(await window.incredibets.realmUser.functions.arbitrationText(JSON.stringify(request))).body;
        if (results.error !== undefined) {
            throw results;
        }
        
        dispatch({ type: actions.SET_ARBITRATION_TEXT, results });

    } catch (error) {
        console.log(`actions.bets.setBetArbitrationText: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
    }

};

//
// expedite the resolution date for this bet
// 
export const expediteBet = (betid, userid, router) => async (dispatch) => {

    try {

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('expediteBet authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        }

        let request = {
            betid: betid,
            userid: userid
        }
        const results = JSON.parse(await window.incredibets.realmUser.functions.expediteBet(JSON.stringify(request))).body;
        if (results.error !== undefined) {
            throw results;
        }
        
        dispatch({ type: actions.EXPEDITE_BET, results });
        router('/mybets', {replace: true});

    } catch (error) {
        console.log(`actions.bets.expediteBet: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
    }

};

//
// put your money down on a bet, good luck sailor!
// { "betid": bet_id, "userid": user_id }
export const takeBet = (betid, userid, router) => async (dispatch) => {

    try {

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('likeBet authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        }

        let request = {
            betid: betid,
            userid: userid
        }
        const results = JSON.parse(await window.incredibets.realmUser.functions.takeBet(JSON.stringify(request))).body;
        if (results.error !== undefined) {
            throw results;
        }

        dispatch({ type: actions.TAKE_BET, results });

        router('/', {replace: true});

    } catch (error) {
        console.log(`actions.bets.takeBet: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
    }

};

//
//  cast your vote on a bet waggered on
//
export const voteBet = (betid, userid, winuserid, router) => async (dispatch) => {
    
    try {
    
        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('deleteBet authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        }

        let request = {
            betid: betid,
            userid: userid,
            winuserid: winuserid
        }
        const results = JSON.parse(await window.incredibets.realmUser.functions.voteBet(JSON.stringify(request))).body;
        if (results.error !== undefined) {
            throw results;
        }
        
        //console.log(`api results ${JSON.stringify(results)}`);
        dispatch({ type: actions.VOTE_BET, results });

        // if the result is settled or need arbitration then what?
        //console.log(`navigate obj: ${router}`)

        // route the user based on the results of the vote
        if (results.bet.status === "concluded") {
            if (userid === winuserid) { router('/mybets?tab=win', { replace: true }); } else { router('/mybets?tab=lose', { replace: true }); }
        } else if (results.bet.status === "pending") {
            router('/mybets?tab=pending', { replace: true });
        } else {
            router('/mybets', { replace: true });
        }

    } catch (error) {
        console.log(`actions.bets.voteBet: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
    }
};

//
//  arbitrate the results of a bet
//
export const arbitrateBet = (betid, userid, winuserid, loseuserid, router) => async (dispatch) => {
    //console.log(`firing arbitrate bet: ${betid}`);
    try {
    
        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('arbitrateBet authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        }

        let request = {
            betid: betid,
            userid: userid,
            winuserid: winuserid,
            loseuserid: loseuserid
        }
        //console.log(`arbitrateBet: ${JSON.stringify(request)}`);

        const results = JSON.parse(await window.incredibets.realmUser.functions.arbitrateBet(JSON.stringify(request))).body;
        if (results.error !== undefined) {
            throw results;
        }
        
        //console.log(`api results ${JSON.stringify(results)}`);
        dispatch({ type: actions.ARBITRATE_BET, results });

        router('/arbitrate', { replace: true });

    } catch (error) {
        console.log(`actions.bets.arbitrateBet: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
    }
};

//
//  appeal the results of a bet
//
export const appealBet = (betid, userid, router) => async (dispatch) => {
    
    try {
    
        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('appealBet authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        }

        let request = {
            betid: betid,
            userid: userid
        }
        const results = JSON.parse(await window.incredibets.realmUser.functions.appealBet(JSON.stringify(request))).body;
        if (results.error !== undefined) {
            throw results;
        }
        
        //console.log(`api results ${JSON.stringify(results)}`);
        dispatch({ type: actions.APPEAL_BET, results });

        // if the result is settled or need arbitration then what?
        //console.log(`navigate obj: ${router}`)

        router('/', { replace: true });

    } catch (error) {
        console.log(`actions.bets.appealBet: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
    }
};

//
// delete the bet with the provided ID
//
export const deleteBet = (betid, userid) => async (dispatch) => {

    try {

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('deleteBet authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        }

        let request = {
            betid: betid,
            userid: userid
        }
        console.log(`deleteBet request: ${JSON.stringify(request)}`);
        const results = JSON.parse(await window.incredibets.realmUser.functions.deleteBet(JSON.stringify(request))).body;
        //console.log(`deleteBet results: ${JSON.stringify(results)}`);

        if (results.error !== undefined) {
            throw results;
        }

        //await api.deleteBet(id);
        dispatch({ type: actions.DELETE_BET, results });

    } catch (error) {
        console.log(`actions.bets.deleteBet: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
    }
};

//
// getBet - return a single bet based off a unique bet ID
//
export const getBet = (betid) => async (dispatch) => {
    //console.log(`ACTION: getBet ${betid}`);
    try {

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('getBet authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        }

        dispatch({ type: actions.START_LOADING });

        let request = {
            betid: betid
        }
        const results  = JSON.parse(await window.incredibets.realmUser.functions.fetchBets(JSON.stringify(request))).body;

        if (results.error !== undefined) {
            throw results;
        }
        dispatch({ type: actions.FETCH_BET, results });
        dispatch({ type: actions.END_LOADING });

    } catch (error) {
        console.log(`actions.bets.getBet: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error });
        dispatch({ type: actions.END_LOADING });
    }
}

export const getBets = (page = 1, betsPerPage = 6) => async (dispatch) => {
    //console.log(`ACTION: getBets`);
    try {

        dispatch({ type: actions.START_LOADING });

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('getBets null realmUser')
            window.incredibets.realmUser = await getApp();
        };

        let request = {
            page: page,
            betsPerPage: betsPerPage
        };
        const results  = JSON.parse(await window.incredibets.realmUser.functions.fetchBets(JSON.stringify(request))).body;
        //console.log(`ACTION: getBets RETURNED bets: ${JSON.stringify(bets)} for page ${currentPage} of ${numberOfPages}`);

        if (results.error !== undefined) {
            throw results;
        }
        dispatch({ type: actions.FETCH_ALL_BETS, results });
        dispatch({ type: actions.END_LOADING });

    } catch (error) {
        console.log(`actions.bets.getBets: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
        dispatch({ type: actions.END_LOADING });
    }
}

//
// return all of the open bets in the system (paginated)
//
export const getOpenBets = (page = 1, betsPerPage = 6) => async (dispatch) => {
    
    try {

        dispatch({ type: actions.START_LOADING });
        //console.log('get open bets');
        // we don't have an authenticated user yet
        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('getOpenBets authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        };
        
        let request = {
            page: page,
            open: true,
            betsPerPage: betsPerPage
        };
        const results  = JSON.parse(await window.incredibets.realmUser.functions.fetchBets(JSON.stringify(request))).body;
        //console.log(`RESULTS: ${JSON.stringify(results)}`);

        if (results.error !== undefined) {
            throw results;
        }
        dispatch({ type: actions.FETCH_OPEN_BETS, results });
        dispatch({ type: actions.END_LOADING });

    } catch (error) {
        console.log(`actions.bets.getOpenBets: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
        dispatch({ type: actions.END_LOADING });
    }
}

//
// return all of the taken bets in the system (paginated)
//
export const getTakenBetsByUser = (userid, page = 1, betsPerPage = 6) => async (dispatch) => {
    
    try {

        dispatch({ type: actions.START_LOADING_TAKEN });
        //console.log('get taken bets');
        // we don't have an authenticated user yet
        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('getTakenBetsByUser authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        };
        
        let request = {
            page: page,
            taken: true,
            userid: userid,
            betsPerPage: betsPerPage
        };
        const results  = JSON.parse(await window.incredibets.realmUser.functions.fetchBets(JSON.stringify(request))).body;
        //console.log(`RESULTS: ${JSON.stringify(results)}`);

        if (results.error !== undefined) {
            throw results;
        }
        dispatch({ type: actions.FETCH_TAKEN_BETS_BY_USER, results });
        dispatch({ type: actions.END_LOADING_TAKEN });

    } catch (error) {
        console.log(`actions.bets.getTakenBetsByUser: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
        dispatch({ type: actions.END_LOADING_TAKEN });
    }
}

//
// return bets for the current user that are still open
//
export const getOpenBetsByUser = (userid, page = 1, betsPerPage = 6) => async (dispatch) => {

    try {
        dispatch({ type: actions.START_LOADING_OPEN });

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('getOpenBetsByUser authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        };

        let request = {
            page: page,
            open: true,
            userid: userid,
            betsPerPage: betsPerPage
        };
        const results  = JSON.parse(await window.incredibets.realmUser.functions.fetchBets(JSON.stringify(request))).body;
        
        if (results.error !== undefined) {
            throw results;
        }
        dispatch({ type: actions.FETCH_OPEN_BETS_BY_USER, results });
        dispatch({ type: actions.END_LOADING_OPEN });

    } catch (error) {
        console.log(`actions.bets.getOpenBetsByUser: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
        dispatch({ type: actions.END_LOADING_OPEN });
    }
}

export const getBetsByUser = (userid, page = 1, betsPerPage = 6) => async (dispatch) => {
    
    try {
        dispatch({ type: actions.START_LOADING });

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('getBetsByOwner authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        };

        let request = {
            page: page,
            userid: userid,
            betsPerPage: betsPerPage
        };
        const results  = JSON.parse(await window.incredibets.realmUser.functions.fetchBets(JSON.stringify(request))).body;
        
        if (results.error !== undefined) {
            throw results;
        }
        dispatch({ type: actions.FETCH_BET_BY_OWNER, results });
        dispatch({ type: actions.END_LOADING });

    } catch (error) {
        console.log(`actions.bets.getBetsByOwner: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
        dispatch({ type: actions.END_LOADING });
    }
  };

export const getBetsBySearch = (searchquery, page = 1, betsPerPage = 6) => async (dispatch) => {
    
    try {
    
        dispatch({ type: actions.START_LOADING });

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('getBetsBySearch authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        };

        let request = {
            page: page,
            search_text: searchquery,
            betsPerPage: betsPerPage
        };
        const results  = JSON.parse(await window.incredibets.realmUser.functions.fetchBets(JSON.stringify(request))).body;
        //console.log(`API search successful: ${JSON.stringify(data.bets)}`);

        if (results.error !== undefined) {
            throw results;
        }
        dispatch({ type: actions.FETCH_BET_BY_SEARCH, results });
        dispatch({ type: actions.END_LOADING });

    } catch (error) {
        console.log(`actions.bets.getBetsBySearch: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
        dispatch({ type: actions.END_LOADING });
    }
}

//
// get the user's favorite bets
//
export const getFavoriteBetsByUser = (userid, page = 1) => async (dispatch) => {
    
    try {

        dispatch({ type: actions.START_LOADING_FAVS });

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('getFavoriteBetsByUser authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        }
        let request = {
            page: page,
            favorites: true,
            userid: userid
        }
        const results = JSON.parse(await window.incredibets.realmUser.functions.fetchBets(JSON.stringify(request))).body;
        
        if (results.error !== undefined) {
            throw results;
        }
        dispatch({ type: actions.FETCH_FAVORITE_BETS, results });
        dispatch({ type: actions.END_LOADING_FAVS });

    } catch (error) {
        console.log(`actions.bets.getFavoriteBetsByUser: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
        dispatch({ type: actions.END_LOADING_FAVS });
    }
}

//
// all of the bets that the user is participating in 
//
export const getParticipatingBetsByUser = (userid, page = 1) => async (dispatch) => {
    
    try {
        
        dispatch({ type: actions.START_LOADING_PART });

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('getParticipatingBetsByUser authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        }

        let request = {
            page: page,
            participating: true,
            userid: userid
        };
        ///console.log(`fetching participating bets for userid: ${userid}`);
        const results = JSON.parse(await window.incredibets.realmUser.functions.fetchBets(JSON.stringify(request))).body;
        //console.log(`RESULTS: ${JSON.stringify(results)}`);

        if (results.error !== undefined) {
            throw results;
        }
        dispatch({ type: actions.FETCH_PARTICIPATING_BETS_BY_USER, results });
        dispatch({ type: actions.END_LOADING_PART });

    } catch (error) {
        console.log(`actions.bets.getParticipatingBetsByUser: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
        dispatch({ type: actions.END_LOADING_PART });
    }
}

//
// return the bets for the current user that they are voting on
//
export const getVotingingBetsByUser = (userid, page) => async (dispatch) => {
    
    try {
        dispatch({ type: actions.START_LOADING_VOTE });

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('getVotingingBetsByUser authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        }

        let request = {
            page: page,
            voting: true,
            userid: userid
        }
        const results  = JSON.parse(await window.incredibets.realmUser.functions.fetchBets(JSON.stringify(request))).body;
        
        if (results.error !== undefined) {
            throw results;
        }
        dispatch({ type: actions.FETCH_VOTING_BETS_BY_USER, results });
        dispatch({ type: actions.END_LOADING_VOTE });

    } catch (error) {
        console.log(`actions.bets.getVotingingBetsByUser: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
        dispatch({ type: actions.END_LOADING_VOTE });
    }
}

//
// return the bets for the current user that are waiting for arbitration or bust
//
export const getResolvingBetsByUser = (userid, page) => async (dispatch) => {
    
    try {
        dispatch({ type: actions.START_LOADING_RESOLVING });

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('getResolvingBetsByUser authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        }

        let request = {
            page: page,
            resolving: true,
            userid: userid
        }
        const results  = JSON.parse(await window.incredibets.realmUser.functions.fetchBets(JSON.stringify(request))).body;
        //console.log(`results: ${JSON.stringify(results)}`);

        if (results.error !== undefined) {
            throw results;
        }
        dispatch({ type: actions.FETCH_RESOLVING_BETS_BY_USER, results });
        dispatch({ type: actions.END_LOADING_RESOLVING });

    } catch (error) {
        console.log(`actions.bets.getResolvingBetsByUser: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
        dispatch({ type: actions.END_LOADING_RESOLVING });
    }
}

//
// return the bets for the current user that are waiting for arbitration or bust
//
export const getAllResolvingBets = (page) => async (dispatch) => {
    
    try {
        dispatch({ type: actions.START_LOADING_RESOLVING });

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('getResolvingBetsByUser authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        }

        let request = {
            page: page,
            arbitrate: true
        }
        const results  = JSON.parse(await window.incredibets.realmUser.functions.fetchBets(JSON.stringify(request))).body;        

        if (results.error !== undefined) {
            throw results;
        }
        dispatch({ type: actions.FETCH_RESOLVING_BETS, results });
        dispatch({ type: actions.END_LOADING_RESOLVING });

    } catch (error) {
        console.log(`actions.bets.getResolvingBets: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
        dispatch({ type: actions.END_LOADING_RESOLVING });
    }
}

//
// return the bets won by this user
//
export const getWonBetsByUser = (userid, page = 1) => async (dispatch) => {
   
    try {

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('getWonBetsByUser null realmUser')
            window.incredibets.realmUser = await getApp();
        }

        dispatch({ type: actions.START_LOADING_WON });

        let request = {
            page: page,
            won: true,
            userid: userid
        };
        const results  = JSON.parse(await window.incredibets.realmUser.functions.fetchBets(JSON.stringify(request))).body;
        if (results.error !== undefined) {
            throw results;
        }

        dispatch({ type: actions.FETCH_WON_BETS_BY_USER, results });
        dispatch({ type: actions.END_LOADING_WON });

    } catch (error) {
        console.log(`actions.bets.getWonBetsByUser: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
        dispatch({ type: actions.END_LOADING_WON });
    }
}

//
// return all of the lost bets for this user
//
export const getLostBetsByUser = (userid, page = 1) => async (dispatch) => {
    
    try {

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('getLostBetsByUser authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        }

        dispatch({ type: actions.START_LOADING_LOST });

        let request = {
            page: page,
            lost: true,
            userid: userid
        }
        const results  = JSON.parse(await window.incredibets.realmUser.functions.fetchBets(JSON.stringify(request))).body;
        
        if (results.error !== undefined) {
            throw results;
        }
        dispatch({ type: actions.FETCH_LOST_BETS_BY_USER, results });
        dispatch({ type: actions.END_LOADING_LOST });

    } catch (error) {
        console.log(`actions.bets.getLostBetsByUser: ${JSON.stringify(error)}`);
        dispatch({ type: actions.IB_ERROR, error});
        dispatch({ type: actions.END_LOADING_LOST });
    }
}

//
// clear the current error message
//
export const clearIBError = () => async (dispatch) => {
    
    dispatch({ type: actions.IB_CLEAR_ERROR });
    
}