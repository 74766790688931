import { 
    IB_ERROR, LEDGER_ADD_CREDITS, LEDGER_DEPOSIT_FUNDS, START_LOADING_LEDGER_ENTRIES, END_LOADING_LEDGER_ENTRIES, FETCH_LEDGER_ENTRIES_BY_USER,
    LEDGER_HOLD_CREDITS
} from '../constants/actionTypes';
import { getApp } from '../utils/utils';
import { INCREDIBETS_SYSTEM_USER_ID } from '../config/config'

//
// accept cash deposit from user - ONLY EVER TO THE INCREDIBET SYSTEM account
//
export const addDepositFromUser = (amount, userid) => async (dispatch) => {
    
    //console.log(`ACTION: addDepositfromUser ${JSON.stringify(bet)}`);
    //  const { debit_amount = null, credit_amount = null, credit_userid = null, debit_userid = null, type = null } = JSON.parse(params);

    try {

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('addDepositfromUser authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        };
        
        let request = {
            debit_amount: amount,
            credit_amount: amount,
            credit_userid: INCREDIBETS_SYSTEM_USER_ID,
            debit_userid: userid,
            type: "DEPOSIT",
            units: "USD"

        }
        const results  = JSON.parse(await window.incredibets.realmUser.functions.ledgerEntry(JSON.stringify(request))).body;
        //console.log(`results: ${JSON.stringify(results)}`);
        
        if (results.error !== undefined) {
            throw results;
        }
        dispatch({ type: LEDGER_DEPOSIT_FUNDS, results });

    } catch (error) {
        console.log(`actions.bets.addDepositfromUser: ${JSON.stringify(error)}`);
        dispatch({ type: IB_ERROR, error });
    }
};

//
// process withdrawl in cash from user
//
export const returnDeposiToUser = (amount, userid) => async (dispatch) => {
    
    //console.log(`ACTION: addDepositfromUser ${JSON.stringify(bet)}`);
    //  const { debit_amount = null, credit_amount = null, credit_userid = null, debit_userid = null, type = null } = JSON.parse(params);

    try {

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('addDepositfromUser authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        };
        
        let request = {
            debit_amount: amount,
            credit_amount: amount,
            credit_userid: INCREDIBETS_SYSTEM_USER_ID,
            debit_userid: userid,
            type: "WITHDRAWL",
            units: "USD"

        }
        const results  = JSON.parse(await window.incredibets.realmUser.functions.ledgerEntry(JSON.stringify(request))).body;
        //console.log(`results: ${JSON.stringify(results)}`);
        
        if (results.error !== undefined) {
            throw results;
        }
        dispatch({ type: LEDGER_DEPOSIT_FUNDS, results });

    } catch (error) {
        console.log(`actions.bets.addDepositfromUser: ${JSON.stringify(error)}`);
        dispatch({ type: IB_ERROR, error });
    }
};

//
// when user places a bet we hold some of their credits in reserve - goes to the SYSTEM ONLY
//
export const holdUserCredits = (amount, userid) => async (dispatch) => {
    
    //console.log(`ACTION: holdUserCredits`);
    
    try {

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('addCreditsToUser authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        };
        
        let request = {
            debit_amount: amount,
            credit_amount: amount,
            credit_userid: INCREDIBETS_SYSTEM_USER_ID,
            debit_userid: userid,
            type: "HOLD",
            units: "INCREDITS"

        }
        const results  = JSON.parse(await window.incredibets.realmUser.functions.ledgerEntry(JSON.stringify(request))).body;
        //console.log(`results: ${JSON.stringify(results)}`);
        
        if (results.error !== undefined) {
            throw results;
        }
        dispatch({ type: LEDGER_HOLD_CREDITS, results });

    } catch (error) {
        console.log(`actions.bets.addCreditsToUser: ${JSON.stringify(error)}`);
        dispatch({ type: IB_ERROR, error });
    }
};

//
// add cash value credits to user's wallet
//
export const transferCreditsToUser = (amount, from_userid, to_userid) => async (dispatch) => {
    
    //console.log(`ACTION: addCreditsToUser ${JSON.stringify(bet)}`);
    
    try {

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('addCreditsToUser authenticate realmUser')
            window.incredibets.realmUser = await getApp();
        };
        
        let request = {
            debit_amount: amount,
            credit_amount: amount,
            credit_userid: to_userid,
            debit_userid: from_userid, // INCREDIBETS_SYSTEM_USER_ID,
            type: "TRANSFER",
            units: "INCREDITS"

        }
        const results  = JSON.parse(await window.incredibets.realmUser.functions.ledgerEntry(JSON.stringify(request))).body;
        //console.log(`results: ${JSON.stringify(results)}`);
        
        if (results.error !== undefined) {
            throw results;
        }
        dispatch({ type: LEDGER_ADD_CREDITS, results });

    } catch (error) {
        console.log(`actions.bets.addCreditsToUser: ${JSON.stringify(error)}`);
        dispatch({ type: IB_ERROR, error });
    }
};

//
// grab entries to the ledger sheet for this user
//
export const getLedgerEntries = (userid, units='ALL', page = 1, entriesPerPage = 10) => async (dispatch) => {
    //console.log(`ACTION: getLedgerEntries`);
    try {

        dispatch({ type: START_LOADING_LEDGER_ENTRIES });

        if (window.incredibets.realmUser === undefined) {
            // get an authenticated user oblect from realm-web
            //console.log('getLedgerEntries null realmUser')
            window.incredibets.realmUser = await getApp();
        };

        let request = {
            userid: userid,
            page: page,
            units: units,
            entriesPerPage: entriesPerPage
        };
        const results  = JSON.parse(await window.incredibets.realmUser.functions.fetchLedgerEntries(JSON.stringify(request))).body;
        //console.log(`ACTION: getLedgerEntries for ${JSON.stringify(request)} RETURNED entries: ${JSON.stringify(results)} for page ${page}`);

        if (results.error !== undefined) {
            throw results;
        }
        dispatch({ type: FETCH_LEDGER_ENTRIES_BY_USER, results });
        dispatch({ type: END_LOADING_LEDGER_ENTRIES });

    } catch (error) {
        console.log(`ERROR: actions.ledger.getLedgerEntries: ${JSON.stringify(error)}`);
        dispatch({ type: IB_ERROR, error});
        dispatch({ type: END_LOADING_LEDGER_ENTRIES });
    }
}