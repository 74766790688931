// enumeration for type of bet state
export const PENDING_BETS    = 0;
export const TAKEN_BETS      = 1;
export const LIVE_BETS       = 2;
export const ALL_BETS        = 3;
export const FAV_BETS        = 4;
export const WON_BETS        = 5;
export const LOST_BETS       = 6;
export const RESOLVING_BETS  = 7;

export const LIVE_BETS_LABEL = "Open";
export const PENDING_BETS_LABEL = "Voting";
export const TAKEN_BETS_LABEL = "Taken";
export const RESOLVING_BETS_LABEL = "Arbitration";

/* 

    <Button key="open" variant={ABindex === LIVE_BETS ? "contained" : "text"} startIcon={<HourglassEmptyIcon />} fullWidth={true}
                    sx={{justifyContent: "flex-start"}} onClick={handleOpenBets}>
                      Open&nbsp;Bets
                  </Button>

                  <Badge badgeContent={waitingforvotebets.length} color="success">
                    <Button key="voting" variant={ABindex === PENDING_BETS ? "contained" : "text"} startIcon={<ThumbsUpDownIcon />} fullWidth={true}
                      sx={{justifyContent: "flex-start"}}  onClick={handleVotingBets} >
                        Pending&nbsp;Bets
                    </Button>
                  </Badge>

                  <Button key="live" variant={ABindex === TAKEN_BETS ? "contained" : "text"} startIcon={<AvTimerIcon />} fullWidth={true}
                    sx={{justifyContent: "flex-start"}} onClick={handleLiveBets}>
                      Taken&nbsp;Bets
                  </Button>

                  

                  <Button key="resolving" variant={ABindex === RESOLVING_BETS ? "contained" : "text"} startIcon={<PendingIcon />} fullWidth={true}
                    sx={{justifyContent: "flex-start"}} onClick={handleResolvingBets}>
                      Resolving&nbsp;Bets
                  </Button>


    case PENDING_BETS:
    setCurrentBets(voting);
    break;

    case TAKEN_BETS:
    setCurrentBets(taken);
    break;

    case ALL_BETS:
    setCurrentBets(bets);
    break;

    case WON_BETS:
    setCurrentBets(won);
    break;

    case LOST_BETS:
    setCurrentBets(lost);
    break;

    case LIVE_BETS:
    setCurrentBets(open);
    break;

    case FAV_BETS:
    setCurrentBets(favorites);
    break;
    
    case RESOLVING_BETS:
    setCurrentBets(resolving);
    break;

*/