import { useEffect, useRef, useState } from 'react';
import Users from './usersadmin';
import { Typography, Paper, Button, Stack, Accordion, AccordionSummary, AccordionDetails, 
  Badge, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, CircularProgress,
  Box, Pagination } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../actions/users';
import { useNavigate} from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/People';
import { getActivity } from '../../actions/activity';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ActivityItemSummary from '../activity/activity_item';

/*
  Main screes for showing the list of user accounts to admin level users
*/
const UserHome = () => {
  
  const navigate = useNavigate();
  let dispatchedUsersRef = useRef(false);
  const dispatch = useDispatch();
  const { activity, isLoadingActivity, activityCount, activityPages } = useSelector((state) => state.activity);
  const [ page, setPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);

  useEffect(() => {

    // get the incredibets users
    const dispatchedUsers = dispatchedUsersRef.current;
    if (!dispatchedUsers) {
      dispatchedUsersRef.current = true;
      dispatch(getUsers());
      dispatch(getActivity());
    }

    if (page !== previousPage) {
      setPreviousPage(page);
      dispatch(getActivity(page));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page]);


  const handleActivityPaginationChange = (event, value) => {
    setPage(value);
  };

  return (
   
    <Stack spacing={2}>

      { /*----- PAGE HEADER ------*/} 
      <Paper variant='outlined' sx={{ p:2, borderRight: 0, borderTop: 0, borderLeft: 0, mb: 1 }}>
        <Stack direction='row' spacing={1} alignItems='center'>
            <PeopleIcon sx={{ color: 'dimgray' }} fontSize='large' />
            <Typography variant='h6' color="dimgray" >incrediBets User Admin</Typography>
            <Button variant="text" onClick={() => navigate(-1)}>&lt; Back</Button>
        </Stack>
      </Paper>  

      { /*----- ACTIVITY SUMMARY ------*/}
      <Accordion sx={{ width: '100%'}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="summary">
          <Stack direction="row" spacing={1}>
            <AssignmentTurnedInIcon color="action" />
            <Typography>user activity</Typography>
            <Badge badgeContent={activityCount} color="primary" />
          </Stack>
        </AccordionSummary>

        <AccordionDetails>
          <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{ backgroundColor: 'gray' }}>
                  <TableRow>
                    <TableCell><Typography color="white"><strong>Who</strong></Typography></TableCell>
                    <TableCell><Typography color="white"><strong>When</strong></Typography></TableCell>
                    <TableCell><Typography color="white"><strong>What</strong></Typography></TableCell>                                 
                  </TableRow>
                </TableHead>
                <TableBody>                        
                { !isLoadingActivity 
                  ? activity.map(item => (<ActivityItemSummary activity={item} key={item._id} />)) 
                  : <TableRow><TableCell colSpan={3}><CircularProgress /></TableCell></TableRow>
                }   
                </TableBody>
              </Table>
            </TableContainer>
          <Stack alignItems="center" spacing={1}>
          { (!isLoadingActivity && (activityPages > 1))  &&
            <Box sx={{ p: 1, width: '100%' }} display="flex" alignItems="center" justifyContent="center">
              <Pagination 
                  count={activityPages} 
                  page={page || 1}
                  variant="outlined" 
                  shape="rounded"
                  color="primary" 
                  onChange={handleActivityPaginationChange}
              />
            </Box>
          }
          </Stack>
        </AccordionDetails>
      </Accordion>

      { /*----- USERS ------*/}     
      <Paper>
        <Users />
      </Paper>
     
    </Stack>
    );
  };
    
export default UserHome;