export const CREATE_BET = 'CREATE_BET';
export const UPDATE_BET = 'UPDATE_BET';
export const DELETE_BET = 'DELETE_BET';
export const FETCH_ALL_BETS = 'FETCH_ALL_BETS';
export const FETCH_BET = 'FETCH_BET';
export const FETCH_OPEN_BETS = 'FETCH_OPEN_BETS';
export const FETCH_BET_BY_OWNER = 'FETCH_BET_BY_OWNER';
export const FETCH_BET_BY_SEARCH = 'FETCH_BET_BY_SEARCH';
export const FETCH_FAVORITE_BETS = 'FETCH_FAVORITE_BETS';   // favorite bets for the given user
export const FETCH_PARTICIPATING_BETS_BY_USER = 'FETCH_PARTICIPATING_BETS_BY_USER'; // live bets for the given user (owner or taker)
export const FETCH_VOTING_BETS_BY_USER = 'FETCH_VOTING_BETS_BY_USER';            // voting bets for the given user (owner or taker)
export const FETCH_WON_BETS_BY_USER = 'FETCH_WON_BETS_BY_USER';  
export const FETCH_LOST_BETS_BY_USER = 'FETCH_LOST_BETS_BY_USER';
export const FETCH_OPEN_BETS_BY_USER = 'FETCH_OPEN_BETS_BY_USER';
export const FETCH_TAKEN_BETS_BY_USER = 'FETCH_TAKEN_BETS_BY_USER';
export const FETCH_RESOLVING_BETS_BY_USER = 'FETCH_RESOLVING_BETS_BY_USER';
export const FETCH_RESOLVING_BETS = 'FETCH_RESOLVING_BETS'

export const TAKE_BET = 'TAKE_BET';
export const LIKE_BET = 'LIKE_BET';
export const VOTE_BET = 'VOTE_BET';
export const EXPEDITE_BET = 'EXPEDITE_BET';
export const APPEAL_BET = 'APPEAL_BET';
export const ARBITRATE_BET = 'ARBITRATE_BET';
export const SET_ARBITRATION_TEXT = 'SET_ARBITRATION_TEXT';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';

export const START_LOADING_WALLET = 'START_LOADING_WALLET';
export const END_LOADING_WALLET = 'END_LOADING_WALLET';
export const FETCH_WALLET_AMOUNT = 'FETCH_WALLET_AMOUNT';
export const FETCH_WALLET_HISTORY = 'FETCH_WALLET_HISTORY';

export const END_LOADING = 'END_LOADING';
export const START_LOADING = 'START_LOADING';

export const START_LOADING_FAVS = "START_LOADING_FAVS";
export const END_LOADING_FAVS = "END_LOADING_FAVS";

export const START_LOADING_LOST = "START_LOADING_LOST";
export const END_LOADING_LOST = "END_LOADING_LOST";

export const START_LOADING_OPEN = "START_LOADING_OPEN";
export const END_LOADING_OPEN = "END_LOADING_OPEN";

export const START_LOADING_PART = "START_LOADING_PART";
export const END_LOADING_PART = "END_LOADING_PART";

export const START_LOADING_VOTE = "START_LOADING_VOTE";
export const END_LOADING_VOTE = "END_LOADING_VOTE";

export const START_LOADING_WON = "START_LOADING_WON";
export const END_LOADING_WON = "END_LOADING_WON";

export const START_LOADING_TAKEN = "START_LOADING_TAKEN";
export const END_LOADING_TAKEN = "END_LOADING_TAKEN";

export const START_LOADING_RESOLVING = "START_LOADING_TAKEN";
export const END_LOADING_RESOLVING = "END_LOADING_TAKEN";

export const FETCH_ALL_USERS = 'FETCH_ALL_USERS';
export const FETCH_USER = 'FETCH_USER';
export const ACTIVATE_USER = 'ACTIVATE_USER';
export const MAKE_USER_ADMIN = 'MAKE_USER_ADMIN';

export const IB_ERROR = "IB_ERROR";
export const IB_CLEAR_ERROR = "IB_CLEAR_ERROR"

export const FETCH_ACTIVITY_BY_USER = "FETCH_ACTIVITY_BY_USER";
export const FETCH_ACTIVITY = "FETCH_ACTIVITY";
export const START_LOADING_ACTIVITY = "START_LOADING_ACTIVITY";
export const END_LOADING_ACTIVITY = "END_LOADING_ACTIVITY";

export const LEDGER_DEPOSIT_FUNDS = "LEDGER_DEPOSIT_FUNDS";
export const LEDGER_ADD_CREDITS = "LEDGER_ADD_CREDITS";
export const LEDGER_HOLD_CREDITS = "LEDGER_HOLD_CREDITS";
export const START_LOADING_LEDGER_ENTRIES = "START_LOADING_LEDGER_ENTRIES";
export const END_LOADING_LEDGER_ENTRIES = "END_LOADING_LEDGER_ENTRIES";
export const FETCH_LEDGER_ENTRIES_BY_USER = 'FETCH_LEDGER_ENTRIES_BY_USER'; 
