import { FETCH_FAVORITE_BETS, START_LOADING_FAVS, END_LOADING_FAVS, DELETE_BET, LIKE_BET, LOGOUT } from '../constants/actionTypes';

const INITIAL_STATE = {
  isLoadingFavs: true,
  favCount: 0, 
  favPage: 1,
  favPages: 1,
  favsPerPage: 6,
  favorites: [] 
}

const favsReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {

    case LOGOUT:
        return { ...INITIAL_STATE };

    case START_LOADING_FAVS:
      return { ...state, isLoadingFavs: true };

    case END_LOADING_FAVS:
      return { ...state, isLoadingFavs: false};

    case FETCH_FAVORITE_BETS:
      return {
        ...state, 
        favorites: action.results.bets,
        favCount: Number(action.results.numberOfBets),
        favPage: Number(action.results.currentPage),
        favPages: Number(action.results.numberOfPages),
        favsPerPage: Number(action.results.bets_per_page)
      };

    case DELETE_BET:
      if (state.favorites.some((bet) => bet._id === action.results.betID)) {
        //console.log('bet found in favorites array, removing it');
        return {
          ...state, 
          favorites: state.favorites.filter((bet) => bet._id !== action.results.betID),
          favCount: state.favCount - 1,
          favPages: (state.favCount - 1)/state.favsPerPage
        };
      } else {
        return { ...state };
      };
      
    case LIKE_BET:
      //console.log(`favorites like bet reducer triggered:\n ${JSON.stringify(action.results)}`);
      // if the the first link on the bet - if so add it to the array
      if (action.results.isLike && action.results.bet.likeCount === 1) {
        //console.log(`adding bet to fav array`);
        return { 
          ...state, 
          favorites: [...state.favorites, action.results.bet] 
        };
      // is this the last like on this bet - if so pull it from the array
      } else if (action.results.bet.likeCount === 0) {
        //console.log(`removing bet from fav array`);
        return { 
          ...state, 
          favorites: state.favorites.filter((bet) => bet._id !== action.results.bet._id)
        };
      }
      //console.log(`simple update to fav array`);
      // otherwise; just map it
      return { 
        ...state, 
        favorites: state.favorites.map((bet) => (bet._id === action.results._id ? action.results : bet)) 
      };

    default:
      return state;
  }
};

export default favsReducer;