import React from 'react';
import { Typography } from "@mui/material";
import { useCountdown } from '../../../hooks/useCountdown';
import DateTimeDisplay from './DateTimeDisplay';
import { Stack } from "@mui/material";

const ExpiredNotice = () => {
    return (
        <Typography variant='caption' sx={{ textAlign: "center", pt: 3 }}>incredibets has this bet in the oven, it will be out shortly...</Typography>
    );
  };
 
  const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <Stack direction="row" className="show-counter">
        <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} />
        <p>:</p>
        <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
      </Stack>
    );
  };
  

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;