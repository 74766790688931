import * as React from 'react';
import { Grid, Box, Container, IconButton, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
  
  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

    return (
        <footer className='footer'>
            <Box sx={{ bgcolor: 'text.secondary', color: 'text.primary'}}>
                
                <Container maxWidth="lg">
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-evenly"
                      alignItems="center"
                      height="40px"
                      color={'text.primary'}
                    >                    
                      <IconButton href="/" sx={{ color: 'white' }}>
                        <FacebookIcon />
                      </IconButton>

                      <IconButton href="/" sx={{ color: 'white' }}>
                        <InstagramIcon />
                      </IconButton>
                      
                      <Typography sx={{ color: 'white' }} variant="caption">
                        copyright &copy; {getCurrentYear()} incredibets
                      </Typography>
                      
                      <IconButton href="/" sx={{ color: 'white' }}>
                        <LinkedInIcon />
                      </IconButton>
                      
                      <IconButton href="/" sx={{ color: 'white' }}>
                        <TwitterIcon />
                      </IconButton>
                    </Grid>
                </Container>
                
            </Box>
        </footer>
    );
  };
  
  export default Footer;