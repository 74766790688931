import { useState, useEffect, useRef } from "react";
import { useDispatch } from 'react-redux';
import { Card, CardActions, CardContent, CardMedia, Button, Typography, IconButton, CardHeader, CardActionArea, Stack, 
    Divider, Paper, Grid, Avatar, Skeleton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, Box, DialogActions, DialogContent } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useNavigate } from 'react-router-dom';
import { truncate, getIBDateAsString, getIBTimeAsString, getIBVoteDateAsStirng, getIBDateAsMS } from '../../../utils/utils';
import IBAvatar from '../../users/avatar';
import CountDownTimer from '../timer/CountdownTimer';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import GavelIcon from '@mui/icons-material/Gavel';
import { takeBet, voteBet, expediteBet, appealBet, clearIBError, arbitrateBet } from '../../../actions/bets';
import AutoSaveTextField from '../../general/AutoSaveTextField';
import fetch from 'node-fetch';

const Bet = ({ bet, onDelete, onLike, user, authenticated, detailed }) => {
    
    var terms = bet.title.split(/[ ,]+/);
    const dispatch = useDispatch();
    let dispatchedRef = useRef(false);
    const [open, setOpen] = useState(false);
    const [openSignUp, setOpenSignUp] = useState(false);
    const [takeBetOpen, setTakeBetOpen] = useState(false);
    const [expediteBetOpen, setExpediteBetOpen] = useState(false);
    const [ownerWinsOpen, setOwnerWinsOpen] = useState(false);
    const [takerWinsOpen, setTakerWinsOpen] = useState(false);
    const [arbitrateBetOpen, setArbitrateBetOpen] = useState(false);
    const [arbitrateWinner, setArbitrateWinner] = useState();
    const [arbitrateLoser, setArbitrateLoser] = useState();
    const userId = user?._id;
    const navigate = useNavigate();
    const titlelength = detailed ? -1 : 35;
    const stackSpacing = detailed ? 2 : 1;
    const maxheight = detailed ? '100hv' : '575px';
    const descriptionlength = detailed ? -1 : 170;
    const mediaheight = detailed ? '256px' : '184px';
    const isOwner = userId === bet.ownerID;
    const isTaker = userId === bet.takerID;
    const [betImageURL, setBetImageURL] = useState(null); 

    const betStatusLabels = {
        'open': ' LIVE',
        'taken': 'TAKEN',
        'voting': 'VOTING',
        'pending': 'ARBITRATION',
        'defaulted': 'DEFAULTED',
        'concluded': 'CONCLUDED',
        'expired' : 'EXPIRED',
        'deleted' : 'DELETED',
        'unknown': 'UNKNOWN',
    };

    const betHeaderBGColor = {
        'open': 'DodgerBlue',
        'taken': '#B6CDCD',
        'voting': 'DarkSlateGrey',
        'defaulted': 'Black',
        'concluded': 'DarkSlateGrey',
        'pending': 'Yellow',
        'expired': 'SlateGrey',
        'deleted': 'SlateGrey',
        'unknown': 'Black',
    };

    const betBodyBGColor = {
        'open': 'DodgerBlue',
        'taken': '#B6CDCD',
        'voting': 'DarkSlateGrey',
        'defaulted': 'Black',
        'concluded': '#B6CDCD',
        'pending': 'Yellow',
        'expired': 'SlateGrey',
        'deleted': 'SlateGrey',
        'unknown': 'Black',
    };

    const betTitleFontColor = {
        'open': 'White',
        'taken': 'Black',
        'pending': 'Red',
        'concluded': 'White',
        'voting': 'White',
        'expired': 'White',
        'deleted': 'White',
        'unknown': '#f2f2f2',
    };

    const betBodyFontColor = {
        'open': 'White',
        'taken': 'Black',
        'pending': 'Red',
        'concluded': 'Black',
        'voting': 'White',
        'expired': 'White',
        'deleted': 'White',
        'unknown': '#f2f2f2',
    }

    // fetch the bet image
    useEffect(() => {
        const dispatched = dispatchedRef.current;
        if (!dispatched) {
            if (!bet.imageURL) getBetImage();
            dispatchedRef.current = true;
        } 
       
    }, [bet]);

    terms = terms.filter(function(item) {
        return item.length > 3;
    })

    const handleClickOpen = () => {
        setOpen(true);
    }
  
    const handleClickClose = () => {
        setOpen(false);
    }

    const handleSignupClose = () => {
        setOpenSignUp(false);
    }

    const handleDeleteClick = () => {
        setOpen(false);
        onDelete(bet._id);
    }

    const handleLikeClick = () => {
        onLike(bet._id, userId);
    }

    const handleTakeBetClickOpen = () => {
        //console.log("handle open");
        setTakeBetOpen(true);
    }

    const handleTakeBetClickClose = () => {
        setTakeBetOpen(false);
    }

    const handleTakeBetClick = () => {
        setTakeBetOpen(false);
        dispatch(takeBet(bet._id, userId, navigate));
    }

    const Likes = () => {
        if (bet.likes.length > 0) {
          return bet.likes.find((like) => like === userId)
            ? (
              <><FavoriteIcon fontSize="small" />&nbsp;{bet.likes.length}</>
            ) : (
              <><FavoriteBorder fontSize="small" />&nbsp;{bet.likes.length}</>
            );
        }
    
        return <FavoriteBorder fontSize="small" />;
    }

    // nab an image from the unsplash API M
    async function getBetImage() {
        //console.log('getBetImage');
        try {
            let response = await fetch('https://api.unsplash.com/photos/random?query=' + terms.join(",") + '&client_id=Fvn4b30opxfQWajhCdrVitl9PqbBIP6lQrncf4HZ4NU');
            //console.log(response);
            if (!response.ok) {
                setBetImageURL("https://aicontentfy.com/hubfs/Blog/e2f82ed6-4180-4648-9560-949a48793661.jpg");
                return;
            }

            let responseJson = await response.json();
            //console.log(`image meta: ${JSON.stringify(responseJson.urls.regular)}`);
            //encodeURI("https://source.unsplash.com/random?" + terms.join(",") + "&content_filter=high")}
            let image_uri = JSON.stringify(responseJson.urls.regular).replace('"','');
            //console.log(`image url: ${image_uri}`);
            setBetImageURL(image_uri);
            bet.imageURL = image_uri;

        } catch (error) {
            console.error(error);
            setBetImageURL("https://aicontentfy.com/hubfs/Blog/e2f82ed6-4180-4648-9560-949a48793661.jpg");
        }
        
    }

    const ownerHasVoted = () => {
        if (bet.vote?.owner) return true;
        return false;
    }

    const takerHasVoted = () => {
        if (bet.vote?.taker) return true;
        return false;
    }

    const handleClick = () => {
        if (!authenticated) {
            setOpenSignUp(true);
        }
        if (authenticated && !detailed) {
            navigate('/bet/' + bet._id);
        } else {
            return;
        }
    }

    const handleSignUpIn = () => {
        navigate('/login');
    }

    const handleExpediteBetClickOpen = () => {
        setExpediteBetOpen(true);
    }

    const handleExpediteBetClickClose = () => {
        setExpediteBetOpen(false);
      }
    
    const handleExpediteBetClick = () => {
        setExpediteBetOpen(false);
        dispatch(expediteBet(bet._id, userId, navigate));
    }

    const handleTakerWins = () => {
        setTakerWinsOpen(false);
        dispatch(voteBet(bet._id, userId, bet.takerID, navigate));
    }
    
    const handleOwnerWins = () => {
        setOwnerWinsOpen(false);
        dispatch(voteBet(bet._id, userId, bet.ownerID, navigate));
    }

    const handleOwnerWinsOpen = () => {
        setOwnerWinsOpen(true);
    }
    
    const handleOwnerWinsClose = () => {
        setOwnerWinsOpen(false);
    }
    
    const handleTakerWinsOpen = () => {
        setTakerWinsOpen(true);
    }
    
    const handleTakerWinsClose = () => {
        setTakerWinsOpen(false);
    }
    
    const handleArbitrateClick = (winuser, loseuser) => {
        setArbitrateBetOpen(true);
        setArbitrateWinner(winuser);
        setArbitrateLoser(loseuser);
    }
    
    const handleArbitrateBetClickClose = () => {
        setArbitrateBetOpen(false);
    }

    const handleArbitrateBet = () => {
        dispatch(arbitrateBet(bet._id, userId, arbitrateWinner.id, arbitrateLoser.id, navigate));
    }

    const usersVote = () => {
        // are they the owner or taker of this bet?
        if (isOwner) {
            // if they are the owner, see if they voted fore themselves
            if (bet.vote?.owner?.vote === bet.ownerID) return bet.vote?.owner;
            else return bet.vote?.taker;
        } else {
            if (bet.vote?.taker?.vote === bet.takerID) return bet.vote?.taker;
            else return bet.vote?.owner;
        }
    }

    const usersUser = () => {
        // are they the owner or taker of this bet?
        if (isOwner) {
        // if they are the owner, see if they voted fore themselves
          if (bet.vote?.owner?.vote === bet.ownerID) return bet.owner;
          else return bet.taker;
        }
        
        if (bet.vote?.taker?.vote === bet.takerID) return bet.taker;
        else return bet.owner;

    }

    // how did the other person vote on this bet
    const othersVote = () => {
        // are they the owner or taker of this bet?
        if (isOwner) {
            // if they are the owner, see if they voted fore themselves
            if (bet.vote?.owner?.vote === bet.ownerID) return bet.vote?.taker;
            else return bet.vote?.owner;
        } else if (bet.vote?.taker?.vote === bet.takerID) return bet.vote?.owner;
        
        return bet.vote?.taker;
    }

    // how did the other person vote on this bet
    const othersUser = () => {
        // are they the owner or taker of this bet?
        if (isOwner) {
            // if they are the owner, see if they voted fore themselves
            if (bet.vote?.owner?.vote === bet.ownerID) return bet.taker;
            return bet.owner;
        } else if (bet.vote?.taker?.vote === userId) return bet.owner;
        
        return bet.taker;
    }

    // return the description text of the bet
    const getBetDescription = () => {

        // detailed form
        if (detailed) return (    
            <Typography variant="body2" sx={{ color: betBodyFontColor[bet.status ?? 'unknown'], minHeight: '80px', p:2 }}>
                {truncate(bet.description, descriptionlength)}
            </Typography> 
        ) 

        // short form
        return (    
            <Typography variant="body2" sx={{ color: betBodyFontColor[bet.status ?? 'unknown'], minHeight: '80px', maxHeight: '80px' }}>
                {truncate(bet.description, descriptionlength)}
            </Typography> 
        )
    }    

    /* ------------- DIALOG: VOTE CONFIRMATION ------------- */
    /* the user is declaring that the owner of the bet has won */
    const diagOwnerVoteConfimation = () => {
        
        if (!detailed) return;

        return (
        <Dialog open={ownerWinsOpen} onClose={handleOwnerWinsClose} maxWidth="sm" fullWidth={true}>
          <DialogTitle>incredibets: SETTLE BET</DialogTitle>
          <Box position="absolute" top={0} right={0}>
              <IconButton onClick={handleOwnerWinsClose}>
              <CloseIcon />
              </IconButton>
          </Box>
          <DialogContent dividers>
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
              <Typography variant="h6">{user.firstname}, confirm your vote:</Typography>
              <Stack spacing={1} direction="row" justifyContent="center" alignItems="center" sx={{ mt:2 }}>
                <Typography color="GrayText.secondary"> WINNER: </Typography>
                <IBAvatar picture={bet?.owner?.picture} name={bet?.owner?.name} />
                <Stack direction="column">
                  <Typography>{bet?.owner?.name}</Typography>
                  <Typography variant="caption" sx={{ fontWeight: 100 }} noWrap={true}>member since {new Date(bet.owner?.createdAt).getFullYear()}</Typography>
                </Stack>
              </Stack>
              { bet.ownerID === userId ?
                <>
                <Typography variant="h6">you will win ${parseFloat(bet.wallet?.takerStake).toFixed(2)}</Typography>
                <Paper variant="outlined" sx={{ p: 2, backgroundColor: 'success.main', border: '2px white' }} >
                  <Typography variant="h6" sx={{ color: 'white' }}>total payout: ${parseFloat(parseFloat(bet.wallet?.takerStake) + parseFloat(bet.wallet?.ownerStake)).toFixed(2)}</Typography>
                </Paper>
                </>
              :
                <Typography variant="h6">you will lose ${parseFloat(bet.wallet?.ownerStake).toFixed(2)}</Typography>
              }
            </Stack>
          </DialogContent>
          <DialogActions>
              <Button color="primary" variant="contained" onClick={handleOwnerWinsClose}>
                  Cancel
              </Button>
              <Button color="secondary" variant="contained" onClick={() => handleOwnerWins()}>
                  Confirm
              </Button>
          </DialogActions>
        </Dialog>
        )
    }

    /* ------------- DIALOG: VOTE CONFIRMATION ------------- */
    /* the user is declaring that the taker of the bet has won */
    const diagTakerVoteConfimation = () => {
    
        if (!detailed) return;

        return (
            <Dialog open={takerWinsOpen} onClose={handleTakerWinsClose} maxWidth="sm" fullWidth={true}>
            <DialogTitle>incredibets: SETTLE BET</DialogTitle>
            <Box position="absolute" top={0} right={0}>
                <IconButton onClick={handleTakerWinsClose}>
                <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent dividers>
              <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                <Typography variant="h6">{user.firstname}, confirm your vote:</Typography>
                <Stack spacing={1} direction="row" justifyContent="center" alignItems="center" sx={{ mt:2 }}>
                  <Typography color="GrayText.secondary"> WINNER: </Typography>
                  <IBAvatar picture={bet.taker?.picture} name={bet.taker?.name} />
                  <Stack direction="column">
                    <Typography>{bet?.taker?.name}</Typography>
                    <Typography variant="caption" sx={{ fontWeight: 100 }} noWrap={true}>member since {new Date(bet?.taker?.createdAt).getFullYear()}</Typography>
                  </Stack>
                </Stack>
                { bet.takerID === userId ?
                  <>
                  <Typography variant="h6">you will win ${parseFloat(bet.wallet?.ownerStake).toFixed(2)}</Typography>
                  <Paper variant="outlined" sx={{ p: 2, backgroundColor: 'success.main', border: '2px white' }} >
                    <Typography variant="h6" sx={{ color: 'white' }}>total payout: ${parseFloat(parseFloat(bet.wallet?.takerStake) + parseFloat(bet.wallet?.ownerStake)).toFixed(2)}</Typography>
                  </Paper>
                  </>
                :
                  <Typography variant="h6">you will lose ${parseFloat(bet.wallet?.takerStake).toFixed(2)}</Typography>
                }
              </Stack>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={handleTakerWinsClose}>
                    Cancel
                </Button>
                <Button color="secondary" variant="contained" onClick={() => handleTakerWins()}>
                    Confirm
                </Button>
            </DialogActions>
          </Dialog>
        )

    }

    /* ------------- DIALOG: EXPEDITE A BET ------------- */
    const diagExpediteBet = () => {

        if (!detailed) return;
        if (!user.admin) return;

        return (
          <Dialog open={expediteBetOpen} onClose={handleExpediteBetClickClose} maxWidth="sm" fullWidth={true}>
            <DialogTitle>EXPEDITE A BET</DialogTitle>
            <Box position="absolute" top={0} right={0}>
                <IconButton onClick={handleExpediteBetClickClose}>
                <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent dividers>
              <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                <Typography variant="h6">confirm that you want to expedite the following bet:</Typography>
                <Typography variant="h6">{bet.title}</Typography>
                <Stack spacing={5} direction="column"  alignItems="center" sx={{ minWidth: 275, maxWidth: 275 }}>
                  <Stack direction="row" spacing={2}>
                    <Stack direction="column" alignItems="center">
                      <Typography variant="body">Odds Given:</Typography>
                      <Divider orientation="horizontal" flexItem />
                      <Typography variant="body">{bet.odds_receiving}:{bet.odds_staked}</Typography>
                      <Typography>&nbsp;</Typography>
                      <Typography variant="body">To Win:</Typography>
                      <Divider orientation="horizontal" flexItem />
                      <Typography variant="body">${parseFloat(bet.amount).toFixed(2)}</Typography>
                    </Stack>
                    <Typography />
                    <Stack direction="column" alignItems="center">
                      <Typography variant="body">Max Risk:</Typography>
                      <Divider orientation="horizontal" flexItem />
                      <Typography variant="body">${parseFloat(bet.odds_staked / bet.odds_receiving * bet.amount).toFixed(2)}</Typography>
                      <Typography>&nbsp;</Typography>
                      <Typography variant="body">Your Payout:</Typography>
                      <Divider orientation="horizontal" flexItem />
                      <Typography variant="body">${parseFloat(parseInt(bet.amount) + (bet.odds_staked / bet.odds_receiving * bet.amount)).toFixed(2)}</Typography>
                    </Stack>
                  </Stack>
                  <Paper variant="outlined" sx={{ p: 1 }}>
                    <Stack spacing={1} direction="row" justifyContent="center" alignItems="flex-end">
                      <Typography color="GrayText.secondary"> Potential Profit: </Typography>
                      <Typography sx={{ fontWeight: 'bold' }}>{parseFloat((parseInt(bet.odds_receiving) / parseInt(bet.odds_staked)) * 100).toFixed(0)}%</Typography>
                    </Stack>
                  </Paper>
                </Stack>
              </Stack>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={handleExpediteBetClickClose}>
                    Cancel
                </Button>
                <Button color="secondary" variant="contained" onClick={() => handleExpediteBetClick()}>
                    Confirm
                </Button>
            </DialogActions>
          </Dialog>
        )
    }

    /* ------------- DIALOG: TAKE A BET ------------- */
    const diagTakeBet = () => {
        
        if (!detailed) return;

        return (
            <Dialog open={takeBetOpen} onClose={handleTakeBetClickClose} maxWidth="sm" fullWidth={true}>
              <DialogTitle>let's get ready to incredi!</DialogTitle>
              <Box position="absolute" top={0} right={0}>
                  <IconButton onClick={handleTakeBetClickClose}>
                  <CloseIcon />
                  </IconButton>
              </Box>
              <DialogContent dividers>
                <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                  <Typography variant="h6">confirm that you are ready to take the following bet:</Typography>
                  <Typography variant="h6">{bet.title}</Typography>
                  <Stack spacing={5} direction="column"  alignItems="center" sx={{ minWidth: 275, maxWidth: 275 }}>
                    <Stack direction="row" spacing={2}>
                      <Stack direction="column" alignItems="center">
                        <Typography variant="body">Odds Given:</Typography>
                        <Divider orientation="horizontal" flexItem />
                        <Typography variant="body">{bet.odds_receiving}:{bet.odds_staked}</Typography>
                        <Typography>&nbsp;</Typography>
                        <Typography variant="body">To Win:</Typography>
                        <Divider orientation="horizontal" flexItem />
                        <Typography variant="body">${parseFloat(bet.amount).toFixed(2)}</Typography>
                      </Stack>
                      <Typography />
                      <Stack direction="column" alignItems="center">
                        <Typography variant="body">Max Risk:</Typography>
                        <Divider orientation="horizontal" flexItem />
                        <Typography variant="body">${parseFloat(bet.odds_staked / bet.odds_receiving * bet.amount).toFixed(2)}</Typography>
                        <Typography>&nbsp;</Typography>
                        <Typography variant="body">Your Payout:</Typography>
                        <Divider orientation="horizontal" flexItem />
                        <Typography variant="body">${parseFloat(parseInt(bet.amount) + (bet.odds_staked / bet.odds_receiving * bet.amount)).toFixed(2)}</Typography>
                      </Stack>
                    </Stack>
                    <Paper variant="outlined" sx={{ p: 1 }}>
                      <Stack spacing={1} direction="row" justifyContent="center" alignItems="flex-end">
                        <Typography color="GrayText.secondary"> Potential Profit: </Typography>
                        <Typography sx={{ fontWeight: 'bold' }}>{parseFloat((parseInt(bet.odds_receiving) / parseInt(bet.odds_staked)) * 100).toFixed(0)}%</Typography>
                      </Stack>
                    </Paper>
                  </Stack>
                </Stack>
              </DialogContent>
              <DialogActions>
                  <Button color="primary" variant="contained" onClick={handleTakeBetClickClose}>
                      Cancel
                  </Button>
                  <Button color="secondary" variant="contained" onClick={() => handleTakeBetClick()}>
                      Confirm
                  </Button>
              </DialogActions>
            </Dialog>
        )
    }

    /* ------------- DIALOG: ARBITRATION CONFIRMATION ------------- */
    const diagArbitrationConfirmation = () => {

        /* an admin user is declaring the results of a bet arbitration */ 
        if (!detailed) return;
        return (
            <Dialog open={arbitrateBetOpen} onClose={handleArbitrateBetClickClose} maxWidth="md" fullWidth={true}>
            <DialogTitle>incredibets: Arbitrate Bet (Admin)</DialogTitle>
            <Box position="absolute" top={0} right={0}>
                <IconButton onClick={handleArbitrateBetClickClose}>
                <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent dividers>
                <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                <Typography variant="h6">{user.firstname},</Typography>
                
                <Stack spacing={1} direction="column" sx={{ ml: 2 }} alignItems="center"  >
                    <Typography sx={{ fontWeight: 600, textAlign: "center" }}>You are selecting</Typography>              
                    <Divider  sx={{borderBottomWidth: 2, width: '100%'}}/>                 
                    <Stack spacing={1} direction="row" justifyContent="center" alignItems="center" sx={{ mt:2 }}>                                
                    <Avatar alt={arbitrateWinner?.name} src={arbitrateWinner?.picture} referrerPolicy="no-referrer" />
                    <Stack direction="column">
                        <Typography>{arbitrateWinner?.name}</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 100 }} noWrap={true}>member since {new Date(arbitrateWinner?.createdAt).getFullYear()}</Typography>                     
                    </Stack>
                    </Stack>     
                    <Typography>As the winner of bet: <strong>{bet.title}</strong>.</Typography>                                   
                </Stack>        
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={handleArbitrateBetClickClose}>
                    Cancel
                </Button>
                <Button color="error" variant="contained" onClick={() => handleArbitrateBet()}>
                    Confirm
                </Button>
            </DialogActions>
            </Dialog>
        )
    }

    const getBetOdds = () => {

        if (bet.status === 'concluded' && !detailed) return;
        if (bet.status === 'taken' && !detailed) return;

        return (            
                
        <Stack alignItems="center" justifyContent="center" spacing={0.5}>

            { !detailed &&
            <>
            <Typography sx={{ fontSize: 14, color: betBodyFontColor[bet.status ?? 'unknown'], textAlign: "center"}} >
                this bet { ['open', 'taken'].includes(bet.status) ? 'pays' : 'would have payed' } <strong>${parseFloat(bet.amount).toFixed(2)}</strong>
            </Typography>      
            <Typography sx={{ fontSize: 14, color: betBodyFontColor[bet.status ?? 'unknown'], textAlign: "center"}} >
                at <strong>{bet.odds_receiving}:{bet.odds_staked}</strong> odds
            </Typography>  
            </>
            }     

            { detailed &&
            
            <Paper elevation={0} sx={{ bgcolor: 'whitesmoke', p:2, minWidth: '300px' }}>
                <Stack direction="row" justifyContent="center" spacing={4}>                                                                       
                    <Stack spacing={5} direction="column" alignItems="center" justifyContent="center">
                        <Stack direction="row" spacing={2}>
                        <Stack direction="column" alignItems="center">
                            <Typography sx={{ fontWeight: 500 }} noWrap={true}><strong>Odds Given</strong></Typography>
                            <Divider orientation="horizontal" flexItem />
                            <Typography>{bet.odds_receiving}:{bet.odds_staked}</Typography>
                            <Typography>&nbsp;</Typography>
                            <Typography sx={{ fontWeight: 500 }} noWrap={true}><strong>To Win</strong></Typography>
                            <Divider orientation="horizontal" flexItem />
                            <Typography>${parseFloat(bet.amount).toFixed(2)}</Typography>
                        </Stack>
                        <Typography />
                        <Stack direction="column" alignItems="center">
                            <Typography sx={{ fontWeight: 500 }} noWrap={true}><strong>Max Risk</strong></Typography>
                            <Divider orientation="horizontal" flexItem />
                            <Typography>${parseFloat(bet.odds_staked / bet.odds_receiving * bet.amount).toFixed(2)}</Typography>
                            <Typography>&nbsp;</Typography>
                            <Typography sx={{ fontWeight: 500 }} noWrap={true}><strong>Payout</strong></Typography>
                            <Divider orientation="horizontal" flexItem />
                            <Typography>${parseFloat(parseFloat(bet.amount) + (bet.odds_staked / bet.odds_receiving * bet.amount)).toFixed(2)}</Typography>
                        </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Paper>
            }                    
        </Stack>
        )
    }

    const getVoteResults = () => {

        if (!detailed) return;

        return (
          <Grid container direction="row" spacing={1} justifyContent="center" alignItems="center">
            <Grid item xs={6}>                            
              <Stack spacing={1} direction="column" sx={{ mr: 2}} alignItems="flex-end" >
                <Typography sx={{ fontWeight: 600, textAlign: "center", color: betBodyFontColor[bet.status ?? 'unknown'] }}>
                    Your Vote
                </Typography>
                <Divider  sx={{borderBottomWidth: 2, width: '100%', bgcolor: betBodyFontColor[bet.status ?? 'unknown'] }}/>
                <Typography sx={{ color: betBodyFontColor[bet.status ?? 'unknown'] }}>
                    On {getIBDateAsString(usersVote()?.votedAt)} {getIBTimeAsString(usersVote()?.votedAt)} you selected
                </Typography> 
                <Stack spacing={1} direction="row" justifyContent="center" alignItems="center" sx={{ mt:2 }}>                                                               
                  <Avatar alt={usersUser()?.name} src={usersUser()?.picture} referrerPolicy="no-referrer" />
                  <Stack direction="column">
                    <Typography sx={{ color: betBodyFontColor[bet.status ?? 'unknown'] }}>
                        {usersUser()?.name}
                    </Typography>
                    <Typography variant="caption" sx={{ fontWeight: 100, color: betBodyFontColor[bet.status ?? 'unknown'] }} noWrap={true}>
                        member since {new Date(usersUser()?.createdAt).getFullYear()}
                    </Typography>
                  </Stack>
                </Stack>
                <Typography sx={{ color: betBodyFontColor[bet.status ?? 'unknown'] }}>
                    As the winner of this bet.
                </Typography>
                { user.admin &&
                    <Button variant="contained" onClick={() => handleArbitrateClick(usersUser(), othersVote())}>Declare {usersUser()?.name} As Winner</Button> 
                }   
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={1} direction="column" sx={{ ml: 2}} alignItems="flex-start" >
                <Typography sx={{ fontWeight: 600, textAlign: "center", color: betBodyFontColor[bet.status ?? 'unknown'] }}>
                    Their Vote
                </Typography>              
                <Divider  sx={{borderBottomWidth: 2, width: '100%', bgcolor: betBodyFontColor[bet.status ?? 'unknown'] }}/>
                <Typography sx={{ color: betBodyFontColor[bet.status ?? 'unknown'] }}>
                    On {getIBDateAsString(othersVote()?.votedAt)} {getIBTimeAsString(othersVote()?.votedAt)} they selected
                </Typography> 
                <Stack spacing={1} direction="row" justifyContent="center" alignItems="center" sx={{ mt:2 }}>                                
                  <Avatar alt={othersUser()?.name} src={othersUser()?.picture} referrerPolicy="no-referrer" />
                  <Stack direction="column">
                    <Typography sx={{ color: betBodyFontColor[bet.status ?? 'unknown'] }}>
                        {othersUser()?.name}
                    </Typography>
                    <Typography variant="caption" sx={{ fontWeight: 100, color: betBodyFontColor[bet.status ?? 'unknown'] }} noWrap={true}>
                        member since {new Date(othersUser()?.createdAt).getFullYear()}
                    </Typography>
                  </Stack>
                </Stack>
                <Typography sx={{ color: betBodyFontColor[bet.status ?? 'unknown'] }}>
                    As the winner of this bet.
                </Typography>
                { user.admin &&
                    <Button variant="contained" onClick={() => handleArbitrateClick(othersUser(), usersUser())}>Declare {othersUser()?.name} As Winner</Button> 
                }    
              </Stack>                                                                                
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ fontWeight: 100, pt: 2, color: betBodyFontColor[bet.status ?? 'unknown'], textAlign: 'center' }}>
                    an incredibets admin will review and decide who won this bet or bust it in the event that the outcome is indeterminant.
                </Typography>
            </Grid>                     
          </Grid>
        )
      }

    const getArbitrationNotice = () => {

        if (!detailed) return;

        return (
            <Paper variant="outlined" sx={{ p: 2, backgroundColor: 'red', border: '2px white' }} >    
                <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={1}>
                    <Avatar sx={{ bgcolor: 'black' }}><GavelIcon /></Avatar>
                    <Typography color="white" sx={{ fontWeight: 600 }}>Bet In Arbitration</Typography>
                    <Typography color="white" variant="caption">As the outcome of the bet was not agreed by all parities, this bet has gone to arbitration.</Typography>  
                </Stack>     
            </Paper>
        )
    }

    const getBetRiskGainInfo = () => {
    
        // save this for the details page only
        if (!detailed) return;
    
        return (
            <Stack spacing={1} direction="column" alignItems='center' justifyContent='center'>   

            <Paper variant="outlined" sx={{ backgroundColor: 'error.main', p:2, minWidth: '300px' }} >      
              <Stack spacing={1} direction="row" justifyContent='center' alignItems='center'>                
                <Typography sx={{ color: 'common.white', textAlign: 'right'}}>
                { (isOwner || isTaker) && 'You Risked:' }
                { (!isOwner && !isTaker && !bet.status === 'open') && 'Owner Risked:' }
                { (!isOwner && bet.status === 'open') && 'Your Risk:' }
                </Typography>                                            
                <Typography sx={{ fontWeight: 'bold', color: 'common.white' }}>$
                  { isOwner && parseFloat(bet.wallet?.ownerStake).toFixed(2) }
                  { isTaker && parseFloat(bet.wallet?.takerStake).toFixed(2) }
                  { (!isOwner && !isTaker && !bet.status === 'open') && parseFloat(bet.wallet?.ownerStake).toFixed(2) }
                  { (!isOwner && bet.status === 'open') && parseFloat(parseFloat(bet.wallet?.ownerStake) * (parseInt(bet.odds_staked) / parseInt(bet.odds_receiving))).toFixed(2)}
                </Typography>                                         
              </Stack>     
              </Paper>                       
              
              <Paper variant="outlined" sx={{ backgroundColor: 'success.main', p:2, minWidth: '300px' }} >
                <Stack spacing={1} direction="column" alignItems='stretch' justifyContent='center'>
                    <Stack spacing={1} direction="row" justifyContent='center' alignItems='center'>
                        <Typography sx={{ color: 'common.white' }}>
                        { (isOwner || isTaker) && 'Your Potential Gain:' }
                        { (!isOwner && !isTaker && !bet.status === 'open') && 'Their Potential Gain:' } 
                        { (!isOwner && bet.status === 'open') && 'Your Potential Gain:' }
                        </Typography>             
                        <Typography sx={{ fontWeight: 'bold', color: 'common.white', textAlign: 'left' }}>$
                        { isOwner && parseFloat(parseFloat(bet.wallet?.ownerStake) * (parseInt(bet.odds_staked) / parseInt(bet.odds_receiving))).toFixed(2) }
                        { isTaker && parseFloat(bet.wallet?.ownerStake).toFixed(2) }
                        { (!isOwner && !isTaker && !bet.status === 'open') && parseFloat(bet.wallet?.takerStake).toFixed(2) (  ) }
                        { (!isOwner && bet.status === 'open') && parseFloat(parseFloat(bet.wallet?.ownerStake)).toFixed(2)  }
                        </Typography>                                                                                               
                    </Stack>
                    <Stack spacing={1} direction="row" justifyContent="center" alignItems="center" >  
                        <Typography sx={{ color: 'common.white' }}>
                            That's
                        </Typography>                      
                        <Typography sx={{ fontWeight: 'bold', color: 'white' }}>
                        { isOwner && parseFloat((parseInt(bet.odds_staked) / parseInt(bet.odds_receiving)) * 100).toFixed(0) }
                        { isTaker && parseFloat((parseInt(bet.odds_receiving) / parseInt(bet.odds_staked)) * 100).toFixed(0) }
                        { (!isOwner && !isTaker) && parseFloat((parseInt(bet.odds_receiving) / parseInt(bet.odds_staked)) * 100).toFixed(0) }                
                        % !!!
                        </Typography>
                    </Stack>
                </Stack>
              </Paper>
            </Stack>
        ) 
      }

    const getBetWinLoseResults = () => {

        // get the total prize
        const total = parseFloat(parseFloat(bet.wallet?.ownerStake) + parseFloat(bet.wallet?.takerStake)).toFixed(2);
        const isWinner = user?._id === bet?.winnerID;
        const isAdmin = user?._id !== (bet?.ownerID || bet?.takerID); 
        var losses = 0;
    
        // if the current user is the bet's loser
        if (!isWinner) {
          if (bet.ownerID === user._id) { losses = bet.wallet?.ownerStake } else { losses = bet.wallet?.takerStake }
        }
    
        if (isWinner || (isAdmin && !isOwner && !isTaker)) return total;
        
        return losses;            
    }

    const getBetVoteStatus = () => {

        if (detailed) return;

        return (            
            <Stack direction="column" alignItems="stretch" justifyContent="center" spacing={1} color={betBodyBGColor[bet.status ?? 'unknown']} >                                
                
                { takerHasVoted() ?
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                    <Typography variant='caption' sx={{ color: betBodyFontColor[bet.status ?? 'unknown']}}>
                        { isTaker ? 'you' : bet.taker?.name } decided on {bet.vote?.taker?.votedAt ? getIBDateAsString(bet.vote?.taker?.votedAt) : 'unknown'}
                    </Typography>
                    <CheckCircleOutlineIcon  />
                </Stack>
                :
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                    <Typography variant='caption' sx={{ color: betBodyFontColor[bet.status ?? 'unknown']}}>
                        waiting for { isTaker ? 'your' : bet.taker?.name + 's' } vote...
                    </Typography>
                </Stack>
                }      
                
                { ownerHasVoted() ?
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                    <Typography variant='caption' sx={{ color: betBodyFontColor[bet.status ?? 'unknown']}}>
                        { isOwner ? 'you' : bet.owner?.name } decided on {bet.vote?.owner?.votedAt ?  getIBDateAsString(bet.vote?.owner?.votedAt) : 'unknown'}
                    </Typography>
                    <CheckCircleOutlineIcon  />
                </Stack>
                : 
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                    <Typography variant='caption' sx={{ color: betBodyFontColor[bet.status ?? 'unknown']}}>
                        waiting for { isOwner ? 'your' : bet.owner?.name + '\'s' } vote...
                    </Typography>
                </Stack>
                }                           

            </Stack>            
        )
    }

    const getBetWinLoseIcon = () => {
                    
        const isWinner = user?._id === bet?.winnerID;
           
        return ( isWinner ? <MonetizationOnIcon sx={{ color: '#006400' }} /> : <MonetizationOnIcon sx={{ color: 'red' }} /> )
    }

    const getBetOwnerAndTakerTags = () => {
        if (!detailed) return;

        return (
            <Grid container direction="row" spacing={2}>
                <Grid item xs>
                { getBetOwnerTag() }
                </Grid>                
                <Grid item xs>
                {  getBetTakerTag() }
                </Grid>
            </Grid>              
        )
    }

    // return a nicly formatted 
    const getBetOwnerTag = () => {

        if (!detailed && (bet.status !== 'expired' || bet.statuss !== 'deleted')) return;

        return (                   
        <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>                    
            <Typography color={betBodyFontColor[bet.status ?? 'unknown']} variant='caption' sx={{ fontWeight: 100 }}>bet placed by</Typography>
            <IBAvatar picture={bet.owner?.picture} name={bet.owner?.name} />
            <Stack direction="column" justifyContent="center" alignItems="flex-start">
                <Typography color={betBodyFontColor[bet.status ?? 'unknown']}>{bet?.owner?.name}</Typography>
                <Typography color={betBodyFontColor[bet.status ?? 'unknown']} variant="caption" sx={{ fontWeight: 100 }} noWrap={true}>member since {new Date(bet?.owner?.createdAt).getFullYear()}</Typography>
            </Stack>
        </Stack>        
        )
    }

    const getBetTakerTag = () => {

        if (!detailed) return;

        return (                   
        <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>                    
            <Typography color={betBodyFontColor[bet.status ?? 'unknown']} variant='caption' sx={{ fontWeight: 100 }}>bet taken by</Typography>
            <IBAvatar picture={bet.taker?.picture} name={bet.taker?.name} />
            <Stack direction="column" justifyContent="center" alignItems="flex-start">
                <Typography color={betBodyFontColor[bet.status ?? 'unknown']}>{bet.taker?.name}</Typography>
                <Typography color={betBodyFontColor[bet.status ?? 'unknown']} variant="caption" sx={{ fontWeight: 100 }} noWrap={true}>member since {new Date(bet.taker?.createdAt).getFullYear()}</Typography>
            </Stack>
        </Stack>        
        )
    }    

    // return the notes that the user left for arbitration 
    const getArbitrationNotes = () => {

        if (!detailed) return;

        return(
            <Stack spacing={2}> 
                <Divider sx={{ m:1, bgcolor: betBodyFontColor[bet.status ?? 'unknown'] }} />    
               
                <Stack spacing={2} sx={{ pl: 5, pr: 5 }} >
                { isOwner &&
                <>
                <Typography color={betBodyFontColor[bet.status ?? 'unknown']}><strong>Your Arbitration Pitch:</strong></Typography>
                <AutoSaveTextField initialValue={bet.vote?.owner?.pitch} userid={userId} betid={bet._id} />                
                </>
                }
                { isTaker &&
                <>
                <Typography color={betBodyFontColor[bet.status ?? 'unknown']}><strong>Your Arbitration Pitch:</strong></Typography>
                <AutoSaveTextField initialValue={bet.vote?.taker?.pitch} userid={userId} betid={bet._id} />                
                </>
                }
                { user.admin && 
                <>
                <Typography color={betBodyFontColor[bet.status ?? 'unknown']}><strong>{bet.owner?.name}'s Arbitration Pitch:</strong></Typography>
                <Typography color={betBodyFontColor[bet.status ?? 'unknown']}>{ bet.vote?.owner?.pitch ? bet.vote?.owner?.pitch : <i>no pitch text entered</i> } </Typography>
                <Typography color={betBodyFontColor[bet.status ?? 'unknown']}><strong>{bet.taker?.name}'s Arbitration Pitch:</strong></Typography>
                <Typography color={betBodyFontColor[bet.status ?? 'unknown']}>{ bet.vote?.taker?.pitch ? bet.vote?.taker?.pitch : <i>no pitch text entered</i> }</Typography>            
                </>
                }
                </Stack>
            </Stack>   
        )        
       
    }


    const getBetVoteOptions = () => {

        if (!detailed) return;

        /* if the owner or taker has shown up and they have already voted */
        return (
           
            (bet.ownerID === userId && bet.vote?.owner?.vote) || (bet.takerID === userId && bet.vote?.taker?.vote) ? 
                      
                <Grid container direction="row" spacing={1} justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 600, textAlign: "center", color: betBodyFontColor[bet.status ?? 'unknown'] }}>You Voted For</Typography>
                    <Stack spacing={1} direction="row" justifyContent="center" alignItems="center" sx={{ mt:2 }}>
                      <Avatar alt={usersVote()?.name} src={usersVote()?.picture} referrerPolicy="no-referrer" />
                      <Stack direction="column">
                        <Typography sx={{ color : betBodyFontColor[bet.status ?? 'unknown'] }}>{usersVote()?.name}</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 100, color: betBodyFontColor[bet.status ?? 'unknown'] }} noWrap={true}>member since {new Date(usersVote()?.createdAt).getFullYear()}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              : 
                <>
                <Grid container direction="row" spacing={1} justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 600, textAlign: "center", color: betBodyFontColor[bet.status ?? 'unknown'] }}>
                        Who Won?
                    </Typography>
                  </Grid>
                  <Grid item sm={6} md={4}>
                    <Card>
                      <CardActionArea onClick={() => { handleOwnerWinsOpen(); }} >
                        <CardContent>
                          <Stack direction="column" spacing={5} alignItems="center" justifyContent="center">
                            <Stack spacing={1} direction="row" justifyContent="center" alignItems="center" sx={{ mt:2 }}>
                              <IBAvatar picture={bet.owner?.picture} name={bet?.owner?.name} />                              
                              <Stack direction="column">
                                <Typography>{bet?.owner?.name}</Typography>
                                <Typography variant="caption" sx={{ fontWeight: 100 }} noWrap={true}>member since {new Date(bet?.owner?.createdAt).getFullYear()}</Typography>
                              </Stack>
                            </Stack>
                            <Paper variant="outlined" sx={{ p: 2, backgroundColor: 'success.main', border: '2px white' }} >
                                <Stack spacing={1} direction="row" justifyContent="space-between">
                                    <Typography sx={{ color: 'common.white', minWidth: '70px' }}>Risked</Typography>
                                    <Typography sx={{ color: 'common.white'}}>&nbsp;</Typography>
                                    <Typography sx={{ fontWeight: 'bold', color: 'common.white' }}>${parseInt(bet.amount).toFixed(2)}</Typography>
                                </Stack>
                                <Stack spacing={1} direction="row"justifyContent="space-between">
                                    <Typography sx={{ color: 'common.white', minWidth: '70px'}}>Profit&nbsp;%</Typography>
                                    <Typography sx={{ color: 'common.white'}}>&nbsp;</Typography>
                                    <Typography sx={{ fontWeight: 'bold', color: 'common.white' }}>{parseFloat((parseInt(bet.odds_staked) / parseInt(bet.odds_receiving)) * 100).toFixed(0)}%</Typography>
                                </Stack>
                                <Stack spacing={1} direction="row" justifyContent="space-around">
                                    <Typography sx={{ color: 'common.white', minWidth: '70px'}}>Potential&nbsp;Gain</Typography>
                                    <Typography sx={{ color: 'common.white'}}>&nbsp;</Typography>
                                    <Typography sx={{ fontWeight: 'bold', color: 'common.white' }}>${parseInt(bet.amount * (bet.odds_staked / bet.odds_receiving)).toFixed(2)}</Typography>
                                </Stack>
                             
                            </Paper>
                          </Stack>
                        </CardContent>
                      </CardActionArea>
                    </Card> 
                  </Grid>
                  <Grid item sm={1} display='flex' justifyContent='center'>
                    <Typography sx={{ fontWeight: 600, color: betBodyFontColor[bet.status ?? 'unknown'] }}>-OR-</Typography>
                  </Grid>
                  <Grid item sm={6} md={4}>
                    <Card>
                      <CardActionArea onClick={() => { handleTakerWinsOpen(); }}>
                        <CardContent>
                          <Stack direction="column" spacing={5} alignItems="center" justifyContent="center">
                            <Stack spacing={1} direction="row" justifyContent="center" alignItems="center" sx={{ mt:2 }}>
                              <IBAvatar picture={bet?.taker?.picture} name={bet?.taker?.name} />                                  
                              <Stack direction="column">
                                <Typography>{bet?.taker?.name}</Typography>
                                <Typography variant="caption" sx={{ fontWeight: 100 }} noWrap={true}>member since {new Date(bet?.taker?.createdAt).getFullYear()}</Typography>
                              </Stack>
                            </Stack>
                            <Paper variant="outlined" sx={{ p: 2, backgroundColor: 'success.main', border: '2px white' }} >
                              <Stack spacing={1} direction="row" justifyContent="space-between">
                                <Typography sx={{ fontWeight: 'bold', color: 'common.white' }}>${parseInt(bet.amount * (bet.odds_staked / bet.odds_receiving)).toFixed(2)}</Typography>
                                <Typography sx={{ color: 'common.white'}}>&nbsp;</Typography>
                                <Typography sx={{ color: 'common.white', minWidth: '70px', textAlign:'right'}}> Risked</Typography>                                            
                              </Stack>
                              <Stack spacing={1} direction="row" justifyContent="space-between">
                                <Typography sx={{ fontWeight: 'bold', color: 'common.white' }}>{parseFloat((parseInt(bet.odds_receiving) / parseInt(bet.odds_staked)) * 100).toFixed(1)}%</Typography>
                                <Typography sx={{ color: 'common.white'}}>&nbsp;</Typography>
                                <Typography sx={{ color: 'common.white', minWidth: '70px', textAlign: 'right'}}> Profit %</Typography>                                            
                              </Stack>
                              <Stack spacing={1} direction="row" justifyContent="space-between">
                                <Typography sx={{ fontWeight: 'bold', color: 'common.white' }}>${parseFloat(bet.amount).toFixed(2)}</Typography>
                                <Typography sx={{ color: 'common.white'}}>&nbsp;</Typography>
                                <Typography sx={{ color: 'common.white', minWidth: '70px'}}> Potential Gain</Typography>                                    
                              </Stack>
                            </Paper>
                          </Stack>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                </Grid>
                </> 
        )
    }

    return (
        <>
        <Card variant="outlined" key={bet._id} sx={{ minHeight: '575px', maxHeight: maxheight }} > 
            
            <CardActionArea onClick={handleClick} >
            
            <CardHeader   
                avatar={<IBAvatar picture={bet.owner?.picture} name={bet.owner?.name} />}  
                title={<Typography sx={{ minHeight : '40px', color: betTitleFontColor[bet.status ?? 'unknown']}}><strong>{truncate(bet.title, titlelength)}</strong></Typography>}
                subheader={
                    <Typography sx={{ fontWeight: 100, fontSize: 12, color: betTitleFontColor[bet.status ?? 'unknown'], mt: 1, textAlign: 'right'}}>
                    { /* write out the STATUS of the bet    */ }  
                        STATUS: <strong>{ betStatusLabels[bet.status ?? 'unknown' ] }</strong>
                    </Typography>
                }
                sx={{ minHeight: '58px', maxHeight: '58px', backgroundColor: betHeaderBGColor[bet.status ?? 'unknown'] }}
            />
            { !bet.imageURL && !betImageURL ? 
            <Skeleton variant="rectangle" height={mediaheight} />            
            :
            <CardMedia
                component="img"
                height={mediaheight}
                image={ bet.imageURL ? bet.imageURL : betImageURL }
                /* image={encodeURI("https://source.unsplash.com/random?" + terms.join(",") + "&content_filter=high")} */
                alt="bet image"
            />
            }   
            {
                {
                    'open': (
                        <>
                        <CardContent sx={{ backgroundColor: betBodyBGColor[bet.status ?? 'unknown'], minHeight: '100px' }}>                                                        
                            <Stack direction={'column'} alignItems="stretch" justifyContent="flex-start" spacing={stackSpacing} sx={{ minHeight: '100px'}} >                            
                                { getBetDescription() }
                                <Divider sx={{ m:1, bgcolor: betBodyFontColor[bet.status ?? 'unknown'] }} />
                                { getBetOwnerTag() }
                                { getBetOdds() }
                                { getBetRiskGainInfo() }                                                                                                                                                                                                                                                        
                            </Stack>                            
                        </CardContent>   
                        <CardActionArea onClick={() => { handleTakeBetClickOpen(); }}>
                            <CardContent sx={{ backgroundColor: betBodyBGColor[bet.status ?? 'unknown'] }}>    
                                <Stack justifyContent={"flex-end"} alignItems={"stretch"} sx={{ minHeight: '22px' }} direction={"column"}>
                                    { userId !== bet.ownerID && !detailed &&
                                    <Paper sx={{ p:1, mt: -2 }}>
                                        <Typography variant='caption' sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                            THIS BET IS AVAILABLE FOR&nbsp; <strong>${parseFloat(bet.odds_staked / bet.odds_receiving * bet.amount).toFixed(2)}</strong>
                                        </Typography>
                                    </Paper>
                                    }    
                                    { userId !== bet.ownerID && detailed &&                                             
                                    <Button component="span" variant="contained" color="secondary" sx={{ color: 'black', p:2 }} >
                                        Take This Bet For&nbsp;<strong>${parseFloat(bet.odds_staked / bet.odds_receiving * bet.amount).toFixed(2)}</strong>
                                    </Button>                       
                                    }                                   
                                </Stack>
                            </CardContent> 
                        </CardActionArea> 
                        <Stack direction='column' alignItems='center' justifyContent='space-between' sx={{ backgroundColor: betBodyBGColor[bet.status ?? 'unknown'], pb: 0.2}} >
                            <Typography variant='caption' sx={{ marginBottom: 'auto', color: betBodyFontColor[bet.status ?? 'unknown'], textAlign: "center", backgroundColor: betHeaderBGColor[bet.status ?? 'unknown'] }} >
                                <i>bet expires at {bet.enddate ? getIBTimeAsString(bet.enddate) : 'undefined'} on {bet.enddate ? getIBDateAsString(bet.enddate) : 'undefined'}</i>
                            </Typography>                                    
                        </Stack>  
                        </>                                   
                    ),
                    'taken': (   
                        <>     
                        <CardActionArea onClick={() => { handleExpediteBetClickOpen(); }}>       
                            <CardContent sx={{ backgroundColor: betBodyBGColor[bet.status ?? 'unknown'], minHeight: '200px' }}> 
                                <Stack direction={'column'} alignItems="stretch" justifyContent="flex-start" spacing={stackSpacing} sx={{ minHeight: '200px' }}>  
                                    { getBetDescription() }        
                                    <Divider sx={{ m:1, bgcolor: betBodyFontColor[bet.status ?? 'unknown'] }} />                                                                          
                                    { getBetOwnerAndTakerTags() }     
                                    { getBetOdds() }                                    
                                    <Stack justifyContent="center" alignItems="center" spacing={stackSpacing} sx={{ mt: 1, mb: 1, height: '100%' }} >                                    
                                        <Typography variant='caption'>
                                            voting on the bet's outcome starts in:
                                        </Typography>
                                        <CountDownTimer targetDate={bet.enddate ? getIBDateAsMS(bet.enddate) : Date.now() }/>                                                            
                                    </Stack>                                                               
                                    { detailed && user.admin && 
                                    <Button component="span" variant="contained" color='secondary'>Expedite</Button>
                                    }       
                                </Stack>
                            </CardContent>
                        </CardActionArea>  
                        <Stack direction='column' alignItems='center' justifyContent='space-between' sx={{ backgroundColor: betBodyBGColor[bet.status ?? 'unknown'], pb: 0.2}} >
                            <Typography variant='caption' sx={{ marginBottom: 'auto', color: betBodyFontColor[bet.status ?? 'unknown'], textAlign: "center", backgroundColor: betHeaderBGColor[bet.status ?? 'unknown'] }} >
                                <i>voting starts at {bet.enddate ? getIBTimeAsString(bet.enddate) : 'undefined'} on {bet.enddate ? getIBDateAsString(bet.enddate) : 'undefined'}</i>
                            </Typography>                                    
                        </Stack>
                        </>
                    ),
                    'voting': (
                        <>
                        <CardContent sx={{ backgroundColor: betBodyBGColor[bet.status ?? 'unknown'], minHeight: '200px' }}> 
                            <Stack direction={'column'} alignItems="stretch" justifyContent="flex-start" spacing={stackSpacing} sx={{ minHeight: '200px' }}>         
                                { getBetDescription() }
                                <Divider sx={{ m:1, bgcolor: betBodyFontColor[bet.status ?? 'unknown'] }} />
                                { getBetOwnerAndTakerTags() }                                                         
                                { getBetVoteOptions() }
                                { getBetVoteStatus() }  
                                { !detailed && ((!ownerHasVoted() && isOwner) || (!takerHasVoted() && isTaker)) &&
                                <Paper sx={{ p:1.5, mt: 1 }}>
                                    <Typography variant='caption' sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                        <strong>VOTE NOW</strong>
                                    </Typography>
                                </Paper>
                                }
                            </Stack>
                        </CardContent>
                        <Stack direction='column' alignItems='center' justifyContent='space-between' sx={{ backgroundColor: betBodyBGColor[bet.status ?? 'unknown'], pb: 0.2}} >
                            <Typography variant='caption' sx={{ marginBottom: 'auto', color: betBodyFontColor[bet.status ?? 'unknown'], textAlign: "center" }} >
                                <i>voting ends {bet.enddate ? getIBVoteDateAsStirng(bet.enddate) : 'unknown'} at {bet.enddate ? getIBTimeAsString(bet.enddate) : 'unknwon' }</i>
                            </Typography>                                    
                        </Stack>                                                                             
                        </>
                    ),
                    'pending': (
                        <>
                        <CardContent sx={{ backgroundColor: betBodyBGColor[bet.status ?? 'unknown'], minHeight: '200px' }}>
                            <Stack direction={'column'} alignItems="stretch" justifyContent="flex-start" spacing={stackSpacing} sx={{ minHeight: '200px' }}>  
                                { getArbitrationNotice() }
                                { getBetDescription() }
                                <Divider sx={{ m:1, bgcolor: betBodyFontColor[bet.status ?? 'unknown'] }} />  
                                { getBetOwnerAndTakerTags() }                                             
                                { getBetVoteStatus() } 
                                { getBetRiskGainInfo() }                                   
                                { getVoteResults() }
                                { getArbitrationNotes() }                             
                            </Stack>
                        </CardContent>
                        <Stack direction='column' alignItems='center' justifyContent='space-between' sx={{ backgroundColor: betBodyBGColor[bet.status ?? 'unknown'], pb: 0.2}} >
                            <Typography variant='caption' sx={{ marginBottom: 'auto', color: betBodyFontColor[bet.status ?? 'unknown'], textAlign: "center" }} >
                                <i>arbitration ends {bet.enddate ? getIBVoteDateAsStirng(bet.enddate) : 'unknown'} at {bet.enddate ? getIBTimeAsString(bet.enddate) : 'unknown'}</i>
                            </Typography>                                    
                        </Stack>   
                        </>
                    ),
                    'defaulted': (
                        <>
                        <CardContent sx={{ backgroundColor: betBodyBGColor[bet.status ?? 'unknown'], minHeight: '200px' }}>    
                            { getBetDescription() }
                            <Divider sx={{ m:1, bgcolor: betBodyFontColor[bet.status ?? 'unknown'] }} />                                                        
                            { getBetOwnerTag() } 
                            { getBetOdds() }           
                            <Stack justifyContent="center" alignItems="center" spacing={1}>
                                <Typography sx={{ color: "white" }} variant="caption">bet was taken by {bet?.taker?.name}</Typography>
                                <IBAvatar picture={bet.taker?.picture} name={bet.taker?.name} />  
                                <Typography sx={{ fontWeight: 100, fontSize: 12, textAlign: "center", color: betBodyFontColor[bet.status ?? 'unknown'] }} >
                                    <i>bet defaulted on {bet.enddate ? getIBTimeAsString(bet.enddate) : 'unknown'} on {bet.enddate ? getIBDateAsString(bet.enddate) : 'unknown'}</i>
                                </Typography>
                            </Stack>                            
                        </CardContent>
                        </>
                    ),
                    'expired': (
                        <>
                        <CardContent sx={{ backgroundColor: betBodyBGColor[bet.status ?? 'unknown'], minHeight: '200px' }}>                              
                            <Stack direction='column' alignItems="stretch" justifyContent="flex-start" spacing={1} sx={{ minHeight: '170px' }}>                            
                                { getBetDescription() }
                                <Divider sx={{ m:1, bgcolor: betBodyFontColor[bet.status ?? 'unknown'] }} />                                                        
                                { getBetOwnerTag() } 
                                { getBetOdds() }                               
                            </Stack>                        
                        </CardContent>
                        <Stack direction='column' alignItems='center' justifyContent='space-between' sx={{ backgroundColor: betBodyBGColor[bet.status ?? 'unknown'], pb: 0.2}} >
                            <Typography variant='caption' sx={{ marginBottom: 'auto', color: betBodyFontColor[bet.status ?? 'unknown'], textAlign: "center" }} >
                                <i>bet expired on {bet.enddate ? getIBTimeAsString(bet.enddate) : 'unknown'} on {bet.enddate ? getIBDateAsString(bet.enddate) : 'unknown'}</i>
                            </Typography>                                    
                        </Stack>       
                        </>
                    ),
                    'deleted': (
                        <>
                        <CardContent sx={{ backgroundColor: betBodyBGColor[bet.status ?? 'unknown'], minHeight: '200px' }}>                                                        
                            <Stack direction={'column'} alignItems="stretch" justifyContent="flex-start" spacing={stackSpacing} sx={{ minHeight: '200px' }}>                                    
                                { getBetDescription() }   
                                <Divider sx={{ m:1, bgcolor: betBodyFontColor[bet.status ?? 'unknown'] }} />   
                                { getBetOwnerTag() }
                                { getBetOdds() }                                 
                            </Stack>                         
                        </CardContent>
                        <Stack direction='column' alignItems='center' justifyContent='space-between' sx={{ backgroundColor: betBodyBGColor[bet.status ?? 'unknown'], pb: 0.2}} >
                            <Typography variant='caption' sx={{ marginBottom: 'auto', color: betBodyFontColor[bet.status ?? 'unknown'], textAlign: "center", backgroundColor: betHeaderBGColor[bet.status ?? 'unknown'] }} >
                                <i>bet deleted on {bet.deletedAt ? getIBTimeAsString(bet.deletedAt) : 'unknown'} on {bet.deletedAt ? getIBDateAsString(bet.deletedAt) : 'unknown'}</i>
                            </Typography>                                    
                        </Stack>                                 
                        </>
                    ),
                    'concluded': (
                        <>
                        <CardContent sx={{ backgroundColor: betBodyBGColor[bet.status ?? 'unknown'], minHeight: '200px' }}>
                            <Stack direction={'column'} alignItems="stretch" justifyContent="flex-start" spacing={stackSpacing} sx={{ minHeight: '200px' }}>         
                                { getBetDescription() }   
                                <Divider sx={{ m:1, bgcolor: betBodyFontColor[bet.status ?? 'unknown'] }} />                                   
                                { getBetOwnerAndTakerTags() }
                                { getBetOdds() }  
                                <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} spacing={1}>
                                { user?._id === bet?.winnerID ?
                                <>
                                    <Typography sx={{ fontSize: 14 }}>you won this bet on {bet.concludedAt ? getIBDateAsString(bet.concludedAt): 'unknwon'}</Typography> 
                                    <Typography sx={{ fontSize: 14 }}>you won a total of</Typography>
                                </>
                                :
                                <>
                                    <Typography sx={{ fontSize: 14 }}>you lost this bet on {bet.concludedAt ? getIBDateAsString(bet.concludedAt) : 'unknown'}</Typography>
                                    <Typography sx={{ fontSize: 14 }}>you lost a total of</Typography>
                                </>
                                }  
                                </Stack>     
                                
                                <Stack spacing={0} direction="row" justifyContent="center" alignItems="center">
                                    { getBetWinLoseIcon() } 
                                    <Typography variant="h6">{parseFloat(getBetWinLoseResults()).toFixed(2)}</Typography>
                                </Stack>
                                
                            </Stack>
                            </CardContent>
                            <Stack direction='column' alignItems='center' justifyContent='space-between' sx={{ backgroundColor: betBodyBGColor[bet.status ?? 'unknown'], pb: 0.2}} >
                            <Typography variant='caption' sx={{ marginBottom: 'auto', textAlign: "center", color: betBodyFontColor[bet.status ?? 'unknown'] }} >
                                <i>bet concluded on {bet.concludedAt ? getIBTimeAsString(bet.concludedAt) : 'unknown'} on {bet.concludedAt ? getIBDateAsString(bet.concludedAt) : 'unknown'}</i>
                            </Typography>                                    
                        </Stack>         
                        </>
                    ),
                }[bet.status]
            }
                 
            </CardActionArea>
            { user && (
            <CardActions sx={{ display: "flex", justifyContent: "space-between", maxHeight: '30px', minHeight: '30px' }}>
                <Button size="small" onClick={() => handleLikeClick()}>
                    <Likes />
                </Button>
                { detailed &&  <IconButton size="small" color="primary" onClick={() => navigate(-1)}><ArrowBackIosIcon/><Typography>BACK</Typography></IconButton> }
                { bet?.ownerID === user?._id && ['defaulted', 'open'].includes(bet?.status)  ? (
                    <IconButton onClick={() => handleClickOpen()} color="primary">
                        <DeleteIcon />
                    </IconButton>                    
                ) : (
                    <IconButton disabled>
                        <DeleteIcon />
                    </IconButton>       
                )}
            </CardActions>
            )
            }
        </Card>

        <Dialog open={open} onClose={handleClickClose} maxWidth="sm" fullWidth>
            <DialogTitle>Confirm Delete</DialogTitle>
            <Box position="absolute" top={0} right={0}>
                <IconButton onClick={handleClickClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent>
                <Typography>Are you sure you want to delete this bet? {bet.title}</Typography>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={handleClickClose}>
                    Cancel
                </Button>
                <Button color="secondary" variant="contained" onClick={() => handleDeleteClick()}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog open={openSignUp} onClose={handleSignupClose} maxWidth="sm" fullWidth>
            <DialogTitle>incredibets Signin / Signup</DialogTitle>
            <Box position="absolute" top={0} right={0}>
                <IconButton onClick={handleSignupClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogContent>
                <Typography>It looks like you are not yet an incredibets member or your session has expired.</Typography>
                <Stack direction='row' justifyContent='center' sx={{ mt: 2, mb: 1 }}>
                    <Typography >Please sign-in or sign-up today!</Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={handleSignupClose}>
                    Cancel
                </Button>
                <Button color="secondary" variant="contained" onClick={() => handleSignUpIn()}>
                    Sign In / Sign Up
                </Button>
            </DialogActions>
        </Dialog>
        
       { diagTakeBet() }
       { diagExpediteBet() }
       { diagOwnerVoteConfimation() }
       { diagTakerVoteConfimation() }
       { diagArbitrationConfirmation() }

        </>
    );
}
  
  export default Bet;