import { AUTH, IB_ERROR } from '../constants/actionTypes';
import { getApp } from '../utils/utils';

//
// log the user into our app
//
export const signin = (formData, router) => async (dispatch) => {
  
  try {

    // we don't have an authenticated user yet
    if (!window.incredibets.realmUser) {
      // get an authenticated user oblect from realm-web
      console.log('signin null realmUser')
      window.incredibets.realmUser = await getApp();
    }

    const user = {
      email: formData.email,
      password: formData.password
    }

    const results = JSON.parse(await window.incredibets.realmUser.functions.userSignin(JSON.stringify(user))).body;
    if (results.error !== undefined) {
      throw results;
    }

    dispatch({ type: AUTH, results });
    router('/', {replace: true});

  } catch (error) {
    console.log(error);
    dispatch({ type: IB_ERROR, error});
  }

};

//
// signup the user to use the app
//
export const signup = (formData, router) => async (dispatch) => {
  
  try {

    // we don't have an authenticated user yet
    if (!window.incredibets.realmUser) {
      // get an authenticated user oblect from realm-web
      console.log('signin null realmUser')
      window.incredibets.realmUser = await getApp();
    }

    const user = {
      email: formData.email,
      password: formData.password,
      confirmPassword: formData.confirmPassword,
      firstName: formData.firstName,
      lastName: formData.lastName
    }
    
    const results = JSON.parse(await window.incredibets.realmUser.functions.userSignup(JSON.stringify(user))).body;
    if (results.error !== undefined) {
      throw results;
    }

    dispatch({ type: AUTH, results });
    router('/', {replace: true});

  } catch (error) {
    console.log(error);
    dispatch({ type: IB_ERROR, error});
  }
  
};

//
// easy, breasy login from the google
//
export const googleProfile = (googleData, router) => async (dispatch) => {

  try {

    //console.log('googleProfile START')
      
    // we don't have an authenticated user yet
    if (!window.incredibets.realmUser) {
        // get an authenticated user oblect from realm-web
        console.log('googleProfile null realmUser')
        window.incredibets.realmUser = await getApp();
    }
    
    //console.log('googgleProfile: got realmUser');
    //const incredibets = await getApp();
    const user = {
      email: googleData?.email,
      firstname: googleData?.given_name,
      lastname: googleData?.family_name,
      picture: googleData?.picture
    }
    
    //console.log(`user is ${JSON.stringify(user)}`);
    const results = JSON.parse(await window.incredibets.realmUser.functions.googleProfile(JSON.stringify(user))).body;
    if (results.error !== undefined) {
      throw results;
    }

    //console.log(`${JSON.stringify(result)}`)
    dispatch({ type: AUTH, results });
    router('/', {replace: true});

  } catch (error) {
      console.log(`_googleProfile: ${error}`);
      dispatch({ type: IB_ERROR, error});
  }

}