import { END_LOADING_WALLET, FETCH_WALLET_AMOUNT, FETCH_WALLET_HISTORY, IB_ERROR, START_LOADING_WALLET } from '../constants/actionTypes';
import { getApp } from '../utils/utils';

//
// return the amount in a user's wallet
//
export const getWalletAmount = (userid) => async (dispatch) => {
    
    try {
        console.log(`getWalletAmount for userID: ${userid}`);
        if (!userid) throw new Error('getWalletAmount - no userid provided');

        dispatch({ type: START_LOADING_WALLET });

        // we don't have an authenticated user yet
        if (!window.incredibets.realmUser) {
            // get an authenticated user oblect from realm-web
            //console.log('getWalletAmount null realmUser')
            window.incredibets.realmUser = await getApp();
        }

        let request = {
            wallet: true,
            userid: userid
        };

        const results = JSON.parse(await window.incredibets.realmUser.functions.fetchWallet(JSON.stringify(request))).body;
        //console.log(`RESULTS: ${JSON.stringify(results)}`)
        if (results.error !== undefined) {
            throw results;
        }
        //console.log(`wallet obtained: ${JSON.stringify(results)}`)
        dispatch({ type: FETCH_WALLET_AMOUNT, results });
        dispatch({ type: END_LOADING_WALLET });
        //console.log('getWalletAmount COMPLETE');

    } catch (error) {
        console.log(error.message);
        dispatch({ type: END_LOADING_WALLET });
        dispatch({ type: IB_ERROR, error});
    }
}

//
// return the history of a user's wallet transactions
//
export const getWalletHistory = (userid, page = 1, walletHistoryItemsPerPage = 10) => async (dispatch) => {
    
    try {

        if (!userid) throw new Error('getWalletHistory - no userid provided');

        dispatch({ type: START_LOADING_WALLET });

        // we don't have an authenticated user yet
        if (!window.incredibets.realmUser) {
            // get an authenticated user oblect from realm-web
            //console.log('getWalletHistory null realmUser')
            window.incredibets.realmUser = await getApp();
        }

        let request = {
            wallet: true,
            userid: userid
        };

        const results = JSON.parse(await window.incredibets.realmUser.functions.fetchWalletHistory(JSON.stringify(request))).body;
        //console.log(`RESULTS: ${JSON.stringify(results)}`)
        if (results.error !== undefined) {
            throw results;
        }
        console.log(`wallet obtained: ${JSON.stringify(results)}`)
        dispatch({ type: FETCH_WALLET_HISTORY, results });
        dispatch({ type: END_LOADING_WALLET });
        //console.log('getWalletHistory COMPLETE');

    } catch (error) {
        console.log(error.message);
        dispatch({ type: END_LOADING_WALLET });
        dispatch({ type: IB_ERROR, error});
    }
}